import React, {useState} from "react";
import {Badge, Button, ButtonGroup, Card, CardBody, Collapse} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faChevronDown,
    faChevronUp,
    faExclamationCircle,
    faPen,
    faSearch,
    faUserGraduate,
    faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import FormCard from "../../components/Card/FormCard";
import ProspectFormHandling from "../../components/Form/ProspectFormHandling";
import FormSearchProspectWrapper from "../../components/Form/FormSearchProspectWrapper";
import ProspectFormValidation from "../../lib/validation/ProspectFormValidation";
import {useMutation, useQuery} from "@apollo/client";
import {
    CREATE_PROSPECT_FULL_PAYLOAD,
} from "../../graphql/mutations";
import PropTypes from "prop-types";
import LoadingSpinner from "../Default/LoadingSpinner";
import ProspectListItem from "../../components/List/ProspectListItem";
import NotificationToast from "../../lib/notification/NotificationToast";
import useNotification from "../../lib/notification/useNotification";
import {getProspectInput, getProspectInputFromUserEnf} from "../../lib/input/prospectInput";
import {SEARCH_PERIMETER_SETTINGS, USERS_ENF_UID_QUERY} from "../../graphql/queries";
import ToggleSectionTitle from "./ToggleSectionTitle";

function ProjectSectionProspect({
                                    project,
                                    setProject,
                                    prospect,
                                    prospectInput,
                                    setProspectInput,
                                    section,
                                    isViewMode,
                                    enfUid,
                                    onChange
}) {
    const { showToast } = useNotification();

    const [isProspectSectionOpened, setProspectSectionOpened] = useState(section === 'prospect' && !isViewMode);
    const toggleSectionProspect = () => setProspectSectionOpened(!isProspectSectionOpened);

    const [userENF, setUserENF] = useState(null);

    const HttpStatus = require('http-status-codes');

    const [createProspect, {loading: isLoadingCreatingProspect}] = useMutation(
        CREATE_PROSPECT_FULL_PAYLOAD,
        { onCompleted(data) {
                let responseCode = data['CreateProspect']['responseCode'];
                let prospect = data['CreateProspect']['prospect'];
                if (responseCode === HttpStatus.CREATED) {
                    setProject({...project, prospect: prospect})
                    setProspectInput(getProspectInput(prospect));
                    showToast(new NotificationToast("PROSPECT",
                        "Le prospect a été créé avec succès",
                        "success",
                        faCheckCircle));
                    onChange(true)
                } else {
                    showToast(new NotificationToast("PROSPECT",
                        "Un problème est survenu lors de la création du prospect",
                        "danger",
                        faExclamationCircle));
                }
            }});

    const updateProspect = (data) => {
        setProspectInput({...prospectInput, ...data.variables.prospect});
        setProject({...project, isOpen: true, relatedProfessionalExperience: data.variables.relatedProfessionalExperience});
    }

    let prospectFormValidation = new ProspectFormValidation(project, prospectInput, userENF);
    let prospectFormValues = prospectFormValidation.getValues();
    let prospectFormValidate = prospectFormValidation.validate();
    let prospectFormMode = prospect !== null ? "edit" : "create";
    prospectFormMode = userENF ? "complete_enf" : prospectFormMode;

    const [isEditingProspect, setIsEditingProspect] = useState(false);
    const toggleIsEditingProspect = () => {
        setProspectSectionOpened(!isEditingProspect);
        setIsEditingProspect(!isEditingProspect);
        setIsSearchingProspect(false);
    }
    const [isSearchingProspect, setIsSearchingProspect] = useState(false);
    const toggleIsSearchingProspect = () => {
        setProspectSectionOpened(!isSearchingProspect);
        setIsSearchingProspect(!isSearchingProspect);
        setIsEditingProspect(false);
    }

    const isLoadingProspect = isLoadingCreatingProspect;
    if (isLoadingProspect && isProspectSectionOpened) {
        setProspectSectionOpened(false);
        setIsEditingProspect(false);
    }

    const [searchedProspectResults, setSearchedProspectResults] = useState(null);
    const addSearchedProspectToProject = (prospectUser) => {
        // Prospect COMPAS
        if (prospectUser.id !== undefined) {
            setUserENF(null)
            setProject({...project, prospect: prospectUser})
            setProspectInput(getProspectInput(prospectUser))
            onChange(true)
        }
        // ENF user
        else {
            setProject({...project, prospect: null});
            setProspectInput(getProspectInputFromUserEnf(prospectUser, project.perimeter));
        }
        setIsEditingProspect(true);
        setIsSearchingProspect(false);
        setProspectSectionOpened(true);
    }

    // When creating a project from a researched a prospect (ProspectOverView)
    // A - User ENF
    const [isCreatingProjectFromUserENF, setIsCreatingProjectFromUserENF] =
        useState(enfUid !== null && enfUid !== undefined &&  !isViewMode && section === "prospect");

    const onCompletedProspectENF = (data) => {
        const prospectENF = data?.UsersEnf[0];

        if (prospectENF && isCreatingProjectFromUserENF) {
            setProspectInput(getProspectInputFromUserEnf(prospectENF, project.perimeter));
            setIsEditingProspect(true);
            setIsSearchingProspect(false);
            setProspectSectionOpened(true);

            prospectFormValidation = new ProspectFormValidation(project, prospectInput, prospectENF);
            prospectFormValues = prospectFormValidation.getValues();
            prospectFormValidate = prospectFormValidation.validate();
            prospectFormMode = "complete_enf";

            setIsCreatingProjectFromUserENF(false);
        }
    }

    const { loading: loadingProspectENF, data: dataProspectENF } = useQuery(
        USERS_ENF_UID_QUERY,
        {
            variables: { enfUid: enfUid },
            skip: !isCreatingProjectFromUserENF,
            onCompleted: onCompletedProspectENF
        });

    const toggleFullSectionProspect = () => {
        setIsEditingProspect(!isProspectSectionOpened && !isViewMode && !isEditingProspect);
        setIsSearchingProspect(false);

        toggleSectionProspect();
    }


    const [consentsSchemas, setConsentsSchemas] = useState({});
    const { perimeterSettings, errorPerimeterSettings, dataPerimeterSettings } = useQuery(SEARCH_PERIMETER_SETTINGS, {
        variables: {
            idPerimeter: project.perimeter.id,
            filterSettings: 'consents'
        },
        onCompleted: function (data) {
            if (data?.PerimeterSettings) {
                setConsentsSchemas(JSON.parse(data.PerimeterSettings.settings).consents);
            }
        }
    });

    return (
        <div>
            <div className="d-md-flex flex-row justify-content-between align-items-center text-dark">
                { prospect
                    ? <>
                        <ToggleSectionTitle
                            title={'N°' + prospect.id}
                            icon={faUserGraduate}
                            toggleFunction={toggleFullSectionProspect}
                        />

                        { isLoadingProspect ?
                            isLoadingCreatingProspect ?
                                <LoadingSpinner text={"Prospect en cours de creation"} color={'info'}/>
                                    : ''
                            : <h6 className="flex-grow-1">
                                {prospect.firstName} {prospect.useName.toUpperCase()}
                            </h6>
                        }
                    </>
                    : <>
                        <ToggleSectionTitle
                            title={'PROSPECT'}
                            icon={faUserGraduate}
                            toggleFunction={toggleFullSectionProspect}
                        />
                        <div className={'flex-grow-1'}>
                            { isViewMode
                                ? <h6>Aucun</h6>
                                : isLoadingProspect ?
                                    isLoadingCreatingProspect ?
                                        <LoadingSpinner text={"Prospect en cours de creation"} color={'text-info'}/>
                                        : loadingProspectENF ?
                                        <LoadingSpinner text={"Récupération du prospect en cours"} color={'text-info'}/>
                                        : ''
                                    : <h6>Aucun</h6>
                            }
                        </div>
                    </>
                }

                    { isViewMode && prospect
                        ? <Button size={"sm"}
                                  color="info" outline
                                  onClick={toggleSectionProspect} >
                            { isProspectSectionOpened
                                ? <em>Fermer <FontAwesomeIcon icon={faChevronUp}/></em>
                                : <em>Afficher <FontAwesomeIcon icon={faChevronDown}/></em>
                            }
                        </Button>
                        : ''
                    }

                    { !isViewMode
                        ? <div>
                            <ButtonGroupSearchOrUpdateToggle prospect={prospect}
                                                             isEditingProspect={isEditingProspect}
                                                             isSearchingProspect={isSearchingProspect}
                                                             toggleIsEditingProspect={toggleIsEditingProspect}
                                                             toggleIsSearchingProspect={toggleIsSearchingProspect}/>
                        </div>
                        :''
                    }

            </div>

            <Collapse isOpen={isProspectSectionOpened}>
                { isViewMode && prospect ?
                    <CollapsedProspectData prospect={prospect} project={project}/>
                    :<div className="mt-3">
                        { isEditingProspect
                            ? <FormCard withTitle={false} >
                                <ProspectFormHandling formValues={prospectFormValues}
                                                      validate={prospectFormValidate}
                                                      mode={prospectFormMode}
                                                      project={project}
                                                      setProject={setProject}
                                                      mutationCallback={ prospect && !userENF ? updateProspect : createProspect}
                                                      onChange={onChange}
                                                      consentsSchemas={consentsSchemas}/>
                            </FormCard>
                            : ''
                        }

                        { isSearchingProspect ?
                            <FormCard withTitle={false}>
                                <div className="d-flex flex-row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <FormSearchProspectWrapper setSearchedResults={setSearchedProspectResults}
                                                                   withRedirection={false}/>
                                    </div>
                                    { searchedProspectResults
                                        ? <div className="col-12 col-md-8">
                                            <h5 className="text-info">
                                                <strong>{searchedProspectResults.length}</strong>
                                                {searchedProspectResults.length > 1 ? ' prospects trouvés' : ' prospect trouvé'}
                                            </h5>
                                            { searchedProspectResults.length > 0
                                                ? <>
                                                    <h6 className="text-secondary">
                                                        <em>Cliquez sur un prospect pour l'associer au projet</em>
                                                    </h6>
                                                    { searchedProspectResults?.map(user =>
                                                        <ProspectListItem key={user.id !== undefined ? user.id : user.enfUid}
                                                                          user={user}
                                                                          callBack={addSearchedProspectToProject}/>)
                                                    }
                                                </>
                                                : ''
                                            }
                                        </div>
                                        : ''
                                    }
                                </div>
                            </FormCard>
                            : ''
                        }
                    </div>
                }
            </Collapse>

        </div>
    );
}

function CollapsedProspectData({prospect, project}) {
    return (
        prospect ?
            <Card className="d-flex flex-row justify-content-center col-12 ml-1 mt-3" color="light">
                <CardBody>
                    <ul className="text-left btn-block" style={{listStyleType: 'none', margin: 0, padding: 0}}>
                        <li className="row">
                            <em className="col-4 text-secondary">Identifiant</em>
                            <span className="col-8">N°{prospect.id}</span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Nom
                                d'usage</em>
                            <span className="col-8">{prospect.useName.toUpperCase()}</span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Nom de
                                naissance</em>
                            <span className="col-8">{prospect.nameBirth ?
                                prospect.nameBirth.toUpperCase()
                                : "-"}
                            </span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Prénom</em>
                            <span className="col-8">{prospect.firstName}</span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Autre(s)
                                prénom(s)</em>
                            <span className="col-8">{prospect.middleNames ?
                                prospect.middleNames.split(',').join(', ')
                                : "-"}
                            </span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Perimètre</em>
                            <span className="col-8">{prospect.perimeter ?
                                prospect.perimeter.label
                                : "-"}
                            </span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Email</em>
                            <span className="col-8">{prospect.email
                                ? <a href={`mailto:${prospect.email}`}>{prospect.email}</a>
                                : '-'}
                            </span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Téléphone</em>
                            <span className="col-8">{prospect.telephone ?
                                prospect.telephone
                                : "-"}
                            </span>
                        </li>

                        <hr/>
                        <h6 className="text-dark">Naissance</h6>
                        <li className="row">
                            <em className="col-4 text-secondary">Date <small>(jj/mm/aaaa)</small></em>
                            <span className="col-8">{formatDateBirth(prospect.birthDate)}</span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Pays</em>
                            <span
                                className="col-8">{prospect.birthCountryCode ?
                                prospect.birthCountryCode.labelFr
                                : "-"}
                            </span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Commune</em>
                            <span className="col-8">
                                {prospect.birthTownshipName && <span>{prospect.birthTownshipName} </span>}
                                {prospect.birthTownshipCode && <span>({prospect.birthTownshipCode})</span>}
                                {!(prospect.birthTownshipName || prospect.birthTownshipCode) && <span>-</span>}
                            </span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Nationalité</em>
                            <span className="col-8">{prospect.nationality ?
                                prospect.nationality.nationalityFr
                                : "-"}
                            </span>
                        </li>

                        <hr/>
                        <h6 className="text-dark">Adresse de domicile</h6>
                        <li className="row">
                            <em className="col-4 text-secondary">N° et nom
                                de voie</em>
                            <span className="col-8">{prospect.homeAddress ?
                                prospect.homeAddress
                                : "-"}
                            </span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Commune</em>
                            <span className="col-8">{prospect.homeTownshipName ?
                                prospect.homeTownshipName
                                : "-"}
                            </span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Code
                                postal</em>
                            <span className="col-8">{prospect.homePostCode ?
                                prospect.homePostCode
                                : "-"}
                            </span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Pays</em>
                            <span
                                className="col-8">{prospect.homeCountryCode ?
                                prospect.homeCountryCode.labelFr
                                : "-"}
                            </span>
                        </li>

                        <hr/>
                        <h6 className="text-dark">Autre</h6>
                        <li className="row">
                            <em className="col-4 text-secondary">Diplôme
                                obtenu</em>
                            <span className="col-8">{prospect.highestDiplomaLevel ?
                                prospect.highestDiplomaLevel.label
                                : "-"}
                            </span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Statut face
                                à l'emploi</em>
                            <span className="col-8">{prospect.professionalStatus ?
                                prospect.professionalStatus.label
                                : "-"}
                            </span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Exp. professionnelle</em>
                            <span className="col-8">{prospect.professionalExperience ?
                                prospect.professionalExperience.label
                                : "-"}
                            </span>
                        </li>
                        {project && <li className="row">
                            <em className="col-4 text-secondary">Exp. professionnelle dans le domaine</em>
                            <span className="col-8">{project.relatedProfessionalExperience ?
                                project.relatedProfessionalExperience.label
                                : "-"}
                            </span>
                        </li>}
                        <li className="row">
                            <em className="col-4 text-secondary">En
                                recherche d'emploi</em>
                            <span className="col-8">{
                                (prospect.lookingForJob && 'oui') ||
                                (prospect.lookingForJob === false && 'non') ||
                                ('-')
                            }</span>
                        </li>
                        <li className="row">
                            <em className="col-4 text-secondary">Inscrit à
                                pôle emploi</em>
                            <span className="col-8">{
                                (prospect.registeredAtEmploymentCenter && 'oui') ||
                                (prospect.registeredAtEmploymentCenter === false && 'non') ||
                                ('-')
                            }</span>
                        </li>
                    </ul>
                </CardBody>
            </Card>
            : ''
    )
}

const ButtonGroupSearchOrUpdateToggle = ({
                                     prospect,
                                     toggleIsEditingProspect,
                                     toggleIsSearchingProspect}) => {
    return (
        <ButtonGroup size={'sm'}>
            <Button type="button"
                    color={'primary'} outline={null !== prospect}
                    onClick={toggleIsEditingProspect}>
                { prospect
                    ? <span><FontAwesomeIcon icon={faPen}/> Modifier</span>
                    : <span><FontAwesomeIcon icon={faUserPlus}/> Créer</span>
                }
            </Button>
            <Button type="button"
                    color={'info'} outline
                    onClick={toggleIsSearchingProspect}>
                <FontAwesomeIcon icon={faSearch}/> Rechercher
            </Button>
        </ButtonGroup>
    )
}
const formatDateBirth = (date) => {
    let options = { year: 'numeric', month: 'numeric', day: 'numeric'};
    return new Date(date).toLocaleDateString('fr-FR', options);
}

ProjectSectionProspect.propTypes = {
    project: PropTypes.object.isRequired,
    prospect: PropTypes.object,
    section: PropTypes.string.isRequired,
    isViewMode: PropTypes.bool
};

ProjectSectionProspect.defaultProps = {
    prospect: null,
    isViewMode: true
};

export {CollapsedProspectData, ProjectSectionProspect};
