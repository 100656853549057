import React, {useContext, useState} from 'react';
import {Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Draggable from 'react-draggable';
import UserContext from "../contexts/UserContext";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {SEARCH_USER_ENF_EMAIL, USER_PROFILE_QUERY} from "../graphql/queries";
import InputMultipleEmails from "./Form/InputMultipleEmails";
import {PDFDownloadLink, pdf} from "@react-pdf/renderer";
import {SEND_POSITIONING_CERTIFICATE} from "../graphql/mutations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faDownload, faPlane, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import StatusCode from "http-status-codes";
import {NotificationContext} from "../contexts/NotificationContext";
import NotificationToast from "../lib/notification/NotificationToast";

function PdfModalDraggable(props) {
    const {currentUser} = useContext(UserContext);
    const {showToast} = useContext(NotificationContext);

    const [documentBlob, setDocumentBlob] = useState(null);
    const [documentBlobUrl, setDocumentBlobUrl] = useState(null);
    const [values, setValues] = useState({
        listToSend: [],
    });
    const [sendPositioningCertificate, {loading: sendPositioningCertificateLoading}] = useMutation(
        SEND_POSITIONING_CERTIFICATE,
        {
            onCompleted: data => {
                const r = data.SendPositioningCertificate;
                if (r.responseCode === StatusCode.OK) {
                    props.setProject(r.project);
                    showToast(new NotificationToast("PROJET",
                        "L'attestation a été envoyée. Le projet est clos.",
                        "success",
                        faCheckCircle));
                    props.toggle();
                }
            }
        }
    );

    const addPerson = person => {
        const formattedPerson = Array.isArray(person)
            ? person.map(p => formatPerson(p))
            : formatPerson(person);

        const isEmpty = (Array.isArray(formattedPerson) && formattedPerson.length === 0) ||
            (!Array.isArray(formattedPerson) && formattedPerson === null);
        if (isEmpty) {
            return;
        }

        setValues({...values, listToSend: values.listToSend.concat(formattedPerson)});
    }

    const removePerson = (email) => {
        let currentValues = {...values};
        const index = currentValues.listToSend
            .map((person) => {
                return person.email;
            })
            .indexOf(email);

        if (index > -1) {
            currentValues.listToSend.splice(index, 1);
        }

        setValues(currentValues);
    }

    const isEmailValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const formatPerson = person => {
        if (!isEmailValid(person?.email)) return null;

        let fullName = person.fullName;
        if (person.useName && person.firstName) {
            fullName = `${person.firstName} ${person.useName}`;
        } else if (!person.fullName) {
            fullName = person.email;
        }

        return {...person, fullName: fullName};
    }

    const searchUserLazyQuery = useLazyQuery(SEARCH_USER_ENF_EMAIL);
    useQuery(USER_PROFILE_QUERY, {
        variables: {userId: currentUser.id},
        onCompleted: data => {
            let personsToAdd = [data.Users[0]];
            if (props.object.prospect.email) {
                personsToAdd.push(props.object.prospect);
            }
            addPerson(personsToAdd);
        }
    });

    const onSubmit = (event) => {
        event.preventDefault();
        
        if (values?.listToSend.length < 1) return false;
        if (!documentBlob || !documentBlobUrl) return false;

        pdf(props.document).toBlob().then(file => {
            sendPositioningCertificate({
                variables: {
                    emails: values?.listToSend.map((person) => {
                        return person.email;
                    }),
                    attestation: {file},
                    projectId: props?.object?.id
                }
            });
        })

    }

    return (
        <div>
            <Draggable handle=".modal-header">
                <Modal isOpen={props.modal} toggle={props.toggle} backdrop={false} fade={false}
                       modalClassName={"nonmodal ui-widget-content"}>
                    <Form onSubmit={onSubmit} autoComplete={"off"}>
                        <ModalHeader toggle={props.toggle} style={{cursor: 'move'}}>
                            <div className={'d-flex justify-content-between'}>
                                <span>{props?.title}</span>
                            </div>
                        </ModalHeader>
                        <ModalBody style={{overflowY: "visible"}}>
                            <FormGroup>
                                <InputMultipleEmails
                                    lazyQuery={searchUserLazyQuery}
                                    inputId="search"
                                    label="Envoyer l'attestation à"
                                    placeholder="Ajouter une personne"
                                    addPerson={addPerson}
                                    removePerson={removePerson}
                                    listToSend={values?.listToSend ?? []}
                                    showPersonBy={'fullName'}
                                />
                            </FormGroup>

                        </ModalBody>

                        <ModalFooter>
                            <div className={'d-flex justify-content-between w-100 align-items-center'}>{
                                props?.document &&
                                <PDFDownloadLink
                                    document={props?.document}
                                    fileName={
                                        props?.titleDoc
                                        ?? generatePdfFilename(props?.object)
                                        + ".pdf"
                                    }>
                                    {({blob, url, loading, error}) => {
                                        setDocumentBlob(blob);
                                        setDocumentBlobUrl(url);
                                        return (
                                            <Button disabled={loading} size={'sm'} type={"button"}>
                                                <FontAwesomeIcon icon={loading ? faSpinner : faDownload}
                                                                 spin={loading}/> Télécharger en PDF
                                            </Button>
                                        );
                                    }}
                                </PDFDownloadLink>
                            }
                                <div className={'d-flex flex-grow-1 justify-content-end'}>
                                    <Button className={'mr-2'}
                                            id={"mymodal"}
                                            color={'danger'}
                                            type={"button"}
                                            onClick={props?.toggle}>
                                        Annuler
                                    </Button>

                                    <Button disabled={sendPositioningCertificateLoading} id={"mymodal"}
                                            color={'primary'} type={"submit"}>
                                        <FontAwesomeIcon icon={faPaperPlane}/>
                                        {
                                            sendPositioningCertificateLoading
                                                ? ' Envoi...'
                                                : ' Envoyer'
                                        }
                                    </Button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Draggable>
        </div>
    );
}

function generatePdfFilename(object) {
    return `projet_${object.id}_${getTodayDate()}`;
}

function getTodayDate() {
    let todayDate = new Date();
    return (todayDate.getDate() < 10 ? '0' + todayDate.getDate() : todayDate.getDate())
        + ((todayDate.getMonth() + 1) < 10 ? '0' + (todayDate.getMonth() + 1) : '' + todayDate.getMonth() + 1)
        + '' + todayDate.getFullYear();
}

export default PdfModalDraggable;