import React, {useState} from 'react';
import {Alert, Form, FormGroup, Input, Label} from "reactstrap";
import {useMutation, useLazyQuery} from "@apollo/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PerimetersListManagement from "../List/PerimetersListManagement";
import {SEARCH_USER_STAFF_ENF} from "../../graphql/queries";
import {ADD_LOCAL_ROLE_USER_FULL_PAYLOAD, REMOVE_LOCAL_ROLE_USER_FULL_PAYLOAD} from "../../graphql/mutations";
import {CREATE_USER_FULL_PAYLOAD} from "../../graphql/mutations";
import AutoSuggestInput from "../Form/AutoSuggestInput";
import {ROLE_ADMIN, ROLE_CHARGE_AIOA_IP, ROLE_GUEST} from "../../lib/security/roles";
import StatusCode from "http-status-codes";

const allRoles = [
    {
        id: ROLE_ADMIN,
        label: "Administrateur"
    },
    {
        id: ROLE_CHARGE_AIOA_IP,
        label: "Chargé AIOA-IP"
    },
    {
        id: ROLE_GUEST,
        label: "Invité"
    }
];

function AccessCard(props) {
    const onCompletedLocalRole = data => {
        const response = data.AddLocalRoleUser ?? data.RemoveLocalRoleUser;
        if (response.responseCode === 200) {
            setCompasUser(response.user);
            unsetMessages();
            setSuccessMessage('L\'utilisateur a été modifié avec succès')
        } else {
            setErrorMessage(response.responseMessage);
        }
    };
    const [addLocalRole, {loading: loadingAddLocalRole}] = useMutation(ADD_LOCAL_ROLE_USER_FULL_PAYLOAD, {
        onCompleted: onCompletedLocalRole
    });
    const [removeLocalRole, {loading: loadingRemoveLocalRole,}] = useMutation(REMOVE_LOCAL_ROLE_USER_FULL_PAYLOAD, {
        onCompleted: onCompletedLocalRole
    });

    const [createUser, {loading: loadingCreateUser}] = useMutation(CREATE_USER_FULL_PAYLOAD);

    const [searchUserStaff, {data: dataUserEnf, loading: loadingUserEnf}] = useLazyQuery(SEARCH_USER_STAFF_ENF);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [searchValue, setSearchValue] = useState('');
    const [user, setUser] = useState();
    const [role, setRole] = useState();

    const unsetMessages = () => {
        setSuccessMessage('');
        setErrorMessage('');
    }

    const handleAutoSuggestInputChange = e => {
        unsetMessages();
        setUser(undefined);
        setRole(undefined);
        const newValue = e.currentTarget.value;
        setSearchValue(newValue);
        if (newValue.length > 1) {
            searchUserStaff({
                variables: {
                    'name': newValue,
                    'firstName': ''
                }
            })
        }
    }

    const handleRoleChange = e => {
        unsetMessages();
        setRole(e.currentTarget.value);
    }

    const select = user => setUser(user);

    const createOrUpdateUser = (toAdd, toRemove) => {
        if (!user.compasUser) {
            createUser({
                variables:
                    {
                        "user": {
                            "enfUid": user.uid
                        }
                    }
            }).then((response) => {
                const newUser = response.data.CreateUser.user;
                setCompasUser(newUser);
                submitPerimeters(newUser.id, toAdd, toRemove);
            });
        } else {
            submitPerimeters(compasUser.id, toAdd, toRemove);
        }
    }

    const submitPerimeters = (userId, toAdd, toRemove) => {
        if (toAdd.length > 0) {
            addLocalRole({
                variables: {
                    id: userId,
                    localRoleUser: [{
                        roleId: role,
                        perimeterIds: toAdd
                    }]
                }
            }).then(({data}) => {
                if (data.AddLocalRoleUser.responseCode === StatusCode.OK && toRemove.length > 0) {
                    removeLocalRole({
                        variables: {
                            id: userId,
                            localRoleUser: [{
                                roleId: role,
                                perimeterIds: toRemove
                            }]
                        }
                    });
                }
            });
        } else if (toRemove.length > 0) {
            removeLocalRole({
                variables: {
                    id: userId,
                    localRoleUser: [{
                        roleId: role,
                        perimeterIds: toRemove
                    }]
                }
            });
        }
    }

    const setCompasUser = newCompasUser => setUser({...user, compasUser: newCompasUser});

    const loading = loadingAddLocalRole || loadingRemoveLocalRole || loadingCreateUser;
    const canSearch = searchValue.length > 1 && user === undefined;
    const items = dataUserEnf
        ? dataUserEnf.UsersEnf
        : []

    const compasUser = user ? (user.compasUser ?? {localRoles: []}) : null;

    return (
        <Form autoComplete="off">
            {
                loadingCreateUser || loadingAddLocalRole || loadingRemoveLocalRole
                    ? <p><FontAwesomeIcon icon={['fas', 'spinner']} spin/> Mise à jour en cours</p>
                    : ''
            }
            {
                errorMessage
                    ? <p className={"alert alert-danger"}>{errorMessage}</p>
                    : ''
            }
            {
                !loading
                    ? <>
                        {successMessage && <Alert color={'success'}>{successMessage}</Alert>}

                        <FormGroup>
                            <div className={'d-flex'}>
                                <div style={{flex: 1}}>
                                    <Label for="fullName">Utilisateur *</Label>
                                </div>
                            </div>
                            <AutoSuggestInput
                                placeholder={'Rechercher par nom'}
                                onChange={handleAutoSuggestInputChange}
                                value={user ? user.fullName : searchValue}
                                loading={loadingUserEnf}
                                items={canSearch ? items : null}
                                onSelect={select}
                                renderItem={u => `${u.fullName} (${u.login})`}
                            />
                        </FormGroup>

                        {
                            compasUser
                                ?
                                <>
                                    <FormGroup>
                                        <div className={'d-flex'}>
                                            <div style={{flex: 1}}>
                                                <Label for="selectedRole">Rôles *</Label>
                                            </div>
                                        </div>
                                        <Input type="select"
                                               name="selectedRole"
                                               id="selectedRole"
                                               value={role}
                                               onChange={handleRoleChange}
                                        >
                                            <option value={""}>Sélectionner un rôle</option>
                                            {
                                                compasUser.localRoles.length > 0 ?
                                                    <optgroup label="Modifier">
                                                        {
                                                            compasUser.localRoles.map(localRole =>
                                                                <option
                                                                    key={localRole?.role.id}
                                                                    value={localRole?.role.id}
                                                                >
                                                                    {localRole?.role.label}
                                                                </option>
                                                            )
                                                        }
                                                    </optgroup>
                                                    : ''
                                            }

                                            {
                                                allRoles
                                                    .filter(r => !compasUser.localRoles.map(lr => lr.role.id).includes(r.id))
                                                    .length > 0 &&
                                                <optgroup label="Ajouter">
                                                    {
                                                        allRoles
                                                            .filter(r => !compasUser.localRoles.map(lr => lr.role.id).includes(r.id))
                                                            .map(r =>
                                                                <option
                                                                    key={r?.id}
                                                                    value={r?.id}
                                                                >
                                                                    {r?.label}
                                                                </option>
                                                            )
                                                    }
                                                </optgroup>
                                            }
                                        </Input>
                                    </FormGroup>

                                    {
                                        role && !loading &&
                                        <PerimetersListManagement
                                            selectedUser={compasUser}
                                            localRoleSelected={compasUser.localRoles
                                                .filter(({role: r}) => r.id === role)
                                            }
                                            adminRole={props?.localRoles
                                                .filter(({role}) => (role.id === ROLE_ADMIN)).shift()
                                            }
                                            update={createOrUpdateUser}
                                        />
                                    }
                                </>
                                : ''
                        }
                    </>
                    : ''
            }
        </Form>
    );
}

export default AccessCard;