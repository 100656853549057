import {CSVLink} from "react-csv";
import React, {useRef, useState} from "react";
import {useLazyQuery} from "@apollo/client";
import PropTypes from "prop-types";
import LoadingSpinner from "../Default/LoadingSpinner";

const QueryExportLink = (props) => {
    const CSVButton = useRef(null);
    const [error, setError] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [loadData, { called, loading }] = useLazyQuery(props.query, {
        fetchPolicy: 'network-only',
        variables: props.filters ?? {},
        onCompleted: (queryData => {
            const data = transformQueryObjects(queryData, props.queryObject, props.headers);
            setCsvData(data);
        }),
        onError: () => setError(true)
    });

    if (called && loading) {
        return <LoadingSpinner/>
    }

    if (error) {
        return <span className='mx-1'> Erreur <span role={'img'} aria-label={'disapointed face'}>😥</span></span>;
    }

    return (
        <>
            <div onClick={() => loadData().then(() => CSVButton.current.link.click())}>{props.children}</div>
            <CSVLink
                ref={CSVButton}
                filename={getTodayDate() + '_' + props.filename}
                data={csvData}
                headers={props.headers}
                separator=";"
                enclosingCharacter=""
                style={{display: 'none'}}
            >
                {props.children}
            </CSVLink>
        </>
    );
}

const decodeHtml = (data) => {
    let container = document.createElement('container');
    container.innerHTML = data;
    return container.innerText;
}

const transformHtml = (data) => {
    if (!data) return data;

    data.replace(/(<br>)/ig, 'vbCrLf')
        .replace(/(<\/([p, br])>)/ig, 'vbCrLf')
        .replace(/(<([^>]+)>)/ig, '');

    return decodeHtml(data);
}

const isHtml = (data) => {
    if (!data) return data;
    let doc = new DOMParser().parseFromString(data, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

const defaultTransformData = (data) => {
    if (typeof data === 'boolean') {
        data = data ? 'Oui' : 'Non';
    }
    if (isHtml(data)) {
        data = transformHtml(data);
    }

    return data;
}

const getDataFromDataKey = (object, key) => {
    let keys = key.split('_');
    let firstKey = keys.shift();

    if (object[firstKey]) {
        if (Array.isArray(object[firstKey])) {
            return object[firstKey]
                .map((data) => getDataFromDataKey(data, keys.join('_')))
                .join(',');
        }

        return keys.length
            ? getDataFromDataKey(object[firstKey], keys.join('_'))
            : object[firstKey];
    }
    return '';
}

const transformObject = (object, headers) => {
    let csvObject = {};

    for (let index in headers) {
        const column = headers[index];
        const key = column.key;
        const dataKeys = column?.dataKey
            ? column.dataKey.split(' && ')
            : key.split(' && ');

        const value = [];
        for (let indexKey in dataKeys) {
            let returnedData = getDataFromDataKey(object, dataKeys[indexKey]);

            if (column.transform && column.transform instanceof Function) {
                returnedData = column.transform(returnedData);
            } else {
                returnedData = defaultTransformData(returnedData);
            }

            value.push(returnedData.trim());
        }
        csvObject[key] = value
            ? value.join(' ')
            : '';
    }

    return csvObject;
}

const transformQueryObjects = (queryData, queryObject, headers) => {
    const objects = queryData[queryObject];
    if (!objects || !Array.isArray(objects)) return false;
    return objects.map(object => transformObject(object, headers));
}

const getTodayDate = () => {
    let date = new Date();
    let month = date.getMonth() + 1;
    month = month > 9 ? month : '0' + month;
    let day = date.getDate();
    day = day > 9 ? day : '0' + day;

    return date.getFullYear() + month + day;
}

QueryExportLink.propTypes = {
    filename: PropTypes.string.isRequired,
    query: PropTypes.object.isRequired,
    headers: PropTypes.array,
    filters: PropTypes.object,
    queryObject: PropTypes.string.isRequired,
};

QueryExportLink.defaultProps = {
    filename: 'compas_export.csv',
    filters: {},
}

export default QueryExportLink;