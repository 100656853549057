import React from "react";
import {useQuery} from "@apollo/client";
import {UNITS_ENROL_OPTIONS} from "../../graphql/queries"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {teachingModalities, temporalities, EnrolOptionListItem} from "../List/EnrolOptionListItem";

const convertOptionToOptionId = option =>
    [
        option.perimeter.id,
        teachingModalities[option.teachingModality],
        temporalities[option.temporality],
        option.year
    ].join('-');

function EnrolOptions({code, options, setOption}) {
    const {loading, error, data} = useQuery(UNITS_ENROL_OPTIONS, {variables: {code: code}});
    const convertOptionToSortId = option =>
        option.year + option.temporality + option.teachingModality; // year, temporality, teachingModality ASC

    if (loading) {
        return (
            <div className={"d-flex flex-row justify-align-content"}>
                <p><FontAwesomeIcon icon={['fas', 'spinner']} spin/> Chargement</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className={"d-flex flex-row justify-align-content"}>
                <p>Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span> {code}</p>
            </div>
        );
    }

    if (data.Units.length === 0) {
        return (
            <div className={"d-flex flex-row justify-align-content"}>
                <p>Aucune unité trouvée <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
            </div>
        );
    }

    if (data.Units.length > 1) {
        return (
            <div className={"d-flex flex-row justify-align-content"}>
                <p>Plusieurs unités retournées <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
            </div>
        );
    }

    const unitEnrolOptions = data.Units[0].enrolOptions;

    if (unitEnrolOptions === null) {
        return (
            <div className={"d-flex flex-row justify-align-content"}>
                <p>Aucune ouverture disponible <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
            </div>
        );
    }

    const unitEnrolOptionsSorted = unitEnrolOptions.slice().sort((op1, op2) => { // slice return copy of unitEnrolOptions
        if (convertOptionToSortId(op1) < convertOptionToSortId(op2)) {
            return -1;
        }
        return 1;
    });

    const optionsIds = options.map(convertOptionToOptionId);

    return <div>
        {unitEnrolOptionsSorted.map((enrolOption, index) =>
            <EnrolOptionListItem
                key={index}
                active={optionsIds.includes(convertOptionToOptionId(enrolOption))}
                onClick={() => setOption(enrolOption)}
                option={enrolOption}
                index={enrolOption.year + enrolOption.teachingModality + enrolOption.perimeter.id} />
        )}
    </div>;
}

export {convertOptionToOptionId, EnrolOptions};
