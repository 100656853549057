import React from 'react';
import Header from "../components/Layout/Header";

function ProjectsPage() {

    return (
        <div className={"container-fluid"}>

            <Header titlePage={"P R O J E C T S"}
                    faIcon={['fas', 'project-diagram']}
            />

            <div className={"row justify-content-around"}>
                <div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
                    <div className={"card card-body"}>
                        <em>CREATE</em>
                    </div>
                </div>
                <div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
                    <div className={"card card-body"}>
                        <em>SEARCH</em>
                    </div>
                </div>
                <div className={"col-12 col-sm-6 col-md-4 col-lg-3"}>
                    <div className={"card card-body"}>
                        <em>RECENT</em>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ProjectsPage;
