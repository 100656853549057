class ProspectFormValidation {

    constructor(project, prospect=null, userENF=null) {
        if (userENF !== null) {
            this.values = this.getValuesFromProjectAndUserENF(project, userENF);
        } else {
            this.values = (prospect !== null)
                ? this.getValuesFromProspect(prospect, project)
                : this.getValuesFromProjectOnly(project);
        }
    }

    getValues = () => {
        return this.values;
    }

    getInitialValues = () => {
        return {
            enfUid: '',
            perimeter: '',
            gender: '',
            useName: '',
            nameBirth: '',
            firstName: '',
            middleNames: '',
            email: '',
            birthDate: '',
            telephone: '',
            nationality: '',
            birthCountryCode: '',
            birthTownshipName: '',
            birthTownshipCode: '',
            homeAddress: '',
            homeAddressAdditional: '',
            homeTownshipName: '',
            homePostCode: '',
            homeCountryCode: '',
            professionalStatus: '',
            highestDiplomaLevel: '',
            professionalExperience: '',
            projectRelatedProfessionalExperience: '',
            lookingForJob: '',
            registeredAtEmploymentCenter: '',
        }
    };

    getValuesFromProjectOnly = (project) => {
        let values = this.getInitialValues();
        values.perimeter = [project][0].perimeter.id;
        values.projectRelatedProfessionalExperience = [project][0].relatedProfessionalExperience
            ? [project][0].relatedProfessionalExperience.id
            : '';
        return values;
    }

    getValuesFromProjectAndUserENF = (project, userENF) => {
        let values = this.getInitialValues();
        values.perimeter = [project][0].perimeter.id;
        values.projectRelatedProfessionalExperience = [project][0].relatedProfessionalExperience
            ? [project][0].relatedProfessionalExperience.id
            : '';

        values.enfUid = userENF.enfUid;
        values.gender = userENF.gender;
        values.useName = userENF.useName;
        values.firstName = userENF.firstName;
        values.nameBirth = userENF.nameBirth;
        values.birthDate = userENF.birthDate;
        values.email = userENF.email;

        return values;
    }

    getValuesFromProspect = (prospect, project) => {
        return {
            ...prospect,
            projectRelatedProfessionalExperience: project.relatedProfessionalExperience?.id,
        };
    }

    validate = () => {
        return {
            enfUid: uid => this.enfUidValidation(uid),
            perimeter: this.noValidation,
            gender: val => this.isRequired('Le genre', val),
            useName: name => this.nameValidation("Le nom d'usage", name, true),
            nameBirth: name => this.nameValidation('Le nom de naissance', name, false),
            firstName: name => this.nameValidation('Le prénom', name, true),
            middleNames: name => this.nameValidation('Les prénoms', name, false),
            email: (email, isBirthDateEmpty) => this.emailValidation(email, isBirthDateEmpty),
            birthDate: (date, isEmailEmpty) => this.birthDateValidation(date, isEmailEmpty),
            telephone: this.phoneValidation,
            nationality: code => this.codeThreeDigitsValidation('Le code nationalité', code),
            birthCountryCode: code => this.codeThreeDigitsValidation('Le code de pays de naissance', code),
            birthTownshipName: name => this.nameValidation('La commune de naissance', name, false),
            birthTownshipCode: this.noValidation,
            homeAddress: name => this.nameValidation('Le numéro et nom de voie', name, false),
            homeAddressAdditional: name => this.nameValidation('Le complément d’adresse', name, false),
            homeTownshipName: name => this.nameValidation('La commune de résidence', name, true),
            homePostCode: (postCode, homeCountryCode) => this.postalCodeValidation('Le code postal', postCode, homeCountryCode),
            homeCountryCode: val => this.isRequired('Le pays de résidence', val),
            professionalStatus: this.noValidation,
            highestDiplomaLevel: this.noValidation,
            professionalExperience: this.noValidation,
            projectRelatedProfessionalExperience: this.noValidation,
            lookingForJob: this.noValidation,
            registeredAtEmploymentCenter: this.noValidation
        }
    };

    isRequired = (fieldName, fieldValue) => {
        if (this.isEmpty(fieldValue)) {
            return `${fieldName} est requis`;
        }
    };

    enfUidValidation = (uid) => {
        if (this.isEmpty(uid) || /^([a-zA-Z0-9]{14}.[0-9]{8}(ELE|PER|HEB))$/.test(uid)) {
            return null;
        }
        return "L'identifiant ENF est incorrect";
    }

    nameValidation = (fieldName, fieldValue, isRequired) => {
        if (isRequired && this.isEmpty(fieldValue)) {
            return `${fieldName} est requis`;
        }
        if (isRequired && fieldValue.trim().length < 2) {
            return `${fieldName} doit comporter 2 caractères minimum`;
        }
        return null;
    };

    phoneValidation = number => {
        if (this.isEmpty(number)) {
            return null;
        }
        if (!(/^((\+)?([0-9 ]{1,2}([ .-]?[0-9]{1,2})){2,5})$/.test(number))) {
            return "Le numéro n'est pas valide"
        }
    };

    birthDateValidation = (date, isEmailEmpty) => {
        if (this.isEmpty(date) && isEmailEmpty) {
            return 'Vous devez renseigner une date de naissance et/ou un email';
        }
        return null;
    };

    emailValidation = (email, isBirthDateEmpty) => {
        if (this.isEmpty(email) && isBirthDateEmpty) {
            return 'Vous devez renseigner un email et/ou une date de naissance';
        }
        if (!this.isEmpty(email) &&
            !(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))) {
            return 'Adresse email incorrecte';
        }
        return null;
    };

    codeThreeDigitsValidation = (fieldName, code) => {
        if (this.isEmpty(code) || /^[A-Z]{2,3}/.test(code)) {
            return null;
        }
        return `${fieldName} est incorrect`;
    };

    postalCodeValidation = (fieldName, postCode, homeCountryCode) => {
        if (this.isEmpty(postCode) && homeCountryCode === "FRA") {
            return `${fieldName} est requis car l'adresse est française`
        }
        if (this.isEmpty(postCode) || /\b\d{5}\b/g.test(postCode)) {
            return null;
        }
        return `${fieldName} est incorrect`;
    };

    isEmpty = (value) => {
        return value === null || value === undefined ? true : value.trim() === '';
    }

    noValidation = () => {
        return null;
    };
}

export default ProspectFormValidation;
