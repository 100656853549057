import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

function Ensemble({schema, children}) {
    return <div className={'schema-ensemble'}>
        <div className={'d-flex justify-content-between align-items-start'}>
            <span className={'font-weight-bold'}>{schema.libelle}{schema.extras.obtained
                ? <span> <FontAwesomeIcon className={'text-success'} icon={faCheckCircle}/></span>
                : ''}
            </span>
            <span className={'badge badge-info rounded'}>{schema.credits} ECTS</span>
        </div>

        {children}
    </div>
}

export default Ensemble;