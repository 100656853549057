import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {CustomInput} from "reactstrap";

function Schema(props) {
    const {edit, schema, onUpdate} = props;
    const {credits} = schema;
    const handleSwitchAlternance = (e) => {
        onUpdate(schema.extras.id, 'isAlternance', schema?.extras?.isAlternance === 0 ? 1 : 0, null, false, false);
    }
    return <div className={'schema-schema'}>
        <div className={'d-flex justify-content-end schema-legends'}>
            <div title={'Obtenu'} className={'bg-success mr-1'}/> <span> Obtenu</span>
            <div title={'Formation'} className={'bg-ens ml-2 mr-1'}/> <span> Formation</span>
            <div title={'VES'} className={'bg-ves ml-2 mr-1'}/> <span> VES</span>
            <div title={'VAE'} className={'bg-vae ml-2 mr-1'}/> <span> VAE</span>
        </div>
        <div className={'d-flex justify-content-between mt-3'}>
            <span>Total de {credits} ECTS{props.schema.extras.obtained ? <span> <FontAwesomeIcon className={'text-success'} icon={faCheckCircle}/> Obtenu</span> : ''}</span>
            {edit && <CustomInput type="switch"
                                  id="switchIsAlternance"
                                  name="switchIsAlternance"
                                  label={`en Alternance`}
                                  checked={schema?.extras?.isAlternance === 1}
                                  onChange={handleSwitchAlternance}
                                  className={"text-dark"}
            />}
            {!edit && schema?.extras?.isAlternance === 1 && <span>En alternance</span>}
        </div>
        {props.children}
    </div>
}

export default Schema;