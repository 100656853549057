import React, {useContext} from 'react';
import QueryExportLink from "../Statistics/QueryExportLink";
import UserContext from "../../contexts/UserContext";
import PropTypes from "prop-types";
import {ROLE_CHARGE_AIOA_IP} from "../../lib/security/roles";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SchemaTool from "../../lib/schema/SchemaTool";

const ProjectsExportLink = (props) => {
    const { currentUser } = useContext(UserContext);
    const currentUserComputedPerimeter = currentUser?.localRolesComputed[ROLE_CHARGE_AIOA_IP]
        ? currentUser?.localRolesComputed[ROLE_CHARGE_AIOA_IP][0]
        : '';

    return (
        <QueryExportLink
            filename={props.filename}
            query={props.query}
            queryObject={props.queryObject}
            headers={props.headers}
            filters={{
                userID: currentUser?.id ? currentUser.id : 0,
                perimeter: currentUserComputedPerimeter,
                ...props.filters,
            }}
        >
            {
                props.children
                    ? props.children
                    : <button className='btn btn-primary mr-2'>
                        <FontAwesomeIcon icon={faFileExcel} color='white'/> {props.button}
                    </button>
            }
        </QueryExportLink>
    )
}

const getUnitsFromSchema = (schemaData = null) => {
    return SchemaTool.getUnits(schemaData)
        .filter((unit) => unit?.extras?.mode !== 'none' && unit?.extras?.obtained === 0)
        .map((unit) => unit.code)
        .join(',');
}

const getFundingFromSchema = (schemaData = null) => {
    return SchemaTool.getFunding(schemaData)
        .map((funding) => funding.id)
        .filter((funding, index, self) => self.indexOf(funding) === index)
        .join(',');
}

const projectHeaders = [
    { label: "Projet ID", key: "id" },
    { label: "Projet créateur", key: "creator_fullName" },
    { label: "Projet contributeurs", key: "contributors_fullName" },
    { label: "Prospect ID", key: "prospect_id" },
    { label: "Prospect prénom", key: "prospect_firstName" },
    { label: "Prospect autres prénoms", key: "prospect_middleNames" },
    { label: "Prospect nom de naissance", key: "prospect_nameBirth" },
    { label: "Prospect nom d'usage", key: "prospect_useName" },
    { label: "Prospect date de naissance", key: "prospect_birthDate" },
    { label: "Prospect code pays de naissance", key: "prospect_birthCountryCode_iso3Code" },
    { label: "Prospect pays de naissance", key: "prospect_birthCountryCode_labelFr" },
    { label: "Prospect code ville de naissance", key: "prospect_birthTownshipCode" },
    { label: "Prospect nom ville de naissance", key: "prospect_birthTownshipName" },
    { label: "Prospect code pays nationalité", key: "prospect_homeCountryCode_iso3Code" },
    { label: "Prospect pays nationalité", key: "prospect_homeCountryCode_labelFr" },
    { label: "Prospect téléphone", key: "prospect_telephone" },
    { label: "Prospect courriel", key: "prospect_email" },
    { label: "Prospect adresse domicile", key: "prospect_homeAddress" },
    { label: "Prospect adresse complémentaire domicile", key: "prospect_homeAddressAdditional" },
    { label: "Prospect ville domicile", key: "prospect_homeTownshipName" },
    { label: "Prospect code postal domicile", key: "prospect_homePostCode" },
    { label: "Prospect code pays domicile", key: "prospect_homeCountryCode_iso3Code"},
    { label: "Prospect pays domicile", key: "prospect_homeCountryCode_labelFr"},
    { label: "Prospect durée d'expérience professionnelle", key: "prospect_professionalExperience_label" },
    { label: "Prospect à la recherche d'une emploi", key: "prospect_lookingForJob" },
    { label: "Prospect inscrit à Pôle emploi", key: "prospect_registeredAtEmploymentCenter" },
    { label: "Projet date de création", key: "createdAt" },
    { label: "Projet date de dernière modification", key: "updatedAt" },
    { label: "Projet code cursus cible", key: "cursusTargeted_code" },
    { label: "Projet unités cibles", key: "units", dataKey: "schema_schema", transform: getUnitsFromSchema },
    { label: "Projet financements", key: "funding", dataKey: "schema_schema", transform: getFundingFromSchema },
    { label: "Projet durée d'expérience professionnelle en relation avec le domaine", key: "relatedProfessionalExperience_label" },
    { label: "Projet notes", key: "notes" },
    { label: "Projet conclusion", key: "conclusion" },
];

ProjectsExportLink.propTypes = {
    filename: PropTypes.string.isRequired,
    query: PropTypes.object.isRequired,
    filters: PropTypes.object,
    queryObject: PropTypes.string.isRequired,
    headers: PropTypes.array.isRequired,
    button: PropTypes.string.isRequired,
};

ProjectsExportLink.defaultProps = {
    filename: 'compas_projects_export.csv',
    queryObject: 'Projects',
    headers: projectHeaders,
    filters: {},
    button: 'Exporter',
}

export default ProjectsExportLink;