class SchemaTool {
    static initSchema(schema) {
        if (typeof schema === 'string') {
            schema = JSON.parse(schema);
        }
        return schema;
    }

    static getUnits(schema, defaultValues = []) {
        return SchemaTool.getSchemaObject(
            schema,
            defaultValues,
            ((schemaObject) => schemaObject.type && schemaObject.type === 'unite'),
            ((schemaObject) => !schemaObject.obtained || schemaObject.obtained === 0
                && !schemaObject.mode || schemaObject.mode !== 'none')
        );
    }

    static getFunding(schema, defaultValues = []) {
        return SchemaTool.getSchemaObject(
            schema,
            defaultValues,
            ((schemaObject) => schemaObject.__typename && schemaObject.__typename === 'Funding'),
            ((schemaObject) => !schemaObject.obtained || schemaObject.obtained === 0
                && !schemaObject.mode || schemaObject.mode !== 'none')
        );
    }

    static getSchemaObject(
        schema,
        insideValues = [],
        conditionFunction,
        filterFunction = () => true
    ) {
        if (!schema) return insideValues;

        schema = SchemaTool.initSchema(schema);

        for (let index in schema) {
            let schemaObject = schema[index];

            if (typeof schemaObject !== 'string') {
                if (filterFunction(schemaObject)) {
                    if (conditionFunction(schemaObject)) {
                        insideValues.push(schemaObject);
                    }
                    insideValues.concat(
                        SchemaTool.getSchemaObject(schemaObject, insideValues, conditionFunction, filterFunction)
                    );
                }
            }
        }

        return insideValues;
    }
}

export default SchemaTool;