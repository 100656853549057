import React from 'react';
import {Label} from "reactstrap";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PropTypes} from "prop-types";
import FormSearchWithLazyQuery from "./FormSearchWithLazyQuery";

function InputMultipleEmails({
    lazyQuery,
     inputId,
     label,
     placeholder,
     addPerson,
     removePerson,
     listToSend,
     showPersonBy,
 }){
    const handleSubmit = value => {
        addPerson({email: value})
    }

    const handleSelect = (person) => {
        addPerson(person);
    }

    return (
        <div>
            <Label for={inputId}>{label}</Label>
            <div className={'d-flex flex-wrap'}>
                {
                    listToSend.map(personPresent =>
                        <div key={personPresent?.email} className={"d-flex presentEmailContainer"} title={personPresent?.email}>
                            <div className={"presentEmail text-primary"}>
                                {personPresent[showPersonBy]}
                            </div>
                            <button type={"button"} onClick={() => removePerson(personPresent?.email)}>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={"text-primary"}
                                />
                            </button>
                        </div>
                    )
                }

                <div className={'flex-grow-1'}>
                    <FormSearchWithLazyQuery
                        lazyQuery={lazyQuery}
                        renderItem={u => `${u.firstName} ${u.useName} (${u.email})`}
                        searchVariableName={'name'}
                        variables={{isFilterStaff: true}}
                        onSelect={handleSelect}
                        onSubmit={handleSubmit}
                        minLength={2}
                        placeholder={placeholder}
                    />
                </div>
            </div>
        </div>
    );
}

InputMultipleEmails.propTypes = {
    inputId: PropTypes.number,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    addPerson: PropTypes.number.isRequired,
    removePerson: PropTypes.number.isRequired,
    listToSend: PropTypes.array,
    showPersonBy: PropTypes.string,
}

export default InputMultipleEmails;