import React from "react";
import Branche from "./Branche";
import Branches from "./Branches";
import Choix from "./Choix";
import Commentaire from "./Commentaire";
import Cursus from "./Cursus";
import Ensemble from "./Ensemble";
import Schema from "./Schema";
import Unite from "./Unite";
import Unites from "./Unites";

function Element(props) {
    const schema = props.schema;
    if (schema === undefined) {
        return '';
    }

    const {type, extras} = schema;

    const processChildren = (element, index) => <Element {...props} key={index} schema={element} />;

    return <div className={`schema-element ${extras?.obtained ? ' schema-obtained' : ''}`}>
        {
            (type === 'unite' && <Unite {...props} />) ||
            (type === 'commentaire' && <Commentaire {...props} />) ||
            (type === 'cursus' && <Cursus {...props} />) ||
            (type === 'unites' && <Unites {...props}>{schema.unites.map(processChildren)}</Unites>) ||
            (type === 'choix' && <Choix {...props}>{schema.choix.map(processChildren)}</Choix>) ||
            (type === 'branches' && <Branches {...props}>{schema.branches.map(processChildren)}</Branches>) ||
            (type === 'ensemble' && <Ensemble {...props}>{schema.schema.map(processChildren)}</Ensemble>) ||
            (type === 'branche' && <Branche {...props}>{schema.schema.map(processChildren)}</Branche>) ||
            (type === 'schema' && <Schema {...props}>{schema.schema.map(processChildren)}</Schema>)
        }
    </div>
}

export default Element;
