import React, {useState} from "react";
import {Button, Collapse, FormGroup, Input} from "reactstrap";
import LoadingSpinner from "../Default/LoadingSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faExclamation,
    faLock,
} from "@fortawesome/free-solid-svg-icons";

function FormConsentsProspect({
                                  consents,
                                  handleChange,
                                  loading,
                                  displayButton,
                                  validConsents,
                                  validConsentsText,
                                  consentsSchemas
                              }) {

    const [open, setOpen] = useState(displayButton);
    const toggleCollaspe = () => {
        setOpen(!open);
    }

    return <>
        <div className="d-flex flex-row justify-content-between align-items-center text-dark">
            <h5 className="col-9 text-left">
                <FontAwesomeIcon icon={faLock}/>
                <em className="ml-2 mr-2 small text-uppercase">Protection des données</em>
            </h5>
            {!displayButton &&
            <Button size={"sm"}
                    color="info"
                    outline
                    onClick={toggleCollaspe}>
                {open
                    ? <em>Fermer <FontAwesomeIcon icon={faChevronUp}/></em>
                    : <em>Afficher <FontAwesomeIcon icon={faChevronDown}/></em>
                }
            </Button>
            }
        </div>

        <Collapse isOpen={open}>
            {
                Object.keys(consentsSchemas).length
                    ? <>
                        {
                            Object.keys(consentsSchemas).map(function (consentId) {
                                return <FormGroup key={consentId} check>
                                    <Input type="checkbox"
                                           name={consentId}
                                           id={"prospect" + consentId}
                                           onChange={handleChange}
                                           checked={
                                               consents
                                                   ? consents[consentId]
                                                   : consentsSchemas[consentId]?.mandatory
                                           }
                                           disabled={consentsSchemas[consentId]?.mandatory}
                                    />
                                    {consentsSchemas[consentId]?.mandatory &&
                                    <FontAwesomeIcon title={'Requis'} className={'text-danger mr-1'} icon={faExclamation}/>}
                                    <span dangerouslySetInnerHTML={{__html: consentsSchemas[consentId]?.label}}/>
                                </FormGroup>
                            })
                        }

                        {
                            displayButton
                                ? <div className={"row justify-content-around align-items-center pt-3 pt-lg-5"}>
                                    <div className={"col-12 col-md-6"}>
                                        <small className={"row pl-3"}>
                                            Cette demande doit être réalisée auprès du prospect, dans le cadre du RGPD.
                                        </small>
                                        <small className={"row pl-3"}>
                                            <span>Les consentements requis
                                                (<FontAwesomeIcon className={'text-danger'} icon={faExclamation}/>)
                                                sont un préalable à la création du projet sur COMPAS.</span>
                                        </small>
                                        <small className={"row pl-3"}>
                                            <span>Le prospect peut retirer son consentement à tout moment en s'adressant à son
                                            centre ou à <a href='mailto:sifa.compas@cnam.fr'>
                                                    sifa.compas@cnam.fr</a>.
                                            </span>
                                        </small>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        {
                                            loading
                                                ? <LoadingSpinner text={"Enregistrement des préférences"} color={'info'}/>
                                                :
                                                <button className={`btn btn-lg btn-success btn-block`} onClick={validConsents}>
                                                    <em> {validConsentsText}</em>
                                                </button>
                                        }
                                    </div>
                                </div>
                                : ''
                        }
                    </>
                    : <LoadingSpinner text={"Chargement"}/>
            }
        </Collapse>
    </>
}

export default FormConsentsProspect;