import React, {useState} from 'react';
import Header from "../components/Layout/Header";
import {faCoins, faSearch, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useQuery} from "@apollo/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FundingListItem from "../components/List/FundingListItem";
import {FUNDING_PERIMETER_QUERY, PERIMETER_BY_ID} from "../graphql/queries";
import WidgetCard from "../components/Card/WidgetCard";
import {Alert, Button, ListGroupItem} from "reactstrap";
import {ListGroup} from "react-bootstrap";
import FormSearchFunding from "../components/Form/FormSearchFunding";
import BackButton from "../components/Button/BackButton";


function SearchResultsFundingsPage(props) {
    const [values, setValues] = useState(props.location.state?.values);

    const {loading, error, data, refetch} = useQuery(FUNDING_PERIMETER_QUERY, {
        variables: {
            perimeter: values.perimeter?.value ?? null,
        },
    });
    const onValuesChange = e => {
        setValues({
            ...values,
            perimeter: {
                ...values.perimeter,
                value: values.perimeter?.value === e.currentTarget.dataset.id ? null : e.currentTarget.dataset.id,
            }
        })
    }

    return (
        <div>
            <Header titlePage={"Résultat de la recherche"}
                    faIcon={faCoins}
            />
            <BackButton className={'mb-2'} />
            <SearchSummary
                refetch={refetch}
                dataCount={data?.Fundings?.length}
                onValuesChange={onValuesChange}
                values={values}
                localRoles={props.location.state?.localRoles}
            />

            <div className={"d-flex my-3 justify-content-end"}>
                <a href={"/funding/create"} className={"btn btn-success btn-xs"}><FontAwesomeIcon icon={faPlus}/> Créer un
                    financement</a>
            </div>

            {
                loading
                    ?
                    <div className={"d-flex flex-row justify-align-content"}>
                        <p><FontAwesomeIcon icon={['fas', 'spinner']} spin/> Chargement</p>
                    </div>
                    : ""
            }

            {
                error
                    ?
                    <div className={"d-flex flex-row justify-align-content"}>
                        <p>Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
                    </div>
                    : ""
            }

            {
                data?.Fundings?.map(funding => <FundingListItem key={funding.id} {...funding}/>)
            }
        </div>
    )
}

function SearchSummary(props) {
    const [edit, setEdit] = useState(false);

    const toggleEdit = () => {
        setEdit(!edit);
    }

    const onSubmit = e => {
        setEdit(false);
        props.refetch({
            perimeter: props.values.perimeter?.value ?? null
        });
    }

    const {data} = useQuery(PERIMETER_BY_ID, {variables: {id: props.values.perimeter?.value}})

    if (edit) {
        return (
            <WidgetCard faIcon={faSearch}>
                <div className={"d-flex justify-content-end my-2"}>
                    <Button onClick={toggleEdit}
                            color={edit ? "danger" : "primary"}
                            size={"sm"}>
                        {edit ? "Annuler" : "Modifier"}
                    </Button>
                </div>
                <p>Sélectionnez un périmètre</p>
                <FormSearchFunding
                    onSubmit={onSubmit}
                    onChange={props.onValuesChange}
                    value={props.values.perimeter?.value}
                    localRoles={props.localRoles}
                />
            </WidgetCard>
        )
    }

    const dataCount = props.dataCount;
    const alertTitle = dataCount ? `${dataCount} financement${dataCount > 1 ? 's' : ''}` : 'Aucun financement';

    return <Alert color={"success"}>
        <div className={"d-flex justify-content-between mt-2"}>
            <h4><strong>{alertTitle}</strong></h4>
            <Button onClick={toggleEdit}
                    color={edit ? "danger" : "primary"}
                    size={"sm"}>
                {edit ? "Annuler" : "Modifier"}
            </Button>
        </div>

        <div className={"d-flex justify-content-between mt-2"}>
            <h5>{dataCount && dataCount > 0 ? '' : 'ne '}correspond{dataCount && dataCount > 1 ? 'ent' : ''} au critère suivant :</h5>
        </div>

        <ListGroup className={"mt-2 mb-2"}>
            <ListGroupItem tag={"span"}>
                <strong>Périmètre</strong> inclus {data && data.Perimeters.length && <span>'{data.Perimeters[0].label}'</span>}
            </ListGroupItem>
        </ListGroup>

    </Alert>;
}

export default SearchResultsFundingsPage;