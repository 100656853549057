import React, {useState} from "react";
import PerimetersTree from "./PerimetersTree";
import {useQuery} from "@apollo/client";
import {PERIMETERS_TREE_QUERY} from "../../graphql/queries";
import {FormGroup} from "react-bootstrap";
import {CustomInput} from "reactstrap";

function PerimetersTreeHandling(props){
    const [showAcquired, setShowAcquired] = useState(false);

    const allPerimeters = useQuery(
        PERIMETERS_TREE_QUERY,
        {variables: {typeID: props?.origin ?? 'ROT'}}
    );

    const perimetersTree = allPerimeters?.data?.Perimeters ?? [];

    if(props.multiselect) {
        props.setperimeterTree(perimetersTree)
    }

    const handleSwitch = () => {
        setShowAcquired(!showAcquired);
    }

    const acquiredPerimeters = props?.perimeterTree.acquiredPerimeters.map((ap) => ap.id);

    const getSelectablePerimeter = (onlyComplete, perimeters, acquired) => {
        let selectablePerimeters = [];
        let childrenSelected = [];
        if (perimeters === null) return [];
        perimeters.forEach((perimeter) => {
            childrenSelected = getSelectablePerimeter(
                onlyComplete, perimeter.children, acquired || acquiredPerimeters.indexOf(perimeter.id) > -1
            );
            selectablePerimeters = selectablePerimeters.concat(childrenSelected);
            if (acquired || acquiredPerimeters.indexOf(perimeter.id) > -1 || (!onlyComplete && childrenSelected.length > 0)) {
                selectablePerimeters.push(perimeter.id);
            }
        });

        return selectablePerimeters;
    }

    const getSubTree = (tree) => {
        const subTree = {...tree};

        if (acquiredPerimeters.includes(tree.id)) {
            return subTree;
        }

        if (!subTree.children || subTree.children.length === 0) {
            return false;
        }

        subTree.children = subTree.children.filter((node) => {
            return getSubTree(node);
        });

        return subTree.children.length > 0 ? subTree : false;
    }
    const selectablePerimeters = getSelectablePerimeter(true, perimetersTree, false);
    const tree = !showAcquired ? [getSubTree(perimetersTree[0] ?? {})].filter(p => p) : perimetersTree;

    return (
        <div className={'tree'}>
            <FormGroup className={"pull-right"}>
                <CustomInput type="switch"
                             id={`showNotRequiredFields_${props.id ?? 'id'}`}
                             name="showNotRequiredFields"
                             label="Afficher les périmètres non acquis"
                             className={"text-info"}
                             onChange={handleSwitch}
                             checked={showAcquired}
                />
            </FormGroup>

            <PerimetersTree
                onChange={props.onChange}
                selectedPerimeter={props.selectedPerimeter}
                perimetersTree={tree ? tree : []}
                showAcquired={showAcquired}
                selectablePerimeters={selectablePerimeters}
                multiselect={props.multiselect}
            />
        </div>
    );

}

export default PerimetersTreeHandling;