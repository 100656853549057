import React, {useState} from "react";
import {faPlus, faSpinner,} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import PropTypes from "prop-types";

const FAButton = (props) => {

    const [active, setActive] = useState('');
    const toggleFAB = () => {
        setActive(props.loading || active === 'active' ? '' : 'active');
    };

    const renderTooltip = (text) => (
        <Tooltip id={`button-tooltip ${text}`} color="text-primary">{text}</Tooltip>
    );

    const showInitial = 25;
    const hideInitial = 50;
    const rotateClass = props.withRotation ? 'rotate' : '';

    return (
        <div className={`btn-group-fab ${active}`} role="group" aria-label="FAB Menu">
            <div>
                <button className={`btn btn-fab btn-main ${props.btnClassMain} has-tooltip ${active} ${rotateClass}`}
                        type="button"
                        data-placement={props.dataPlacement} title="Menu"
                        onClick={toggleFAB}
                >
                    <FontAwesomeIcon spin={props.loading} icon={props.loading ? faSpinner : props.iconMain}/>
                </button>

                { props.buttons.map(({ id, title, fa, btnClass, titleClass, modaless }, index) => (
                    ! props.withButtonTitle ?
                        <OverlayTrigger
                            key={index}
                            placement={props.dataPlacement}
                            delay={{ show: (showInitial * index), hide: (hideInitial * index) }}
                            overlay={renderTooltip(title)}>
                            <button type="button" className={`btn btn-fab btn-sub ${btnClass} has-tooltip ${active}`}
                                    data-toggle="tooltip" data-placement={props.dataPlacement}
                                    title={title}
                                    onClick={() => props.onClick(id)}
                            >
                                { props.withButtonTitle ?
                                    <div className={`btn-sub-title ${btnClass} p-1 ${active} ${titleClass}`}>
                                        <em className="text-uppercase small">{title}</em>
                                    </div>
                                    : ''
                                }
                                <div className={`icon ${active} ${rotateClass}`}>
                                    <FontAwesomeIcon icon={fa}/>
                                </div>
                            </button>
                        </OverlayTrigger>
                        : <button key={index} type="button"
                                  className={`btn btn-fab btn-sub ${btnClass} has-tooltip ${active}`}
                                  data-toggle="tooltip" data-placement={props.dataPlacement}
                                  data-section={id}
                                  data-backdrop={false}
                                  data-target={"#mymodal"}
                                  title={title}
                                  onClick={(e) => {setActive(''); props.onClick(e.currentTarget.dataset.section);}}>
                            { props.withButtonTitle ?
                                <div className={`btn-sub-title ${btnClass} p-1 ${active} ${titleClass}`}>
                                    <em className="text-uppercase small">{title}</em>
                                </div>
                                : ''
                            }
                            <div className={`icon ${active} ${rotateClass}`}>
                                <FontAwesomeIcon icon={fa}/>
                            </div>
                        </button>
                ))}
            </div>
        </div>
    )
}

FAButton.propTypes = {
    iconMain: PropTypes.object,
    btnClassMain: PropTypes.string,
    buttons: PropTypes.array.isRequired,
    dataPlacement: PropTypes.oneOf([
        'left',
        'right',
        'top',
        'bottom'
    ]),
    withButtonTitle: PropTypes.bool,
    withRotation: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};

FAButton.defaultProps = {
    iconMain: faPlus,
    btnClassMain: 'btn-primary',
    buttons: [],
    dataPlacement: 'left',
    withButtonTitle: false,
    withRotation: false,
    onClick: null
};

export default FAButton;
