import React from "react";
import {
    Input,
    InputGroupAddon,
    InputGroup,
    InputGroupText,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faSpinner} from "@fortawesome/free-solid-svg-icons";
import { PropTypes } from 'prop-types';

function AutoSuggestInput({placeholder, onChange, value, loading, renderItem, items, onSelect, onBlur, onFocus, name, zIndexStart}) {
    return <>
        <InputGroup style={{zIndex: zIndexStart + 1}}>
            <InputGroupAddon addonType={'prepend'}>
                <InputGroupText>
                    {
                        loading
                            ? <FontAwesomeIcon icon={faSpinner} spin/>
                            : <FontAwesomeIcon icon={faSearch}/>
                    }
                </InputGroupText>
            </InputGroupAddon>
            <Input placeholder={placeholder ?? 'Rechercher'}
                   type={'search'}
                   onChange={onChange}
                   onFocus={onFocus}
                   onBlur={onBlur}
                   value={value}
                   name={name}
            />
        </InputGroup>
        <div style={{zIndex: zIndexStart}} className={'position-relative'}>
            {
                !loading &&
                (
                    items && <ListGroup className={'w-100'} style={{maxHeight: '300px', overflowY: 'scroll', cursor: 'pointer', position: 'absolute', zIndex: 1000}}>
                        {items.map(r => <ListGroupItem onClick={() => { onSelect(r, value)}}
                                                               action>
                            {renderItem(r, value)}
                        </ListGroupItem> )}
                    </ListGroup>
                )
            }
        </div>
    </>
}

AutoSuggestInput.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    renderItem: PropTypes.func.isRequired,
    items: PropTypes.array,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    name: PropTypes.string,
    zIndexStart: PropTypes.number.isRequired
}

AutoSuggestInput.defaultProps = {
    zIndexStart: 100
}

export default AutoSuggestInput;