import React, { useState, useCallback } from 'react';

export const NotificationContext = React.createContext({
    notifications: {},
    showToast: () => {},
    hideToast: () => {},
    pendingNotifications: {},
    addPendingNotification: () => {},
    showPendingToasts: () => {}
});

export default function NotificationProvider({ children }) {
    const [notifications, setNotifications] = useState({});
    const [pendingNotifications, setPendingNotifications] = useState({});

    const showToast = (notificationObject) => {
        setNotifications({...notifications, [notificationObject.date]: notificationObject});
    }
    const hideToast = (notificationKey) => {
        const copy = {...notifications}
        delete copy[notificationKey];
        setNotifications(copy);

        const pendingCopy = {...pendingNotifications}
        delete pendingCopy[notificationKey];
        setPendingNotifications(pendingCopy);
    }

    const addPendingNotification = (notificationObject) => {
        setPendingNotifications(
            {...pendingNotifications, [notificationObject.date]: notificationObject}
            );
    }
    const showPendingToasts = () => {
        setNotifications(pendingNotifications);
    }

    const contextValue = {
        notifications,
        showToast: useCallback(
            (notificationObject) => showToast(notificationObject),
            [notifications]),
        hideToast: useCallback(
            (notificationKey) => hideToast(notificationKey),
            [notifications]),

        pendingNotifications,
        addPendingNotification: useCallback(
            (notificationObject) => addPendingNotification(notificationObject),
            [pendingNotifications]),
        showPendingToasts: useCallback(
            () => showPendingToasts(),
            [notifications, pendingNotifications]),
    };

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    );
}
