import React from 'react';
import PropTypes from 'prop-types'
import {useQuery} from "@apollo/client";
import {USERS_ENF_UID_QUERY} from "../../graphql/queries";
import WidgetCard from "./WidgetCard";
import LoadingSpinner from "../Default/LoadingSpinner";

function UserEnfCard({uid}) {
    const {data, loading, error} = useQuery(USERS_ENF_UID_QUERY, {variables: { enfUid: uid }});

    if (loading) {
        return (
            <WidgetCard color={'light'}>
                <div className="row justify-content-center">
                    <LoadingSpinner />
                </div>
            </WidgetCard>
        )
    }

    if (error) {
        return (
            <WidgetCard color={'light'}>
                <span> Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></span>
            </WidgetCard>
        )
    }

    if (data.UsersEnf.length === 0) {
        return (
            <WidgetCard color={'light'}>
                <span className="text-muted">Utilisateur ENF non trouvé</span>
            </WidgetCard>
        )
    }

    const userEnf = data.UsersEnf[0];

    return (
        <WidgetCard color={'light'}>
            <ul className="text-left btn-block" style={{listStyleType: 'none', margin: 0, padding: 0}}>
                <li className="row">
                    <em className="col-12 col-md-4 text-secondary">UID ENF</em>
                    <span className="ml-1 ml-md-0 col-11 col-md-8">{userEnf?.uid}</span>
                </li>
                <li className="row">
                    <em className="col-12 col-md-4 text-secondary">NOM D'USAGE</em>
                    <span className="ml-1 ml-md-0 col-11 col-md-8">{userEnf?.name}</span>
                </li>
                <li className="row">
                    <em className="col-12 col-md-4 text-secondary">NOM DE NAISSANCE</em>
                    <span className="ml-1 ml-md-0 col-11 col-md-8">{userEnf?.nameBirth}</span>
                </li>
                <li className="row">
                    <em className="col-12 col-md-4 text-secondary">PRENOM</em>
                    <span className="ml-1 ml-md-0 col-11 col-md-8">{userEnf?.firstName}</span>
                </li>
                <li className="row">
                    <em className="col-12 col-md-4 text-secondary">DATE DE NAISSANCE</em>
                    <span className="ml-1 ml-md-0 col-11 col-md-8">{formatDateBirth(userEnf?.birthDate)}</span>
                </li>
                <li className="row">
                    <em className="col-12 col-md-4 text-secondary">EMAIL</em>
                    <span className="ml-1 ml-md-0 col-11 col-md-8">
                        <a href={`mailto:${userEnf?.email}`}>{userEnf?.email}</a>
                    </span>
                </li>
            </ul>
        </WidgetCard>
    )
}

const formatDateBirth = (date) => {
    let options = { year: 'numeric', month: 'numeric', day: 'numeric'};
    return new Date(date).toLocaleDateString('fr-FR', options);
}

UserEnfCard.propTypes = {
    uid: PropTypes.string.isRequired
}

export default UserEnfCard;
