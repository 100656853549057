import React from 'react';
import PropTypes from 'prop-types'
import { Card, CardTitle, CardBody, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPen, faUserPlus} from "@fortawesome/free-solid-svg-icons";

const FormCard = (props) => {
    let textColor = props.mode === "create" ? "text-success" : "text-info";
    return (
        <div className={"pt-lg-3"}>
            <Card color={"light"}>
                { props.withTitle ?
                    <CardHeader className={`pl-3 ${textColor}`}>
                        <div className={"d-flex justify-content-start align-items-center"}>
                            <div className={"p-3"}>
                                {
                                    props.mode === "create"
                                        ?
                                        <>
                                            <CardTitle tag={"h3"}>
                                                <FontAwesomeIcon icon={faUserPlus}/>&nbsp;Créer un prospect
                                            </CardTitle>
                                        </>
                                        :
                                        <>

                                            <CardTitle tag={"h3"}>
                                                <FontAwesomeIcon icon={faPen}/>&nbsp;Modifier {props.subtitle}
                                            </CardTitle>
                                        </>
                                }
                            </div>
                        </div>
                    </CardHeader>
                    : ''
                }

                <CardBody>
                    <CardBody className={"p-0 mt-1"}>
                        {props.children}
                    </CardBody>
                </CardBody>

            </Card>
        </div>
    );
};

FormCard.propTypes = {
    mode: PropTypes.oneOf([
        'create',
        'update'
    ]),
    subtitle: PropTypes.string,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
        'light',
        'dark',
        'white',
    ]),
    withTitle: PropTypes.bool
};

FormCard.defaultProps = {
    mode: 'create',
    subtitle: '',
    color: 'white',
    withTitle: true
};

export default FormCard;
