import React, {useContext, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Collapse, Nav, Navbar, NavItem, NavLink as BSNavLink} from 'reactstrap';
import sideMenuBgImage from '../../assets/img/compas.jpg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserContext from "../../contexts/UserContext";
import {faBars, faChevronLeft, faLifeRing} from "@fortawesome/free-solid-svg-icons";
import {useQuery} from "@apollo/client";
import {USER_PROFILE_QUERY} from "../../graphql/queries";

const FA_ARROW_DOWN = ['fas', 'chevron-down'];

const sideMenuBackground = {
    backgroundImage: `url("${sideMenuBgImage}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};

const navItems = [
    {
        to: '/',
        name: 'dashboard',
        label: 'Tableau de bord',
        active: 'active',
        exact: false,
        fa: ['fas', 'compass'],
        subs: null,
        secured: true,
        roles: []
    },
    {
        to: '/help',
        name: 'help',
        label: 'Aide',
        active: '',
        exact: false,
        fa: faLifeRing,
        subs: null,
        secured: true,
        roles: []
    },
    {
        to: '/login',
        name: 'connexion',
        label: 'Connexion',
        active: 'active',
        exact: false,
        fa: ['fas', 'info-circle'],
        subs: null,
        secured: false,
        roles: []
    },
    {
        to: '/admin',
        name: 'admin',
        label: 'Administration',
        active: '',
        exact: false,
        fa: ['fas', 'user-shield'],
        subs: null,
        secured: true,
        roles: ['ROLE_ADMIN']
    }
];

function SideMenu() {
    const {currentUser} = useContext(UserContext);
    const isConnected = currentUser !== null;

    const {data} = useQuery(USER_PROFILE_QUERY, {
        variables: {userId: currentUser?.id ? currentUser.id : 0},
    });
    const user = data?.Users[0];

    const [isCollapsed, setCollapsed] = useState(false);
    const toggleCollapse = () => {
        setCollapsed(!isCollapsed);

        // Handling the width of the footer notes
        let docStyle = getComputedStyle(document.documentElement);
        const expanded = docStyle.getPropertyValue("--sidemenu-width-expanded");
        const collapsed = docStyle.getPropertyValue("--sidemenu-width-collapsed");
        const width = !isCollapsed ? collapsed : expanded;
        document.documentElement.style.setProperty("--sidemenu-width", width);
    };

    const [menuItems, setMenuItems] = useState(navItems);
    const handleActive = (name) => e => {
        let allFalse = menuItems.map(function (item) {
            item.active = '';
            return item;
        });
        setMenuItems(allFalse);

        const elementsIndex = menuItems.findIndex(element => element.name === name);
        let newArray = [...menuItems];
        newArray[elementsIndex] = {...newArray[elementsIndex], active: 'active'}
        setMenuItems(newArray);
    };


    const userRoles = user?.localRoles ?? [];
    const checkRoles = (roles) => {
        if (roles.length === 0){
            return true;
        }

        let canAccess = false;
        userRoles.forEach(singleRole => {
            if (roles.includes(singleRole.role.id)){
                canAccess = true;
            }
        });

        return canAccess;
    }

    return (
        <div className={'sticky-top vh-100'}>
            <aside className={`cr-sidemenu cr-sidemenu--open d-none ${isCollapsed ? '' : 'd-lg-block'}`} data-image={sideMenuBgImage}>
                <div className={"cr-sidemenu__background"} style={sideMenuBackground}/>
                <div className={"cr-sidemenu__content"}>
                    <Navbar className={"justify-content-center align-content-center"}>
                        <div className="navbar-brand">
                            <strong className={"d-none d-md-inline text-white"}>
                                C <FontAwesomeIcon icon={['far', 'compass']} size="sm"/> M P A S
                            </strong>
                            <strong className={"d-md-none text-white"}>
                                <FontAwesomeIcon icon={['far', 'compass']} size="lg"/>
                            </strong>
                        </div>
                    </Navbar>
                    <Nav vertical>
                        {menuItems
                            .filter(({secured}) => secured === isConnected)
                            .filter(
                                ({ roles }) => checkRoles(roles)
                            )
                            .map(({to, name, label, active, exact, fa, subs}, index) => (
                                <NavItem
                                    key={index}
                                    className={'cr-sidemenu__nav-item'}
                                    onClick={handleActive(name)}>

                                    {subs === null
                                        ?
                                        <BSNavLink
                                            id={`navItem-${name}-${index}`}
                                            className={`text-uppercase ${active}`}
                                            tag={NavLink}
                                            to={to}
                                            activeClassName={active}
                                            exact={exact}>
                                            <FontAwesomeIcon icon={fa} fixedWidth className={"mr-2"}/>
                                            <span className="d-none d-md-inline">{label}</span>
                                        </BSNavLink>
                                        :
                                        <div>
                                            <BSNavLink
                                                id={`navItem-${name}-${index}`}
                                                className={`text-uppercase ${active}`}
                                                tag={NavLink}
                                                to={to}
                                                activeClassName={active}
                                                exact={exact}>
                                                <FontAwesomeIcon icon={fa} fixedWidth className={"mr-2"}/>
                                                <span className="d-none d-md-inline">{label}</span>
                                                <FontAwesomeIcon
                                                    icon={FA_ARROW_DOWN}
                                                    className={"ml-2"}
                                                    style={{
                                                        padding: 0,
                                                        transform: isCollapsed ? 'rotate(0deg)' : 'rotate(-90deg)',
                                                        transitionDuration: '0.3s',
                                                        transitionProperty: 'transform',
                                                    }}
                                                />
                                            </BSNavLink>
                                            <Collapse isOpen={isCollapsed}>
                                                {subs.map(({to, name, label, exact, fa}, index) => (
                                                    <BSNavLink
                                                        key={index}
                                                        id={`navItemSubs-${name}-${index}`}
                                                        className="text-uppercase ml-2 mr-2"
                                                        tag={NavLink}
                                                        to={to}
                                                        activeClassName="active"
                                                        exact={exact}>
                                                        <FontAwesomeIcon icon={fa} fixedWidth className={"mr-2"}/>
                                                        <span className="d-none d-md-inline">{label}</span>
                                                    </BSNavLink>
                                                ))}
                                            </Collapse>
                                        </div>
                                    }
                                </NavItem>
                            ))}
                    </Nav>
                </div>
            </aside>
            { isCollapsed && <div className={`cr-collpasedMenuContainer`}>
                <Navbar className={"position-absolute"}>
                    <div className="navbar-brand">
                        <strong className={"text-primary"}>
                            C <FontAwesomeIcon icon={['far', 'compass']} size="sm"/> M P A S
                        </strong>
                    </div>
                </Navbar>
                <aside className={`cr-collpasedMenu ${isCollapsed ? 'd-lg-block' : 'd-none'}`}>
                <div>
                    <Nav vertical>
                        {menuItems
                            .filter(({secured}) => secured === isConnected)
                            .filter(
                                ({ roles }) => checkRoles(roles)
                            )
                            .map(({to, name, label, active, exact, fa, subs}, index) => (
                                <NavItem
                                    key={index}
                                    className={'cr-sidemenu__nav-item'}
                                    onClick={handleActive(name)}>

                                    {subs === null
                                        ?
                                        <BSNavLink
                                            id={`navItem-${name}-${index}`}
                                            className={`text-uppercase ${active}`}
                                            tag={NavLink}
                                            to={to}
                                            activeClassName={active}
                                            exact={exact}>
                                            <FontAwesomeIcon icon={fa} size="lg"/>
                                            <div className="cr-collpasedMenuLabel">
                                                <p>{label}</p>
                                            </div>
                                        </BSNavLink>
                                        :
                                        <div>
                                            <BSNavLink
                                                id={`navItem-${name}-${index}`}
                                                className={`text-uppercase ${active}`}
                                                tag={NavLink}
                                                to={to}
                                                activeClassName={active}
                                                exact={exact}>
                                                <FontAwesomeIcon icon={fa}/>
                                                <div className="cr-collpasedMenuLabel">
                                                    <p>{label}</p>
                                                </div>
                                                <FontAwesomeIcon
                                                    icon={FA_ARROW_DOWN}
                                                    className={"ml-2"}
                                                    style={{
                                                        padding: 0,
                                                        transform: isCollapsed ? 'rotate(0deg)' : 'rotate(-90deg)',
                                                        transitionDuration: '0.3s',
                                                        transitionProperty: 'transform',
                                                    }}
                                                />
                                            </BSNavLink>
                                        </div>
                                    }
                                </NavItem>
                            ))}
                    </Nav>
                </div>
                </aside>
            </div>}
            <div className={isCollapsed ? 'is-collapse' : ''} title={'Déplier/Replier le menu'}
                 onClick={toggleCollapse}
                 id={'cr-menu-toggle-button'}>
                <FontAwesomeIcon icon={isCollapsed ? faBars : faChevronLeft}/>
            </div>
        </div>
    );
}

export default SideMenu;
