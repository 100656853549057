import React from 'react';
import {ListGroupItem} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolder, faFolderOpen, faMapMarkerAlt, faBirthdayCake} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const ProspectListItem = ({user, callBack}) => {

    let projectsOpen = [];
    let projectsClose = [];
    if (user.projects) {
        projectsOpen = user.projects.filter(p => p.isOpen);
        projectsClose = user.projects.filter(p => !p.isOpen);
    }

    const formatDateYmdTodmY = dateYmd => {
        const dateYmdParts = dateYmd.split('-');
        return dateYmdParts.length === 3 ? dateYmdParts[2] + '-' + dateYmdParts[1] + '-' + dateYmdParts[0] : dateYmd;
    }

    const key = user.id !== undefined ? user.id : user.enfUid;

    return (
        <ListGroupItem key={key} tag={"div"} className={"mt-3"}
                       action={true} onClick={() => callBack(user)}>
            <div className={"d-flex justify-content-between"}>
                <h5>
                    {user.firstName} {user.useName}
                    {
                        user.nameBirth && user.nameBirth !== user.useName && <span>
                            <span className="font-weight-light"> né{user.gender === 'f' ? 'e' : ''}</span> {user.nameBirth}
                        </span>
                    }

                </h5>
                <div className={'d-flex flex-column justify-content-start align-items-end'}>
                    {user.birthDate && <span className={'text-muted'}><FontAwesomeIcon
                        icon={faBirthdayCake}/> {formatDateYmdTodmY(user.birthDate)}</span>}
                </div>

            </div>
            <div className={"d-flex justify-content-start"}>
                <span className={"muted mr-3"}>
                    <span><FontAwesomeIcon icon={faFolderOpen}/> </span>
                    {projectsOpen.length} projet{projectsOpen.length > 1 ? 's' : ''} ouvert{projectsOpen.length > 1 ? 's' : ''}
                </span>
                <span className={"muted mr-3"}>
                    <span><FontAwesomeIcon icon={faFolder}/> </span>
                    {projectsClose.length} projet{projectsClose.length > 1 ? 's' : ''} fermé{projectsClose.length > 1 ? 's' : ''}
                </span>
                {user.perimeter ?
                    <span className={"muted mr-3"}>
                        <span><FontAwesomeIcon icon={faMapMarkerAlt}/> </span>
                        {user.perimeter ? user.perimeter.parent.label : ''}
                    </span> : ''}
            </div>
        </ListGroupItem>
    )
}

ProspectListItem.propTypes = {
    user: PropTypes.object.isRequired,
    callBack: PropTypes.func.isRequired
};

export default ProspectListItem;
