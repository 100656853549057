import React, {useContext, useState} from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserContext from "../../contexts/UserContext";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink as BSNavLink } from 'reactstrap';
import UserPopoverCard from "../Card/UserPopoverCard";
import {useQuery} from "@apollo/client";
import {USER_PROFILE_QUERY} from "../../graphql/queries";
import {faLifeRing} from "@fortawesome/free-solid-svg-icons";

const FA_ARROW_DOWN = ['fas', 'chevron-down'];

const navItems = [
    { to: '/', name: 'dashboard', label: 'Tableau de bord', active: 'active', exact: false, fa: ['fas', 'compass'], subs: null, secured: true, roles: [] },
    { to: '/admin', name: 'admin', label: 'Administration', active: '', exact: false, fa: ['fas', 'user-shield'], subs: null, secured: true, roles: ['ROLE_ADMIN'] },
    { to: '/help', name: 'help', label: 'Aide', active: '', exact: false, fa: faLifeRing, subs: null, secured: true, roles: [] },
    { to: '/login', name: 'connexion', label: 'Connexion', active: 'active', exact: false, fa: ['fas', 'info-circle'], subs: null, secured: false, roles: [] }
];

function ResponsiveMenu() {
    const { currentUser } = useContext(UserContext);
    const isConnected = currentUser !== null;

    const {data} = useQuery(USER_PROFILE_QUERY, {
        variables: {userId: currentUser?.id ? currentUser.id : 0},
    });
    const user = data?.Users[0];

    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);

    const [menuItems, setMenuItems] = useState(navItems);
    const handleActive = (name) => e => {
        let allFalse = menuItems.map(function (item) {
            item.active = '';
            return item;
        });
        setMenuItems(allFalse);

        const elementsIndex = menuItems.findIndex(element => element.name === name);
        let newArray = [...menuItems];
        newArray[elementsIndex] = {...newArray[elementsIndex], active: 'active'}
        setMenuItems(newArray);

        toggleNavbar();
    };


    const userRoles = user?.localRoles ?? [];
    const checkRoles = (roles) => {
        if (roles.length === 0){
            return true;
        }

        let canAccess = false;
        userRoles.forEach(singleRole => {
            if (roles.includes(singleRole.role.id)){
                canAccess = true;
            }
        });

        return canAccess;
    }

    return (
        <div className={"cr-menuresponsive d-lg-none"}>
            <div className={"cr-menuresponsive__background"} />
            <Navbar className={"cr-menuresponsive__content"} color="faded" dark>
                <NavbarBrand href="/" className="mr-auto">
                    <a className={"navbar-brand"} href={"/"}>
                        <p className={"mb-0 text-white"}>
                            C <FontAwesomeIcon icon={['far', 'compass']} size="sm" /> M P A S
                        </p>
                    </a>
                </NavbarBrand>
                <NavItem>
                    <UserPopoverCard className="align-middle"/>
                </NavItem>
                <NavbarToggler onClick={toggleNavbar}/>
                <Collapse isOpen={!collapsed} navbar>
                    <Nav navbar>
                        { menuItems
                            .filter(({ secured }) => secured === isConnected)
                            .filter(
                                ({ roles }) => checkRoles(roles)
                            )
                            .map(({ to, name, label, active, exact, fa, subs }, index) => (
                                <NavItem
                                    key={index}
                                    className={`cr-menuresponsive__nav-item ${active}`}
                                    onClick={ handleActive(name) } >

                                    { subs === null
                                        ?
                                        <BSNavLink
                                            id={`navItem-${name}-${index}`}
                                            className={`text-uppercase ${active}`}
                                            tag={NavLink}
                                            to={to}
                                            activeClassName={active}
                                            exact={exact}>
                                            <FontAwesomeIcon icon={fa} fixedWidth className={"mr-2"}/>
                                            <span>{label}</span>
                                        </BSNavLink>
                                        :
                                        <div>
                                            <BSNavLink
                                                id={`navItem-${name}-${index}`}
                                                className={`text-uppercase ${active}`}
                                                tag={NavLink}
                                                to={to}
                                                activeClassName={active}
                                                exact={exact}>
                                                <FontAwesomeIcon icon={fa} fixedWidth className={"mr-2"}/>
                                                <span>{label}</span>
                                                <FontAwesomeIcon
                                                    icon={FA_ARROW_DOWN}
                                                    className={"ml-2"}
                                                    style={{
                                                        padding: 0,
                                                        transform: collapsed ? 'rotate(0deg)' : 'rotate(-90deg)',
                                                        transitionDuration: '0.3s',
                                                        transitionProperty: 'transform',
                                                    }}
                                                />
                                            </BSNavLink>
                                            <Collapse isOpen={collapsed}>
                                                { subs.map(({ to, name, label, exact, fa }, index) => (
                                                    <BSNavLink
                                                        key={index}
                                                        id={`navItemSubs-${name}-${index}`}
                                                        className="text-uppercase ml-2 mr-2"
                                                        tag={NavLink}
                                                        to={to}
                                                        activeClassName="active"
                                                        exact={exact} >
                                                        <FontAwesomeIcon icon={fa} fixedWidth className={"mr-2"}/>
                                                        <span>{label}</span>
                                                    </BSNavLink>
                                                ))}
                                            </Collapse>
                                        </div>
                                    }
                                </NavItem>
                            ))}
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
}

export default ResponsiveMenu;
