import React from "react";

function Choix({schema, children}) {
    return <div className={'schema-choix'}>
        <div className={'text-muted d-flex justify-content-between align-items-start'}>
            <span>{schema.libelle}</span>
            <span className={'badge badge-info rounded'}>{schema.credits} ECTS</span>
        </div>
        {children}
    </div>
}

export default Choix;