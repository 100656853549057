import React, {useContext, useEffect, useState} from 'react';
import {
    ListGroup,
    CustomInput,
    PaginationItem,
    PaginationLink
} from 'reactstrap';

import { useQuery } from '@apollo/client';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserContext from "../../contexts/UserContext";
import {faHistory, faSpinner} from "@fortawesome/free-solid-svg-icons";
import ProjectListGroupItem from "../List/ProjectListGroupItem";
import WidgetCard from "./WidgetCard";
import {Pagination} from "react-bootstrap";

const REFRESH_INTERVAL = 30000;

const ProjectsCard = ({titleName, query}) => {
    useEffect(() => {
        refetch();
    }, []);

    const { currentUser } = useContext(UserContext);
    const resultsPerPage = [3, 10, 20];
    const [numberPerPage, setNumberPerPage ] = useState(resultsPerPage[0]);
    const handleResultsPerPage = evt => {
        setNumberPerPage(parseInt(evt.target.dataset.value));
    };

    const [showAllProjects, setShowAllProjects] = React.useState(false);
    const handleSwitch = () => {
        setShowAllProjects(!showAllProjects);
    }

    const { loading, error, data, refetch, startPolling, stopPolling } = useQuery(query, {
        variables: {
            userID: currentUser?.id ? currentUser.id : 0,
        }
    });

    useEffect(() => {
        refetch();
    }, []);
    useEffect(() => {
        startPolling(REFRESH_INTERVAL)
        return () => {
            stopPolling()
        }
    }, [startPolling, stopPolling])

    if (loading) {
        return (
            <WidgetCard title={titleName} faIcon={faHistory}>
                <span className="text-muted"><FontAwesomeIcon icon={faSpinner} spin /> Chargement...</span>
            </WidgetCard>
        )
    }

    if (error) {
        return (
            <WidgetCard title={titleName} faIcon={faHistory}>
                <span> Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></span>
            </WidgetCard>
        )
    }

    if (data.Projects.length === 0) {
        return (
            <WidgetCard title={titleName} faIcon={faHistory}>
                <h6 className="text-muted">Aucun</h6>
            </WidgetCard>
        );
    }

    const allResults = data.Projects
        .filter(project => project.isOpen || showAllProjects)
        .slice(0, resultsPerPage[resultsPerPage.length -1])
    ;
    const results = allResults
        .slice(0, numberPerPage)
    ;
    const countNotOpenProjects = data.Projects.filter(project => !project.isOpen).length;
    const countAllResults = allResults.length;
    const countResults = results.length;

    return (
        <WidgetCard title={titleName} faIcon={faHistory}>
            <div className="d-flex m-2 mt-0 justify-content-between align-items-center">
                {
                    countNotOpenProjects > 0
                        ? <CustomInput type="switch"
                                       id="showAllProjects"
                                       name="showAllProjects"
                                       label={`Afficher les projets clos (${countNotOpenProjects})`}
                                       onChange={handleSwitch}
                                       className={"text-muted"}
                        />
                        : <span>  </span>
                }
                <ResultsPerPageSelector handleChange={handleResultsPerPage}
                                        valueCurrent={numberPerPage}
                                        values={resultsPerPage.filter(
                                            (n, index, ar) => countAllResults > n || (index !== 0 && countAllResults > ar[index-1])
                                        )}
                                        objectsName={"projets"}
                />
            </div>

            <ListGroup className="col-12">
                {
                    results?.map(
                        (project, index) => <ProjectListGroupItem props={project}
                                                                  key={index}
                        />)
                }
            </ListGroup>

            <div className={"d-flex mr-3 justify-content-end"}>
                {
                    results.length > 1 ?
                        <span className="text-muted mt-3">
                            1-{countResults} sur {countAllResults} projets
                        </span>
                        : ''
                }
            </div>
        </WidgetCard>
    );
};

function ResultsPerPageSelector({valueCurrent, values, objectsName, handleChange}) {

    return (
        values.length > 0
            ?
            <Pagination size={"sm"}>
                <PaginationItem key={99} title={'nombre de ' + objectsName + ' à afficher'}>
                    <PaginationLink disabled>
                        <FontAwesomeIcon icon={"list"}/>
                    </PaginationLink>
                </PaginationItem>
                {
                    values.map((value, index) => (
                        <PaginationItem active={valueCurrent === value} key={index}>
                            <PaginationLink data-value={value} onClick={handleChange}>{value}</PaginationLink>
                        </PaginationItem>
                    ))
                }
            </Pagination>
            : <span> </span>
    )
}

export default ProjectsCard;
