import React from "react";
import {GET_CURSUS_BY_CODE} from "../../graphql/queries";
import {useLazyQuery} from "@apollo/client";
import FormSearchWithLazyQuery from "./FormSearchWithLazyQuery";

function FormSearchCursus({className, onSubmit}) {
    const lazyQuery = useLazyQuery(GET_CURSUS_BY_CODE, {variables: {code: null}});

    return <FormSearchWithLazyQuery lazyQuery={lazyQuery}
                                    searchVariableName={'code'}
                                    minLength={2}
                                    renderItem={c => `${c.code} ${c.intituleLong}`}
                                    onSelect={onSubmit}
                                    className={className}
                                    placeholder={"Rechercher par code du cursus (i.e : CYC9100A)"}

    />
}

export default FormSearchCursus;