import React, {useContext} from 'react';
import { BrowserRouter, Route, Link, Switch, Redirect} from 'react-router-dom';
import './App.css';
import SideMenu from "./components/Layout/SideMenu";
import ResponsiveMenu from "./components/Layout/ResponsiveMenu";
import DashboardPage from "./pages/DashboardPage";
import ProjectsPage from "./pages/ProjectsPage";
import ProspectsPage from "./pages/ProspectsPage";
import FundingOverView from "./pages/FundingOverView";
import AdminPage from "./pages/AdminPage";
import HelpPage from "./pages/HelpPage";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import SearchResultsPage from "./pages/SearchResultsPage";
import SearchResultsFundingsPage from "./pages/SearchResultsFundingsPage";
import LoginPage from "./pages/LoginPage";
import UserContext from "./contexts/UserContext";
import ProjectOverView from "./pages/ProjectOverView";
import ProspectOverView from "./pages/ProspectOverView";
import ErrorPage from "./pages/ErrorPage";
import NotificationProvider from "./contexts/NotificationContext";
import ToastNotification from "./components/ToastNotification";

export const pages = [
    { to: '/projects', name: 'projects', fa: ['fas', 'project-diagram'], component: ProjectsPage, secured: true },
    { to: '/projects/create', name: 'projects_create', fa: ['fas', 'plus-circle'], component: ProjectsPage, secured: true },
    { to: '/projects/search', name: 'projects_search', fa: ['fas', 'search'], component: ProjectsPage, secured: true },
    { to: '/projects/recent', name: 'projects_recent', fa: ['fas', 'history'], component: ProjectsPage, secured: true },
    { to: '/project/:id/:accessMode?/:sectionTargeted?', name: 'project_view', fa: ['fas', 'project-diagram'], component: ProjectOverView, secured: true },
    { to: '/project/:id/:accessMode?/:sectionTargeted?/with/:enfUid', name: 'project_view_with_enfUid', fa: ['fas', 'project-diagram'], component: ProjectOverView, secured: true },
    { to: '/admin', name: 'admin', fa: ['fas', 'user-shield'], component: AdminPage, secured: true },
    { to: '/help', name: 'aide', fa: ['fas', 'info-circle'], component: HelpPage, secured: true },
    { to: '/login', name: 'connexion', fa: ['fas', 'info-circle'], component: LoginPage, secured: false },
    { to: '/search/results', name: 'search', fa: faSearch, component: SearchResultsPage, secured: true },
    { to: '/search/results/fundings', name: 'search', fa: faSearch, component: SearchResultsFundingsPage, secured: true },
    { to: '/prospects', name: 'prospects', fa: faSearch, component: ProspectsPage, secured: true },
    { to: '/funding/:accessMode/:id?', name: 'fundings_view', fa: faSearch, component: FundingOverView, secured: true },
    { to: '/prospect/:id', name: 'prospect_view', fa: ['fas', 'user'], component: ProspectOverView, secured: true },
    { to: '/', name: 'dashboard', fa: ['fas', 'compass'], component: DashboardPage, secured: true } // Keep last for routes' switch
];

export const user = {
    username: "Billy Bobby",
    userFirstName: "Billy",
    userLastName: "BOBBY",
    userEmail: "billy_bobby@gmail.com",
    userColor: "#80CBC4",
    userRole: "CHARGER_AIOIP"
};

function App(props) {
    const { currentUser } = useContext(UserContext);
    const isConnected = currentUser !== null;

    return (
        <BrowserRouter>
            <main className={"cr-app bg-light"}>
                <NotificationProvider>
                    <ToastNotification />
                    <ResponsiveMenu />
                    <SideMenu />
                    <div className={"cr-content container-fluid pl-0 pl-md-3 pr-0 pr-md-3"}>
                        {
                            !props?.errorServer
                                ? <div className={"cr-page p-1"}>
                                    <Switch>
                                        { pages.filter(({ secured }) => ( secured === isConnected )).map(({ to, name, fa, component}, index) => (
                                            <Route key={index} id={`route-${name}-${index}`} path={to} component={component} exact/>
                                        ))}
                                        {
                                            isConnected ? <Redirect to="/" /> : <Redirect to="/login" />
                                        }
                                    </Switch>
                                </div>
                                : <ErrorPage resetErrors={props?.onSetErrorServer}/>
                        }

                    </div>

                </NotificationProvider>
            </main>
        </BrowserRouter>
    );
}

export default App;
