import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types'
import {
    Button,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserContext from "../../contexts/UserContext";
import {useMutation, useQuery} from "@apollo/client";
import {USER_PROFILE_QUERY} from "../../graphql/queries";
import {CREATE_PROJECT_FROM_SCRATCH, CREATE_PROSPECT_FULL_PAYLOAD} from "../../graphql/mutations";
import {faCaretDown, faPlus, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Redirect} from "react-router-dom";

const CreateProjectButton = (props) => {

    const enfUid = props.enfUid;
    const prospect = props.prospect;
    const block = props.block;
    const titleBtn = props.title;
    const colorBtn = props.color;
    const iconBtn = props.faIcon;
    const animate = props.withAnimation ? 'grow-in' : '';
    const roleID = "ROLE_CHARGE_AIOA_IP";

    const [blankProjectId, setBlankProjectId] = useState(null);
    const [isDropOpen, setIsDropOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => setIsModalOpen(!isModalOpen);
    const [chosenPerimeter, setChosenPerimeter] = useState('');

    const {currentUser} = useContext(UserContext);
    const {loading: loadingUser, error: errorUser, data: dataUser} = useQuery(USER_PROFILE_QUERY, {
        variables: {userId: currentUser?.id ? currentUser.id : 0},
    });

    const toggleDrop = () => setIsDropOpen(!isDropOpen);

    const [createProject, {loading: loadingProject}] = useMutation(CREATE_PROJECT_FROM_SCRATCH, {
        onCompleted(data) {
            setBlankProjectId(data.CreateProject.projet.id);
        }
    });

    const loading = loadingProject || loadingUser;

    if (loading) {
        return (
            <Button color={colorBtn} block={block} className="mb-3">
                <h4 className="text-uppercase mb-0">
                    <small>
                        <em><FontAwesomeIcon icon={faSpinner} spin/>
                            {
                                (loadingProject && <span> Création du projet en cours ...</span>) ||
                                (<span> Traitements en cours ...</span>)
                            }
                        </em>
                    </small>
                </h4>
            </Button>
        )
    }

    if (blankProjectId !== null) {
        const path = enfUid
            ? '/project/' + blankProjectId + '/edit/with/' + enfUid
            : '/project/' + blankProjectId + '/edit';
        return (
            <Redirect push to={{ pathname: path }}/>
        )
    }

    if (currentUser !== null && errorUser) {
        return (
            <h6>Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></h6>
        )
    }

    // User not connected
    if (!dataUser) {
        return '';
    }

    const localRoles = dataUser.Users[0].localRoles;

    // Necessary check
    if (!userHasRole(localRoles, roleID)) {
        return '';
    }

    const userPerimeters = getUserPerimetersByRole(localRoles, roleID);

    const projectVariables = prospect !== undefined && prospect.id
        ? { isOpen: true, perimeter: chosenPerimeter.id, prospect: prospect.id }
        : { isOpen: true, perimeter: chosenPerimeter.id };

    return (
        <div>
            {
                userPerimeters.length > 1
                    ? <ButtonDropdown isOpen={isDropOpen}
                                      toggle={toggleDrop}
                                      setActiveFromChild={true}
                                      className={`${block ? 'btn-block ' : ''}mb-3 ${animate}`}>

                        <DropdownToggle color={colorBtn}>
                            <h4 className="text-uppercase mb-0">
                                <FontAwesomeIcon icon={iconBtn} className="mr-1"/> {titleBtn}
                                <FontAwesomeIcon icon={faCaretDown} size={"xs"} className="ml-3"/>
                            </h4>
                        </DropdownToggle>

                        <DropdownMenu style={{maxHeight: '500px'}} className={'overflow-auto'}>
                            <DropdownItem header>Pour le centre :</DropdownItem>
                            {
                                userPerimeters.map((perimeter) =>
                                    <DropdownItem onClick={() => {
                                        setChosenPerimeter(perimeter);
                                        toggleModal();
                                    }}
                                                  color={colorBtn}
                                    >
                                        {perimeter.label}
                                    </DropdownItem>
                                )
                            }
                        </DropdownMenu>
                    </ButtonDropdown>
                    : <Button onClick={() => {
                        setChosenPerimeter(userPerimeters[0]);
                        toggleModal();
                    }}
                              color={colorBtn}
                              className={`btn-block mb-3 ${animate}`}>
                        <h4 className="text-uppercase mb-0"><FontAwesomeIcon icon={iconBtn}/> {titleBtn}</h4>
                    </Button>
            }
            <Modal isOpen={isModalOpen}>
                <ModalHeader>Confirmation</ModalHeader>
                <ModalBody>
                    <span>
                        Etes-vous certain de vouloir créer un nouveau projet pour le périmètre <strong>{chosenPerimeter.label}</strong> ?
                    </span>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>Annuler</Button>
                    <Button color="success"
                            onClick={() => createProject({ variables: { project: projectVariables }}) }>
                        OUI
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

const userHasRole = (localRoles, roleSearched) => {
    let hasRole = false;
    localRoles.map(({role}) => (
        role.id === roleSearched ?
            hasRole = true
            : ''
    ));
    return hasRole;
}

const getUserPerimetersByRole = (localRoles, roleSearched) => {
    let perimeterObjects = [];
    localRoles.map(({role, perimetres}, index, arr) => (
        role.id === roleSearched ?
            perimetres.map((perimeter) => perimeterObjects.push(perimeter))
            : ''
    ));
    return perimeterObjects;
}

CreateProjectButton.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
        'light',
        'dark',
        'white',
    ]),
    faIcon: PropTypes.object,
    withAnimation: PropTypes.bool,
    block: PropTypes.bool.isRequired
};

CreateProjectButton.defaultProps = {
    title: 'créer un project',
    color: 'primary',
    faIcon: faPlus,
    withAnimation: true,
    block: true
};

export default CreateProjectButton;
