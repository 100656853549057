import React, {useState} from "react";

import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faScroll,
} from "@fortawesome/free-solid-svg-icons";
import {Button, Card, CardBody, Collapse, Form} from "reactstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ToggleSectionTitle from "./ToggleSectionTitle";

function ProjectSectionConclusion({conclusion, setConclusion, isViewMode}) {
    const [isConclusionOpened, setConclusionOpened] = useState(false);
    const toggleDisplay = () => setConclusionOpened(!isConclusionOpened);

    const digest = text => {
        if (!text) {
            return 'Aucune';
        }
        const textDiggest = text.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 42);
        return text.length > 42 ? textDiggest + '...' : textDiggest;
    }

    return (
        <div>
            <div className="mb-2 d-md-flex flex-row justify-content-between align-items-center text-dark">

                <ToggleSectionTitle
                    title={'CONCLUSION'}
                    icon={faScroll}
                    toggleFunction={toggleDisplay}
                />

                <div className={`flex-grow-1`}>
                    {!isConclusionOpened && <h6>{digest(conclusion)}</h6>}
                </div>

                {(!isViewMode || conclusion) &&
                <Button size={'sm'} color="info" outline onClick={toggleDisplay}>
                    {
                        isConclusionOpened
                            ? <em>Fermer <FontAwesomeIcon icon={faChevronUp}/></em>
                            : <em>Afficher <FontAwesomeIcon icon={faChevronDown}/></em>
                    }
                </Button>
                }

            </div>

            <Collapse isOpen={isConclusionOpened}>
                {isViewMode
                    ? <Card color="light">
                        <CardBody>
                            <div className="d-md-flex flex-row justify-content-center align-items-center text-dark">
                                <div className="col-12 clo-lg-8" dangerouslySetInnerHTML={{__html: conclusion}}/>
                            </div>
                        </CardBody>
                    </Card>
                    : <CKEditor
                        editor={ClassicEditor}
                        config={ {
                            toolbar: ['heading', 'bold', 'italic', 'Link', 'numberedList', 'bulletedList']
                        } }
                        data={conclusion}
                        onChange={(event, editor) => {
                            setConclusion(editor.getData());
                        }}
                    />
                }

            </Collapse>
        </div>
    );
}

ProjectSectionConclusion.prototypes = {
    conclusion: PropTypes.string.isRequired,
    setConclusion: PropTypes.func.isRequired,
    isViewMode: PropTypes.bool.isRequired
}

export default ProjectSectionConclusion;
