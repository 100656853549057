import React, {useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {ADD_EXTRA_FIELD_TO_SCHEMA, ADD_UNIT_TO_SCHEMA, REMOVE_UNIT_TO_SCHEMA} from "../../graphql/mutations";
import {GET_FUNDINGS_BY_PERIMETER} from "../../graphql/queries";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Card, CardBody, CustomInput} from "reactstrap";
import FormSearchUnite from "../Form/FormSearchUnite";
import Element from "./Element";
import PropTypes from 'prop-types';
import TermsAccess from "./TermsAccess";

function SchemaVisualizer({schema, edit, perimeter, setSchema, canAdd, setTouched}) {
    const onCompleted = ({data: schema}) => {
        setSchema(JSON.parse(schema?.schema) ?? {});
    }
    const [addExtraField] = useMutation(ADD_EXTRA_FIELD_TO_SCHEMA, {onCompleted: onCompleted});
    const [addUnitToSchema] = useMutation(ADD_UNIT_TO_SCHEMA, {onCompleted: onCompleted});
    const [removeUnitToSchema] = useMutation(REMOVE_UNIT_TO_SCHEMA, {onCompleted: onCompleted});

    const onUpdate = (id, extraKey, extraValue, newSchema = null, uniteOnly = true, visitChildren = true) => {
        if (Array.isArray(extraKey)) { // addExtraField chaining
            return onUpdate(id, extraKey[0], extraValue[0], newSchema, uniteOnly, visitChildren).then(({data}) => {
                const {data: schema} = data;
                if (extraKey.length > 1) {
                    return onUpdate(id, extraKey.splice(1, extraValue.length - 1), extraValue.splice(1,
                        extraValue.length - 1), schema.schema, uniteOnly, visitChildren);
                }
            });
        }

        setTouched(true);
        return addExtraField({
            variables: {
                schema: newSchema ?? JSON.stringify(schema),
                elementId: id,
                extraKey: extraKey,
                extraValue: extraValue,
                uniteOnly: uniteOnly,
                visitChildren: visitChildren
            }
        });
    }

    const {loading: loadingFundings, error: errorFundings, data: dataFundings} = useQuery(GET_FUNDINGS_BY_PERIMETER, {
        variables: {perimeter: perimeter}
    });

    const addUnit = (unit) => {
        setTouched(true);
        return addUnitToSchema({
            variables: {
                schema: JSON.stringify(schema),
                elementId: schema.extras.id,
                codeUnite: unit.code
            }
        })
    }

    const removeUnit = (id) => {
        setTouched(true);
        return removeUnitToSchema({
            variables: {
                schema: JSON.stringify(schema),
                elementId: id
            }
        })
    }

    if (loadingFundings) {
        return <div className="d-flex justify-content-center">
            <em className="text-muted"><FontAwesomeIcon icon={faSpinner} spin/> Chargement...</em>
        </div>
    }

    if (errorFundings) {
        return <div className="d-flex justify-content-center">
            Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span>
        </div>
    }

    const {Fundings: fundings} = dataFundings;

    return (
        schema.schema ?
            <Card color="light" style={{marginTop: '1rem'}}
                  className="ml-1 mb-1">
                <CardBody>
                    {edit && canAdd && <FormSearchUnite onSubmit={addUnit} />}
                    {!canAdd && <TermsAccess schema={schema} edit={edit} setTouched={setTouched} />}
                    <Element schema={schema}
                             edit={edit}
                             onUpdate={onUpdate}
                             fundings={fundings}
                             removeElement={canAdd ? removeUnit : null}
                             isAlternance={schema?.extras?.isAlternance}
                    />
                </CardBody>
            </Card>
            : ''
    )
}

SchemaVisualizer.propTypes = {
    schema: PropTypes.object.isRequired,
    edit: PropTypes.bool,
    fundings: PropTypes.array,
    setSchema: PropTypes.func,
    canAdd: PropTypes.bool,
    setTouched: PropTypes.func.isRequired
};

SchemaVisualizer.defaultProps = {
    edit: false,
    fundings: [],
    canAdd: false,
    setSchema: () => {},
    setTouched: () => {}
}

export default SchemaVisualizer;
