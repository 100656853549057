import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faTimesCircle,
    faCalendarAlt,
    faCalendarPlus,
    faCalendarMinus,
    faInfoCircle,
    faCoins,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import {Collapse, CustomInput, Form, FormGroup, Input, Label, Tooltip, ButtonGroup, Button} from "reactstrap";
import {convertOptionToOptionId, EnrolOptions} from "./EnrolOptions";

function Unite({schema, edit, fundings, onUpdate, removeElement, isAlternance}) {
    const {code, libelle, credits, extras} = schema;

    const [showSchedule, setShowSchedule] = useState(false);
    const toggleSchedule = () => setShowSchedule(!showSchedule);
    const handleObtained = () => {
        onUpdate(extras.id, 'obtained', extras.obtained ? 0 : 1);
    }
    const currentYear = (new Date()).getFullYear();
    const [year, setYear] = useState(extras.year ? extras.year : null);
    const handleDefaultYear = () => {

        if (!year) {
            setYear(currentYear);
            onUpdate(extras.id, 'year', currentYear);
        }
    }
    const handleYearChange = (e) => {
        setYear(e.currentTarget.value ?? null);
        if (e.currentTarget.checkValidity()) {
            onUpdate(extras.id, 'year', e.currentTarget.value);
        }
    };

    const handleMode = (e) => {
        const mode = e.currentTarget.value;
        onUpdate(
            extras.id,
            mode !== 'ens' ? ['mode', 'options'] : ['mode'],
            mode !== 'ens' ? [mode, '[]'] : [mode]
        );
    }

    const setOption = option => {
        const optionId = convertOptionToOptionId(option);
        const options = extras.options
            ? extras.options.filter(opt => convertOptionToOptionId(opt) !== optionId)
            : [];

        if (!extras.options || options.length === extras.options.length) {
            options.push(option);
        }
        const year = options.length > 0 ? Math.min(...options.map(op => op.year)) : '';
        setYear(year ? year : null);
        onUpdate(extras.id, ['options', 'year'], [JSON.stringify(options), year]);
    }

    const handleFunding = e => {
        const funding = fundings.filter(f => f.id === e.currentTarget.value).pop();
        const newFundings = extras.funding.filter(f => f.id !== funding.id);
        if (newFundings.length === extras.funding.length) {
            newFundings.push(funding);
        }
        onUpdate(extras.id, 'funding', JSON.stringify(newFundings));
    }

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const [tooltipFundingOpen, setTooltipFundingOpen] = useState(false);

    const toggleTooltipFunding = () => setTooltipFundingOpen(!tooltipFundingOpen);

    const selectedFundingText = extras.funding.map(f => f.label).join(', ');

    return <div onDoubleClick={toggleSchedule}
                className={`schema-unite schema-unite-mode-${extras.mode}`}>
        <div className={`d-flex justify-content-start`}>
            {edit && <div
                className={`schema-unite-actions mr-1 d-flex flex-grow-0 flex-column`}>
                <FontAwesomeIcon title={extras.obtained ? 'Invalider l\'obtention' : 'Valider l\'obtention'}
                                 className={'mt-1 text-primary schema-unite-action'}
                                 icon={extras.obtained ? faTimesCircle : faCheckCircle}
                                 size={'lg'}
                                 onClick={handleObtained}
                />
                {extras.obtained === 0 &&
                <FontAwesomeIcon title={'Consulter ou modifier l\'organisation de cette unité'}
                                 className={'mt-1 text-primary schema-unite-action'}
                                 icon={showSchedule ? faCalendarMinus : faCalendarPlus} size={'lg'}
                                 onClick={toggleSchedule}/>}
            </div>}
            <div className={'d-flex flex-grow-1 flex-column justify-content-between'}>
                <div className={'d-flex justify-content-between'}>
                    <a href={`https://bedeo.cnam.fr/unite/view/${code}`} target={'_blank'}>
                        <span className={'text-info'}>{code}</span>
                    </a>
                    {edit && removeElement && <FontAwesomeIcon title={'Supprimer l\'unité'}
                                              style={{cursor: 'pointer'}}
                                              className={'text-danger'}
                                              onClick={() => removeElement(extras.id)}
                                              icon={faTimes}
                    />}
                </div>
                <span>{libelle}</span>
                <div className={'text-muted d-flex justify-content-between align-items-end'}>
                    <div className={'d-flex justify-content-start'}>
                <span>
                    <FontAwesomeIcon className={extras.obtained ? 'text-success' : ``}
                                     icon={extras.obtained ? faCheckCircle : faCalendarAlt}/>
                    {
                        (extras.obtained && ' Obtenue') ||
                        (extras.mode !== 'none' && ' Prévue') || ' Non prévue'
                    }
                    {
                        (extras.mode === 'ens' && ' en Formation') ||
                        (extras.mode === 'ves' && ' en VES') ||
                        (extras.mode === 'vae' && ' en VAE')
                    }
                    {
                        extras.mode === 'ens' && <span>
                            <span id='ensInfos'> <FontAwesomeIcon icon={faInfoCircle}/></span>
                            <Tooltip placement="right" isOpen={tooltipOpen} target="ensInfos" toggle={toggleTooltip}>
                                {extras.options.length > 0
                                    ? extras.options.map(op => convertOptionToOptionId(op)).join(' | ')
                                    : 'Aucune option de formation choisie'
                                }
                            </Tooltip>
                        </span>
                    }
                    {
                        (extras.mode !== 'none' && extras.year ? ` (${extras.year}/${extras.year + 1})` : null)
                    }
                </span>
                        <span id={'fundingsInfos'} className={'ml-2'}>

                            {!extras.obtained && extras.mode !== 'none' && <span>
                                <FontAwesomeIcon icon={faCoins}/>
                                {extras.funding.length === 0 && <span className={'ml-1'}>Aucun financement</span>}
                                <span> {selectedFundingText.substr(0, 30)}{selectedFundingText.length > 30 ? '...' : ''}</span>
                                {
                                    selectedFundingText.length > 30 &&
                                    <Tooltip placement="right" isOpen={tooltipFundingOpen} target="fundingsInfos"
                                             toggle={toggleTooltipFunding}>
                                        {selectedFundingText}
                                    </Tooltip>
                                }
                            </span>}
                        </span>
                    </div>
                    <span className={'badge badge-info rounded'}>{credits} ECTS</span>
                </div>
            </div>
        </div>
        <Collapse isOpen={edit && showSchedule && extras.obtained === 0}>
            <div className={'mt-3'}>
                <h6>Organisation</h6>
                <Form className={'ml-3'} onSubmit={e => e.preventDefault()}>
                    <FormGroup>
                        {
                            extras.mode !== 'none' && <>
                                <FormGroup>
                                    <Label>Financement</Label>
                                </FormGroup>
                                <FormGroup className={`ml-1 mb-3 text-${extras.mode}`} check inline>
                                    {
                                        (fundings.length === 0 && 'Aucun') ||
                                        fundings.map(f => <FormGroup check inline>
                                            <CustomInput
                                                id={`${extras.id + f.id}`}
                                                type={'switch'}
                                                value={f.id}
                                                checked={extras.funding.filter(fun => fun.id === f.id).length === 1}
                                                onClick={handleFunding}
                                            />
                                            <Label for={`${extras.id + f.id}`}>{f.label}</Label>
                                        </FormGroup>)
                                    }
                                </FormGroup>
                            </>
                        }
                        <FormGroup>
                            <Label>Mode de validation</Label>
                        </FormGroup>
                        <FormGroup className={'ml-1 d-flex flex-wrap'} check inline>
                            <ButtonGroup>
                                <Button  outline color="secondary" value={'none'}   active={extras.mode === 'none'} onClick={handleMode}>Aucun</Button>
                                <Button  outline className={'btn-formation'} value={'ens'} active={extras.mode === 'ens'} onClick={handleMode}>Formation</Button>
                                <Button  outline color="warning" className={'btn-VES'} value={'ves'} active={extras.mode === 'ves'} onClick={handleMode}>VES</Button>
                                <Button  outline className={'btn-VAE'} value={'vae'} active={extras.mode === 'vae'} onClick={handleMode}>VAE</Button>
                            </ButtonGroup>
                        </FormGroup>
                    </FormGroup>

                    {extras.mode !== 'none' && (extras.mode !== 'ens' || extras.options.length === 0) && <FormGroup>
                        <FormGroup>
                            <Label>Année de validation (passée ou estimée)</Label>
                        </FormGroup>
                        <FormGroup className={'ml-1'}>
                            <Input onFocus={handleDefaultYear} onChange={handleYearChange} onKeyUp={handleYearChange}
                                   value={year} type={"number"}
                                   min={1980} max={currentYear + 5}/>
                        </FormGroup>
                    </FormGroup>}

                    {extras.mode === 'ens' && !isAlternance && <div className={'mb-1'}>
                        <FormGroup>
                            <Label>Options de formation</Label>
                        </FormGroup>
                        <FormGroup>
                            <EnrolOptions code={code} setOption={setOption} options={extras.options ?? []} />
                        </FormGroup>
                    </div>}
                </Form>
            </div>
        </Collapse>
    </div>
}

export default Unite;
