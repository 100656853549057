// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// Add icons needed
import { faCompass as faCompassRegular } from '@fortawesome/free-regular-svg-icons';

import {
    faCompass as faCompassSolid,
    faProjectDiagram,
    faCog,
    faInfoCircle,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faExpand,
    faCompress,
    faUser,
    faChevronRight,
    faChevronDown,
    faPlusCircle,
    faSearch,
    faHistory,
    faSignOutAlt,
    faChartPie,
    faSpinner,
    faUserGraduate, // Prospects
    faUserPlus, // Prospects
    faMapMarkerAlt, // Perimeters
    faFolderOpen, // Project open
    faFolder, // Project closed
    faSignInAlt,
    faClock, // Date
    faEnvelope, // Email
    faCalendarDay, // Prospect's birthDate
    faList,
    faUserShield // Administration ?
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faCompassRegular,
    faCompassSolid,
    faProjectDiagram,
    faCog,
    faInfoCircle,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faExpand,
    faCompress,
    faUser,
    faChevronRight,
    faChevronDown,
    faPlusCircle,
    faSearch,
    faHistory,
    faSignOutAlt,
    faChartPie,
    faSpinner,
    faUserGraduate,
    faUserPlus,
    faMapMarkerAlt,
    faFolderOpen,
    faFolder,
    faSignInAlt,
    faClock,
    faEnvelope,
    faCalendarDay,
    faList,
    faUserShield
);
