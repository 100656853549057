import React, {useState} from "react";
import {Form, FormGroup} from "reactstrap";
import AutoSuggestInput from './AutoSuggestInput';
import PropTypes from "prop-types";

function FormSearchWithLazyQuery({
                                     onSubmit,
                                     onSelect,
                                     lazyQuery,
                                     minLength,
                                     searchVariableName,
                                     variables,
                                     renderItem,
                                     placeholder,
                                     className,
                                     name,
                                     onFocus,
                                     onBlur,
                                     zIndexStart
                                 }) {
    const [value, setValue] = useState('');
    const [canSearch, setCanSearch] = useState(false);

    const [loadItems, {loading, data}] = lazyQuery;
    const handleChange = e => {
        const newValue = e.currentTarget.value;
        setValue(newValue);
        search(newValue);
    }
    const search = (val) => {
        if (val.length >= minLength) {
            loadItems({variables: {...variables, [searchVariableName]: val.toUpperCase()}});
            setCanSearch(val.length >= minLength);
        }
    }

    return <Form onFocus={() => setTimeout(() => search(value), 500)} onBlur={() => setTimeout(() => setCanSearch(false), 500)} className={className} onSubmit={() => {setValue(''); onSubmit(value); }}>
        <FormGroup>
            <AutoSuggestInput placeholder={placeholder}
                              onChange={handleChange}
                              value={value}
                              loading={canSearch && loading}
                              items={canSearch ? data?.items : null}
                              onSelect={(item, inputValue) => {
                                  setCanSearch(false);
                                  setValue('');
                                  onSelect(item, inputValue);
                              }}
                              renderItem={renderItem}
                              name={name}
                              onFocus={onFocus}
                              onBlur={onBlur}
                              zIndexStart={zIndexStart}
            />
        </FormGroup>
    </Form>
}

FormSearchWithLazyQuery.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    lazyQuery: PropTypes.array.isRequired,
    minLength: PropTypes.number.isRequired,
    searchVariableName: PropTypes.string.isRequired,
    variables: PropTypes.object.isRequired,
    renderItem: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.object,
    name: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    zIndexStart: PropTypes.number
};

FormSearchWithLazyQuery.defaultProps = {
    onSubmit: () => {},
    onSelect: () => {},
    minLength: 1,
    variables: {},
    placeholder: 'Rechercher',
    onBlur: () => {}
}

export default FormSearchWithLazyQuery;