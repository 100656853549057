import React from "react";
import {Form, FormGroup, Input, Label} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PerimetersTreeHandling from "../Tree/PerimetersTreeHandling";

function FormFunding(props) {
    const localRoles = props.localRoles ?? [];

    const textSubmit = props.mode === "create" ? "CRÉER" : "MODIFIER";
    const colorSubmit = props.mode === "create" ? "outline-success" : "outline-info";
    const colorSubmitReady = props.mode === "create" ? "success" : "info";

    let requiredFieldsInfo = [
        {text: "Les champs * sont obligatoires"}
    ];

    return (
        <Form onSubmit={props.onSubmit} autoComplete="off">
            <FormGroup>
                <Label for="fundingLabel">Libellé *</Label>
                <Input type="text"
                       name="label"
                       id="fundingLabel"
                       placeholder="Libellé"
                       data-label="Libellé"
                       value={props.values.label?.value}
                       onChange={props.onChange}
                />
                <div className={"p-1 text-warning"}>
                    <small className={"text-warning"}>
                        {props.touched.label && props.errors.label}
                    </small>
                </div>
            </FormGroup>

            <FormGroup>
                <p>Sélectionnez un périmètre *</p>
                <PerimetersTreeHandling
                    acquiredOnly={true}
                    perimeterTree={localRoles.filter(({ role }) => ( role.id === 'ROLE_ADMIN' )).shift()}
                    origin={'ROT'}
                    onChange={props.onPerimeterChange}
                    selectedPerimeter={props.values.perimeter.value}
                />
                <div className={"p-1 text-warning"}>
                    <small className={"text-warning"}>
                        {props.touched.perimeter && props.errors.perimeter}
                    </small>
                </div>
            </FormGroup>

            <div className={"row justify-content-around align-items-center pt-3 pt-lg-5"}>
                <div className={"col-12 col-lg-4"}>
                    {requiredFieldsInfo.map(({text}, index) => (
                        <small key={index} className={"row pl-3"}>{text}</small>
                    ))}
                </div>
                <div className={"col-12 col-lg-8"}>
                    {
                        Object.values(props.errors).length > 0
                            ? <button className={"btn btn-lg btn-outline-warning btn-block disabled"} type="submit">Le
                                formulaire est incomplet</button>
                            :
                            Object.values(props.touched).length === Object.values(props.values).length // all fields were touched
                                ? <button className={`btn btn-lg btn-${colorSubmitReady} btn-block`} type="submit">
                                    {
                                        props.mode === 'create'
                                            ? <FontAwesomeIcon icon={['fas', 'plus-circle']} size="sm"/>
                                            : ''
                                    }
                                    {` ${textSubmit}`}
                                </button>
                                : <button className={`btn btn-lg btn-${colorSubmit} btn-block`} type="submit">
                                    {
                                        props.mode === 'create'
                                            ? <FontAwesomeIcon icon={['fas', 'plus-circle']} size="sm"/>
                                            : ''
                                    }
                                    {` ${textSubmit}`}
                                </button>
                    }
                </div>
            </div>

        </Form>
    )
}

export default FormFunding;
