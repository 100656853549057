import React from 'react';
import FundingFormHandling from "../components/Form/FundingFormHandling";
import Header from "../components/Layout/Header";
import {faChevronLeft, faCoins} from "@fortawesome/free-solid-svg-icons";
import FormFundingCard from "../components/Card/FormFundingCard";
import {useQuery} from "@apollo/client";
import {FUNDING_ID_QUERY} from "../graphql/queries";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "reactstrap";

// TODO: funding can not be granted on edit (hiding buttons, readonly fields)
function FundingOverView(props)
{
    const {loading, error, data} = useQuery(FUNDING_ID_QUERY, {
        variables: {
            fundingID: props.match.params.id ?? 0,
        },
    });

    let mode = props.match.params.accessMode ?? 'create';
    let subtitle = "FINANCEMENT " + data?.Fundings[0]?.label;

    const initialValues = {
        perimeter: {
                value: data?.Fundings[0]?.perimeter?.id ?? null,
                label: 'Périmètre'
            },
        label: {
                value: data?.Fundings[0]?.label ?? null,
                label: 'Libellé'
            },
        fundingID: {
                value: props.match.params.id,
                label: 'FundingID'
            },
    }

    const isRequired = (fieldName, fieldValue) => {
        if (fieldValue === null) {
            return `${fieldName} est requis`;
        }
        if (fieldValue.trim() === '') {
            return `${fieldName} est requis`;
        }
    }

    const validate = {
        perimeter: val => isRequired('Le périmètre', val?.value, true),
        label: val => isRequired("Le libellé", val?.value, true),
        fundingID: val => ''
    }

    return (
        <div className={"container-fluid"}>

            <Header titlePage={"FINANCEMENTS"}
                    faIcon={faCoins}
            />

            <Button
                className={'mb-2 mb-lg-0'}
                onClick={() => props.history.goBack()}
                color={'danger'}><FontAwesomeIcon
                icon={faChevronLeft}
                size={'lg'}
            /> Retour</Button>

            {
                loading && mode !== 'create'
                    ?
                    <div className={"d-flex flex-row justify-align-content"}>
                        <p><FontAwesomeIcon icon={['fas', 'spinner']} spin/> Chargement</p>
                    </div>
                    : ""
            }

            {
                error && mode !== 'create'
                    ?
                    <div className={"d-flex flex-row justify-align-content"}>
                        <p>Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
                    </div>
                    : ""
            }

            {
                data || mode === 'create' ?
                <FormFundingCard mode={mode} subtitle={subtitle}>
                    <FundingFormHandling
                        validate={validate}
                        initialValues={initialValues}
                        mode={mode}
                    />
                </FormFundingCard>
                    : ''
            }
        </div>
    )
}

export default FundingOverView;