import React from "react";
import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const FormSearchProspect = ({values, handleChange, handleSubmit}) => {
    return (
        <Form onSubmit={handleSubmit} autoComplete="off">
            <FormGroup>
                <Label for="prospectUseName">Nom *</Label>
                <Input type="text"
                       name="nameLike"
                       id="prospectUseName"
                       placeholder="Nom d'usage"
                       data-label="Nom d'usage"
                       required={true}
                       value={values.userName?.value}
                       onChange={handleChange}
                />
            </FormGroup>

            <FormGroup>
                <Label for="prospectFirstNameLike">Prénom *</Label>
                <Input type="text"
                       name="firstNameLike"
                       id="prospectFirstName"
                       placeholder="Prénom"
                       data-label="Prénom"
                       required={true}
                       value={values.firstName?.value}
                       onChange={handleChange}
                />
            </FormGroup>

            <FormGroup>
                <Label for="prospectEmail">Email</Label>
                <Input type="email"
                       name="emailLike"
                       id="prospectEmail"
                       placeholder="prospect@email.com"
                       data-label="Email"
                       value={values.email?.value}
                       onChange={handleChange}
                />
            </FormGroup>

            <FormGroup className={"d-flex justify-content-end"}>
                <Button className="col-12" type={"submit"} color={"primary"}>
                    <FontAwesomeIcon icon={faSearch}/> Rechercher
                </Button>
            </FormGroup>
        </Form>
    )
}

FormSearchProspect.propTypes = {
    values: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
    handleSubmit:PropTypes.func.isRequired
};

FormSearchProspect.defaultProps = {
    values: []
};

export default FormSearchProspect;
