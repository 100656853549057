class NotificationToast {

    constructor(title, message, color=null, icon=null) {
        this._title = title;
        this._message = message;
        this._color = color
        this._icon = icon;
        this._date = Date.now();
    }

    getNotification = () => {
        return {
            title: this._title,
            message: this._message,
            color: this._color,
            icon: this._icon,
            date: this._date,
        }
    }

    get title() {
        return this._title;
    }

    set title(value) {
        this._title = value;
    }

    get message() {
        return this._message;
    }

    set message(value) {
        this._message = value;
    }

    get icon() {
        return this._icon;
    }

    set icon(value) {
        this._icon = value;
    }

    get color() {
        return this._color;
    }

    set color(value) {
        this._color = value;
    }

    get date() {
        return this._date;
    }

    set date(value) {
        this._date = value;
    }
}

export default NotificationToast;
