import React, {useContext, useEffect} from 'react';
import WidgetCard from "./WidgetCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useQuery} from "@apollo/client";
import UserContext from "../../contexts/UserContext";
import PropTypes from "prop-types";
import {ROLE_CHARGE_AIOA_IP} from "../../lib/security/roles";

function CountCard({query, refreshIntervalMs, title, faIcon, children}) {
    const { currentUser } = useContext(UserContext);
    const currentUserComputedPerimeter = currentUser?.localRolesComputed[ROLE_CHARGE_AIOA_IP]
        ? currentUser?.localRolesComputed[ROLE_CHARGE_AIOA_IP][0]
        : '';

    const { loading, error, data, refetch, startPolling, stopPolling } = useQuery(query, {
        variables: { userId: currentUser?.id ? currentUser.id : 0, perimeter: currentUserComputedPerimeter }
    });

    useEffect(() => {
        refetch();
    }, []);
    useEffect(() => {
        startPolling(refreshIntervalMs)
        return () => {
            stopPolling()
        }
    }, [startPolling, stopPolling])

    if (loading) {
        return (
            <WidgetCard title={ title } faIcon={ faIcon }>
                <div className={"d-flex flex-row justify-align-content"}>
                    <p><FontAwesomeIcon icon={['fas', 'spinner']} spin /> Chargement</p>
                </div>
            </WidgetCard>
        );
    }

    if (error) {
        return (
            <WidgetCard title={ title } faIcon={ faIcon }>
                <div className={"d-flex flex-row justify-align-content"}>
                    <p>Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
                </div>
            </WidgetCard>
        );
    }

    return (
        <WidgetCard title={ title } faIcon={ faIcon }>
            <div className={"d-flex flex-row justify-align-content justify-content-between align-items-end"}>
                <h4 className={"display-4"}>{ data.count }</h4>
                {children}
            </div>
        </WidgetCard>
    );
}

CountCard.propTypes = {
    query: PropTypes.string.isRequired,
    title: PropTypes.string,
    faIcon: PropTypes.object,
    refreshIntervalMs: PropTypes.number
};

CountCard.defaultProps = {
    refreshIntervalMs: 30000 // 30s
}

export default CountCard;