import React, {useContext} from 'react';
import UserContext from "../../contexts/UserContext";
import PropTypes from "prop-types";
import {ROLE_ADMIN, ROLE_CHARGE_AIOA_IP, ROLE_GUEST} from "../../lib/security/roles";

function RenderIfRole({isRoleChargeAIOAIP, isRoleGuest, isRoleAdmin, all, children}) {
    const { currentUser } = useContext(UserContext);
    if (!currentUser) {
        return;
    }
    const currentUserRoles = currentUser.roles;
    const requiredRoles = [
        isRoleChargeAIOAIP ? ROLE_CHARGE_AIOA_IP : undefined,
        isRoleGuest ? ROLE_GUEST : undefined,
        isRoleAdmin ? ROLE_ADMIN : undefined
    ].filter(r => r);

    const matchingRoles = requiredRoles.filter(r => currentUserRoles.includes(r));
    const granted = all ? matchingRoles.length === requiredRoles.length : matchingRoles.length > 0;

    return granted ? children : ''
}

RenderIfRole.propTypes = {
    isRoleChargeAIOAIP: PropTypes.bool.isRequired,
    isRoleGuest: PropTypes.bool.isRequired,
    isRoleAdmin: PropTypes.bool.isRequired,
    all: PropTypes.bool.isRequired
};

RenderIfRole.defaultProps = {
    isRoleChargeAIOAIP: false,
    isRoleGuest: false,
    isRoleAdmin: false,
    all: true
};

export default RenderIfRole;
