import React from 'react';
import {Badge, ListGroupItem} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faClock,
    faCrosshairs,
    faFolder,
    faFolderOpen,
    faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";

function ProjectListGroupItem( {props, index} ) {
    const hasProspect = props.prospect !== null;
    const hasFormation = props.cursusTargeted || JSON.parse(props.schema.schema).schema !== undefined;
    const unitsCount = hasFormation ? JSON.parse(props.schema.schema).schema.length : 0;

    return (
        <ListGroupItem key={index} tag="a" href={`/project/${props.id}`} action>
            <div className={"d-flex justify-content-between"}>
                {
                    hasProspect
                        ? <h5>{props.prospect.firstName} {props.prospect.useName.toUpperCase()}</h5>
                        : <h5>-</h5>
                }
                <Badge pill className={"align-self-center"} color={props.isOpen ? 'success' : 'secondary'}>
                    {props.isOpen ? 'Ouvert' : 'Clos'}
                </Badge>
            </div>
            <div className={"d-flex justify-content-start"}>
                <span className={"muted mr-3"}>
                    <span><FontAwesomeIcon icon={props.isOpen ? faFolderOpen : faFolder}/> </span>
                    Projet n°{props.id}
                </span>
                <span className={"muted mr-3"}>
                    <span><FontAwesomeIcon icon={faCrosshairs}/> </span>
                    <span>
                        {
                            !hasFormation ? '-' : ''
                        }
                        {
                            props.cursusTargeted ? 'Cursus (' + props.cursusTargeted.code + ')' : ''
                        }
                        {
                            hasFormation && !props.cursusTargeted ? 'Enseignements à la carte (x' + unitsCount + ')' : ''
                        }
                    </span>
                </span>
                <span className={"muted mr-3"}>
                    <span><FontAwesomeIcon icon={faMapMarkerAlt}/> </span>
                    {props.perimeter.parent.label}
                </span>
                <span className={"muted mr-3"}>
                    <span><FontAwesomeIcon icon={faClock}/> </span>
                    {formatDate(props.updatedAt)}
                </span>
            </div>
        </ListGroupItem>
    )
}

function formatDate(string) {
    let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(string).toLocaleDateString('fr-FR', options);
}

export default ProjectListGroupItem;
