const auth = {
    getToken: async username => {
        let token = localStorage.getItem('token');

        if (token === null) {
            token = auth.fetchToken(username);
        }

        return token;
    },
    fetchToken: username => {
        const requestOptions = {
            method: 'POST',
            referrerPolicy: 'origin',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify( username ? {'uid': username} : {} ),
        };
        return fetch(process.env.REACT_APP_API_URI, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.token) {
                    auth.setToken(data.token);
                    return data.token;
                } else if (process.env.NODE_ENV !== 'development' && data.redirectTo) {
                    window.location.replace(data.redirectTo);
                }
                return null;
            })
            .catch(error => {
                auth.unsetToken();
                console.log(error);
            });
    },
    setToken: token => { localStorage.setItem('token', token); },
    unsetToken: () => { localStorage.removeItem('token'); },
    getUser: () => {
        const token = localStorage.getItem('token');

        if (token === null) {
            return null;
        }

        const tokenParts = token.split('.');

        if (tokenParts.length !== 3) {
            return null;
        }

        return JSON.parse(window.atob(tokenParts[1]));
    }
}

export default auth;
