import React, {useEffect, useState} from 'react';
import PerimetersTreeHandling from "../Tree/PerimetersTreeHandling";
import {Button, FormGroup} from "reactstrap";

function PerimetersListManagement(props) {
    const personalPerimeters = props?.localRoleSelected[0]?.perimetres ?? [];
    const [perimeterTree, setPerimeterTree] = useState();
    const [values, setValues] = useState();

    let initState = personalPerimeters.map((p)=>p.id)

    useEffect(()=>{
        if (!values)
            getInitialValues()
    }, [perimeterTree])

    useEffect(()=>{
        getInitialValues()
    }, [props.localRoleSelected])

    const getInitialValues= () =>{
        if(perimeterTree)
        {
            for (const perimeter of initState) {
                let parent = findParentPerimeterInTree(perimeter)
                while (parent) {
                    //check if every brother of the state are here
                    const brothers = parent.children.map(c=>c.id)
                    if (brothers.every(b=>initState.includes(b))) {
                        initState = initState.filter(i=>!brothers.includes(i))
                        initState.push(parent.id)
                    }
                    parent = findParentPerimeterInTree(parent.id)
                }
            }
            setValues(initState)
        }
    }

    const compareSelection = () => {
        if (!values) {
            return [[], []];
        }

        //what is in init values and not in new is to remove
        const perimetersToRemove = initState.filter((p) => {
            if (values.includes(p)) {
                return false;
            }
            const parents = findParentsInTree(p);
            return parents.length > 0 && parents.filter(parent => values.includes(parent)).length === 0;
        } )
        //what is not in init values and is in new is to add
        const perimetersToAdd = values.filter((p)=>!initState.includes(p))
        return [perimetersToAdd, perimetersToRemove];
    }

    const handleSubmit = () => {
        const [perimetersToAdd, perimetersToRemove] = compareSelection();
        props.update(perimetersToAdd, perimetersToRemove);
    }

    const resetSelection = ()=>{
        setValues(initState)
    }

    const findPerimeterInTree = (id, perimeters=perimeterTree) => {
        for (const perimeter of perimeters){
            if(perimeter.id === id) return perimeter
            if(perimeter.children){
                const child=findPerimeterInTree(id, perimeter.children)
                if(child) return child
            }
        }
    }

    const findChildrenInTree = (perimeter, childrenIds = []) => {
        if (perimeter.children) {
            for (const child of perimeter.children) {
                childrenIds.push(child.id)
                if (child.children)
                    childrenIds.concat(findChildrenInTree(child, childrenIds))
            }
        }
        return childrenIds
    }

    const findParentsInTree = (perimeterId, parentsIds = []) => {
        //get all parents list of perimeter checked
        const parent = findParentPerimeterInTree(perimeterId)
        if (parent) {
            parentsIds.push(parent.id)
            parentsIds.concat(findParentsInTree(parent.id, parentsIds))
        }
        return parentsIds
    }

    function findParentPerimeterInTree (id, perimeters=perimeterTree) {
        for (const perimeter of perimeters) {
            if (perimeter.children) {
                if (perimeter.children?.find((child) =>  child.id === id))
                    return perimeter;
                else {
                    const parent = findParentPerimeterInTree(id, perimeter.children)
                    if (parent) return parent;
                }
            }
            else return null;
        }
    }

    const deselectParents = (parents, selectedPerimeters, checked) => {
        const parentsSelected = parents.filter((p)=>selectedPerimeters.includes(p))
        if (parentsSelected.length>0) {
            const checkedIndex=selectedPerimeters.indexOf(checked)
            selectedPerimeters = [...selectedPerimeters.slice(0,checkedIndex), ...selectedPerimeters.slice(checkedIndex+1)]
            //deselect parent, select all children except if its a parent of checked
            parentsSelected.map((parent)=>{
                selectedPerimeters = [...selectedPerimeters.slice(0,selectedPerimeters.indexOf(parent)),
                    ...selectedPerimeters.slice(selectedPerimeters.indexOf(parent)+1)]
                const parentPerimetre = findPerimeterInTree(parent)
                const parentsToAdd = parentPerimetre.children.filter((pc)=>!parents.includes(pc.id)&&pc.id!==checked)
                selectedPerimeters = selectedPerimeters.concat(parentsToAdd.map(p=>p.id))
            })

            //add perimeter's checked brothers
            const brothers = findParentPerimeterInTree(checked).children.filter((c)=>!selectedPerimeters.includes(c.id) && c.id !== checked)
            selectedPerimeters = selectedPerimeters.concat(brothers.map(p=>p.id))
        }
        return selectedPerimeters
    }


    const onChange = e => {
        const checked = e.currentTarget.dataset.id;
        const checkedPerimeter = findPerimeterInTree(checked)
        const parent = findParentPerimeterInTree(checked);

        if (values.indexOf(checked) === -1) {
            if (parent) {
                let selectedPerimeters = values;
                selectedPerimeters = [...selectedPerimeters,checked]
                //if have children selected
                const children = findChildrenInTree(checkedPerimeter)
                const childrenSelected = children.filter((c)=>selectedPerimeters.includes(c))
                if (childrenSelected.length > 0) {
                    //deselect children
                    childrenSelected.map((child)=>{
                        selectedPerimeters = [...selectedPerimeters.slice(0,selectedPerimeters.indexOf(child)),
                            ...selectedPerimeters.slice(selectedPerimeters.indexOf(child)+1)]
                    })
                }
                //if have parents selected
                const parents = findParentsInTree(checked)
                selectedPerimeters = deselectParents(parents, selectedPerimeters, checked)

                //if have no parent or child selected
                //if with this selection, the parent is checked
                //check parent and remove children
                if(childrenSelected.length === 0 &&  parents.filter((p)=>selectedPerimeters.includes(p)).length ===0) {
                    const brothers = findParentPerimeterInTree(checked).children.map(c=>c.id)
                    if (brothers.every(r => selectedPerimeters.includes(r))) {
                        for(const brother of brothers) {
                            selectedPerimeters = [...selectedPerimeters.slice(0,selectedPerimeters.indexOf(brother)),
                                ...selectedPerimeters.slice(selectedPerimeters.indexOf(brother)+1)]
                        }
                        selectedPerimeters = [...selectedPerimeters,parent.id]
                    }
                }
                setValues(selectedPerimeters)
            }
            else {
                setValues([checked])
            }
        }
        else { //deselection of an item already in values
            let selectedPerimeters = [...values.slice(0,values.indexOf(checked)), ...values.slice(values.indexOf(checked)+1)]
            if (parent) {
                //if have a parent selected , deselect it and add others children
                const parents = findParentsInTree(checked)
                if (parents.length > 0) {
                    selectedPerimeters = deselectParents(parents, selectedPerimeters, checked)
                }
            }
            setValues(selectedPerimeters)
        }
    }

    const touched = () => {
        const [perimetersToAdd, perimetersToRemove] = compareSelection();
        return perimetersToAdd.length > 0 || perimetersToRemove.length > 0;
    }

    return (
        <>
            <p>Périmètres</p>
            <div>

                <PerimetersTreeHandling
                    id='listManagementTreeHandling'
                    acquiredOnly={true}
                    perimeterTree={props.adminRole}
                    origin={'ROT'}
                    onChange={onChange}
                    selectedPerimeter={values}
                    multiselect={true}
                    setperimeterTree={setPerimeterTree}
                />

                <FormGroup className={"mt-2 d-flex justify-content-end"}>
                    <Button disabled={!touched()} onClick={handleSubmit} color={"primary"}>Valider</Button>
                    <Button disabled={!touched()} onClick={resetSelection} color={"danger"}>Annuler</Button>
                </FormGroup>
            </div>
        </>
    )
}

export default PerimetersListManagement;