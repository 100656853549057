import React from 'react';
import PropTypes from 'prop-types'
import { Card, CardTitle, CardBody, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons";

const FormFundingCard = (props) => {
    let textColor = props.mode === "create" ? "text-success" : "text-info";

    return (
        <div className={"pt-lg-3"}>
            <Card color={"light"}>
                <CardHeader className={`pl-3 ${textColor}`}>
                    <div className={"d-flex justify-content-start align-items-center"}>
                        <div className={"p-3"}>
                            {
                                props.mode === "create"
                                    ?
                                    <>
                                        <CardTitle tag={"h3"}>
                                            <FontAwesomeIcon icon={faCoins} />&nbsp;
                                            Créer un financement
                                        </CardTitle>
                                    </>
                                    :
                                    <>

                                        <CardTitle tag={"h3"}>
                                            <FontAwesomeIcon icon={['fas', 'user-graduate']}/>&nbsp;
                                            Modifier {props.subtitle}
                                        </CardTitle>
                                    </>
                            }
                        </div>

                    </div>
                </CardHeader>

                <CardBody className={"p-3"}>
                    <CardBody className={"p-0 p-sm-3 mt-1"}>
                        {props.children}
                    </CardBody>
                </CardBody>

            </Card>
        </div>
    );
};

FormFundingCard.propTypes = {
    mode: PropTypes.oneOf([
        'create',
        'update'
    ]),
    subtitle: PropTypes.string,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
        'light',
        'dark',
        'white',
    ])
};

FormFundingCard.defaultProps = {
    mode: 'create',
    subtitle: '',
    color: 'white'
};

export default FormFundingCard;
