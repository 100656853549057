import React from "react";
import useNotification from "../lib/notification/useNotification";
import {Toast, ToastHeader, ToastBody} from 'reactstrap';
import {faCompass} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ToastNotification = () => {
    const { notifications, hideToast } = useNotification();
    const close = (key) => hideToast(key);

    return (
        <div className="notifications" >
            { Object.keys(notifications).map((key, index) => (
                <div className="d-flex flex-row justify-content-end mt-1" key={key}>
                    <Toast isOpen={!!notifications[key]} className="slide-from-left">
                        <ToastHeader toggle={ () => close(key) }
                                     className={`bg-${notifications[key].color ? notifications[key].color : 'info'} text-white`}>
                            { notifications[key].icon
                                ? <FontAwesomeIcon icon={notifications[key].icon}/>
                                : <FontAwesomeIcon icon={faCompass}/>
                            }
                            {' '}
                            { notifications[key].title
                                ? notifications[key].title
                                : ''
                            }
                        </ToastHeader>
                        <ToastBody className={`text-${notifications[key].color ? notifications[key].color : 'info'}`}>
                            { notifications[key].message
                                ? notifications[key].message
                                : ''
                            }
                        </ToastBody>
                    </Toast>
                </div>
            ))
            }
        </div>
    )
}

export default ToastNotification;
