import React from 'react';
import {FormGroup, Label} from "reactstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";

function TermsAccess({schema, edit, setTouched}) {
    return (
        <FormGroup>
            <Label>Conditions d'accès</Label>
            {edit
                ? <div>
                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            toolbar: ['heading', 'bold', 'italic', 'Link', 'numberedList', 'bulletedList']
                        }}
                        data={schema?.extras?.termsAccess}
                        onChange={(event, editor) => {
                            schema.extras.termsAccess = editor.getData();
                            setTouched(true);
                        }}
                    />
                </div>
                : <div dangerouslySetInnerHTML={{__html: schema?.extras?.termsAccess ?? 'Aucun'}}/>
            }
        </FormGroup>
    )
}

export default TermsAccess;