import React, {useState} from "react";
import FormConsentsProspect from "./FormConsentsProspect";
import {UPDATE_PROSPECT} from "../../graphql/mutations";
import {useMutation, useQuery} from "@apollo/client";
import NotificationToast from "../../lib/notification/NotificationToast";
import {faCheckCircle, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import useNotification from "../../lib/notification/useNotification";
import {SEARCH_PERIMETER_SETTINGS} from "../../graphql/queries";

function FormConsentProspectWrapper({
                                        prospect,
                                        className
                                    }) {
    const { showToast } = useNotification();
    const HttpStatus = require('http-status-codes');

    const [consents, setConsents] = useState(prospect?.gpdrConsents
        ? JSON.parse(prospect?.gpdrConsents) ?? {}
        : {});

    const [consentsSchemas, setConsentsSchemas] = useState({});
    useQuery(SEARCH_PERIMETER_SETTINGS, {
        variables: {
            idPerimeter: prospect.perimeter.id,
            filterSettings: 'consents'
        },
        onCompleted: function (data) {
            if (data?.PerimeterSettings) {
                let localConsentsSchemas = JSON.parse(data.PerimeterSettings.settings);
                setConsentsSchemas(localConsentsSchemas);

                Object.keys(localConsentsSchemas).forEach(function(consentId) {
                   if (consents && !consents.hasOwnProperty(consentId)) {
                       consents[consentId] = localConsentsSchemas[consentId]?.mandatory ?? false
                   }
                });
            }
        }
    });

    const [updateProspect, {loading: isLoadingUpdatingProspect}] = useMutation(
        UPDATE_PROSPECT,
        { onCompleted(data) {
                let responseCode = data['UpdateProspect']['responseCode'];
                if (responseCode === HttpStatus.OK) {
                    showToast(new NotificationToast("PROSPECT",
                        "Les préférences ont été mis à jour avec succès",
                        "success",
                        faCheckCircle));
                } else {
                    showToast(new NotificationToast("PROSPECT",
                        "Un problème est survenu lors de la mise à jour des préférences",
                        "danger",
                        faExclamationCircle));
                }
        }});

    const handleChangeConsent = (evt) => {
        const { name, checked } = evt.target;

        setConsents({
            ...consents,
            [name]: checked
        });
    }

    const submitConsents = () => {
        updateProspect({variables: {
            prospect: {'gpdrConsents': JSON.stringify(consents)},
            id: prospect.id
        }});
    }

    return <div className={className ?? 'p-1'}>
            <FormConsentsProspect
                consents={consents}
                handleChange={handleChangeConsent}
                loading={isLoadingUpdatingProspect}
                displayButton={true}
                validConsents={submitConsents}
                validConsentsText={'Enregistrer'}
                consentsSchemas={consentsSchemas}
            />
        </div>;
}

export default FormConsentProspectWrapper;