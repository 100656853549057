import React from "react";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Unites({schema, children}) {
    return <div className={'schema-unites'}>
        {children}
    </div>
}

export default Unites;