import {teachingModalities, temporalities} from "../../List/EnrolOptionListItem";

function extractUnites(elt) {
    let unites = [];

    if (!elt) {
        return unites;
    }

    switch (elt.type) {
        case 'unite': unites.push(elt); break;
        case 'schema':
        case 'ensemble':
        case 'branche': unites = unites.concat(elt.schema.map(extractUnites).flat()); break;
        case 'choix': unites = unites.concat(elt.choix.map(extractUnites).flat()); break;
        case 'unites': unites = unites.concat(elt.unites.map(extractUnites).flat()); break;
    }
    return unites;
}

function getPositioningCertificateContent(object, currentUser){
    let content = {};

    const unites = extractUnites(JSON.parse(object?.schema?.schema)).filter(u => !u.extras.obtained && u.extras.mode !== 'none');
    let schema = JSON.parse(object?.schema?.schema);
    unites.sort(function (a, b) {
        return a.code.localeCompare(b.code);
    });

    const birthPlace = p => {
        let place = '';
        if (p.birthTownshipName) {
            place = p.birthTownshipName;
            if (p.birthTownshipCode) {
                place += ` (${p.birthTownshipCode})`;
            }
            return place;
        }
        if (p.birthTownshipCode) {
            place = p.birthTownshipCode;
        }
        return place;
    }

    const yesNoNor = v => {
        if (v === null) {
            return '';
        }
        return v ? 'Oui' : 'Non';
    }

    /**
     * ==============================================================================
     * HEADER
     * ==============================================================================
     */
    content.header = {
        header: [
            "Attestation de positionnement éditée"
            + (currentUser ? " par " + currentUser?.fullName : "")
            + " le " + getTodayDate(),
            "Ref. : " + object.id
        ],
        region: object?.perimeter?.type === 'CRA' || object?.perimeter?.type === 'REG' || object?.perimeter?.parent?.id === 'EP'
            ? object?.perimeter?.label
            : object?.perimeter?.parent?.label
    };

    /**
     * ==============================================================================
     * BODY
     * ==============================================================================
     */
    content.body = {
        title: "Attestation de positionnement",
        sections: [
            {
                id: "rencontre",
                title: "Rencontre",
                subSections: [
                    {
                        subSectionId: "0-rencontre",
                        type: "simpleInfos",
                        content:
                            [getCreationInfos(object, object.prospect?.gender === "f")]
                                /*
                                .concat(
                                    object?.actionEntries ? getActionEntriesContent(object?.actionEntries) : []
                                )
                                 */
                                .concat(
                                    object?.updatedAt ? getUpdatedAtInfos(object) : []
                                )
                    }
                ]
            },
            {
                id: "demandeur",
                title: "Demandeur",
                subSections: [
                    {
                        subSectionId: "1-etatCivil",
                        subSectionTitle: "Etat civil",
                        type: "multiColumnInfos",
                        content: [
                            [{titleInfo: "Genre", data: object.prospect?.gender === "f" ? 'Femme' : 'Homme'}],
                            [
                                {titleInfo: "Nom d'usage", data: object?.prospect?.useName},
                                {titleInfo: "Nom de naissance", data: object?.prospect?.nameBirth}
                            ],
                            [
                                {titleInfo: "Prénom", data: object?.prospect?.firstName},
                                {titleInfo: "Autres prénoms", data: object?.prospect?.middleNames ? object?.prospect?.middleNames.replace(',', ', ') : ''},
                            ],
                            [
                                {titleInfo: "Date de naissance", data: object?.prospect?.birthDate !== null ? formatDate(object?.prospect?.birthDate) : ""},
                                {titleInfo: "Lieu de naissance", data: birthPlace(object?.prospect)}, //moreInfo: "(Code INSEE 13055)"
                            ],
                            [{titleInfo: "Nationalité", data: object?.prospect?.nationality?.nationalityFr}]
                        ]
                    },
                    {
                        subSectionId: "2-coordonnees",
                        subSectionTitle: "Coordonnées",
                        type: "multiColumnInfos",
                        content: [
                            [{
                                titleInfo: "Adresse postale",
                                data: [
                                    {
                                        content: object?.prospect?.homeAddress ?? ''
                                    },
                                    {
                                        content: (object?.prospect?.homePostCode ?? '') + " " + (object?.prospect?.homeTownshipName ?? '')
                                    }
                                ]
                            }],
                            [
                                {titleInfo: "Courriel", data: object?.prospect?.email ?? ''},
                                {titleInfo: "Téléphone", data: object?.prospect?.telephone ?? ''}
                            ]
                        ]
                    },
                    {
                        subSectionId: "3-situationProfessionnelle",
                        subSectionTitle: "Situation professionnelle",
                        type: "multiColumnInfos",
                        content: object?.prospect?.professionalStatus !== null
                        || object?.prospect?.professionalExperience !== null
                        || object?.prospect?.registeredAtEmploymentCenter !== null
                        || object?.prospect?.lookingForJob !== null
                        || object?.relatedProfessionalExperience !== null
                            ? (object?.prospect?.professionalStatus !== null
                                ? [[{
                                    titleInfo: "Statut professionnel",
                                    data: (object?.prospect?.professionalStatus?.id ?? '') + "-"
                                        + (object?.prospect?.professionalStatus?.label ??'')}]]
                                : [])
                                .concat(
                                    object?.prospect?.professionalExperience !== null
                                    || object?.prospect?.relatedProfessionalExperience !== null
                                        ? [[
                                            {
                                                titleInfo: "Expériences professionnelles",
                                                data: object?.prospect?.professionalExperience !== null
                                                    ? (object?.prospect?.professionalExperience?.label + ' ans')
                                                    : ''
                                            },
                                            {titleInfo: "Dans le domaine", data: object?.relatedProfessionalExperience?.label ?? ''}
                                        ]]
                                        : []
                                )
                                .concat(
                                    object?.prospect?.lookingForJob !== null
                                    || object?.relatedProfessionalExperience !== null
                                        ? [[
                                            {titleInfo: "En recherche d'emploi", data: yesNoNor(object?.prospect?.lookingForJob)},
                                            {titleInfo: "Inscrit à pôle emploi", data: yesNoNor(object?.prospect?.registeredAtEmploymentCenter)}
                                        ]]
                                        : []
                                )
                            : null
                    },
                    {
                        subSectionId: "4-niveauDiplome",
                        subSectionTitle: "Niveau de diplôme",
                        type: "multiColumnInfos",
                        content: object?.prospect?.highestDiplomaLevel
                            ? [
                                [{titleInfo: "Cadre Européen des Certifications", data: object?.prospect?.highestDiplomaLevel?.label ?? ''}]
                            ]
                            : null
                    }
                ]
            },
            {
                id: "projetFormation",
                title: "Projet de formation",
                subSections: [
                    {
                        subSectionId: "5-parcoursSouhaite",
                        subSectionTitle: "Parcours souhaité",
                        subSectionSubTitle: object?.cursusTargeted
                            ? (object?.cursusTargeted?.code ?? '')
                            + ' - ' + (object?.cursusTargeted?.intituleLong
                                ?? (object?.cursusTargeted?.intituleCourt ?? ''))
                            + ' (' + (schema?.credits ?? '--') + ' ECTS)'
                            + (schema?.extras.isAlternance === 1 ? ' (en Alternance)' : '')
                            : '',
                        type: "tableInfos",
                        content: unites.length ?
                            {
                                tableHead: [
                                    'Code',
                                    'Intitulé',
                                    'ECTS'
                                ],
                                sizing: [15, 77, 8],
                                tableBody: getTableInfosColumnsValues(
                                    unites,
                                    ['code', 'libelle', 'credits'],
                                    ['------', 'Matière en préparation pour ce parcours']) ?? []
                            }
                            : null
                    },
                    {
                        subSectionId: "11-termsAccess",
                        subSectionTitle: "Conditions d'accès à la formation",
                        type: "simpleInfos",
                        content: schema?.extras?.termsAccess
                            ? [schema.extras.termsAccess]
                            : null
                    },
                    {
                        subSectionId: "6-financementEnvisage",
                        subSectionTitle: "Financement envisagé",
                        type: "tableInfos",
                        content: unites.length ?
                            {
                                tableHead: [
                                    'Code',
                                    'Financement(s) envisagé(s)'
                                ],
                                sizing: [15, 85],
                                tableBody: getTableInfosColumnsValues(
                                    unites.map(u => {
                                        return {...u, extras: {...u.extras, funding: u.extras.funding.map(f => f.label).join(', ')}}
                                    }),
                                    ['code', 'extras.funding'],
                                    ['------', 'Individuel'],
                                ) ?? []
                            }
                            : null
                    },
                    {
                        subSectionId: "7-modeObtentionEnvisage",
                        subSectionTitle: "Mode d'obtention envisagé",
                        type: "tableInfos",
                        content: unites.length ?
                            {
                                tableHead: [
                                    'Code',
                                    'Mode d\'obtention envisagé'
                                ],
                                sizing: [15, 85],
                                tableBody: getTableInfosColumnsValues(
                                    unites.map(u => {
                                        let mode;
                                        switch (u.extras.mode) {
                                            case 'ens': mode = 'En formation'; break;
                                            case 'ves': mode = 'VES'; break;
                                            case 'vae': mode = 'VAE'; break;
                                        }
                                        return {...u, extras: {...u.extras, mode: mode}}
                                    }) ?? [],
                                    ['code', 'extras.mode'],
                                    ['------', 'En formation'],
                                )
                            }
                            : null
                    },
                    {
                        subSectionId: "8-calendrierPrevisionnel",
                        subSectionTitle: "Calendrier Prévisionnel",
                        subSectionMention: "Le calendrier présenté ci-dessous est à titre indicatif. Celui-ci n'engage aucunement les centres Cnam organisateurs.",
                        type: "tableInfos",
                        content: unites.length ?
                            {
                                tableHead: [
                                    'Code',
                                    'Calendrier Prévisionnel'
                                ],
                                sizing: [15, 85],
                                tableBody: getTableInfosColumnsValues(
                                    unites.map(u => {
                                        let options = [];
                                        if (u.extras.mode === 'ens' && u.extras.options.length > 0) {
                                            options = u.extras.options.map(opt => {
                                                const {perimeter, teachingModality, temporality, year} = opt;
                                                return `${year}/${year + 1} - ${temporalities[temporality]} - ${teachingModalities[teachingModality]} - ${perimeter.label}`;
                                            }).join(' | ')
                                        } else if (u.extras.year) {
                                            options = `${u.extras.year}/${u.extras.year+1}`;
                                        }
                                        return {...u, extras: {...u.extras, options: options}};
                                    }),
                                    ['code', 'extras.options'],
                                    ['------', '____/____'],
                                )
                            }
                            : null
                    },
                    {
                        subSectionId: "10-conclusions",
                        subSectionTitle: "Conclusions",
                        type: "simpleInfos",
                        content: object?.conclusion !== null
                            ? [object?.conclusion]
                            : null
                    }
                ]
            }
        ]
    };

    /**
     * ==============================================================================
     * FOOTER
     * ==============================================================================
     */
    content.footer = {
        content: [
            "Les informations recueillies par le Cnam font l'objet d'un traitement informatique destiné à la " +
            "gestion des projets de formations et à établir des données statistiques sur les activités " +
            "d'accompagnements. Conformément aux articles 39 et suivants de la loi Informatique et " +
            "Libertés du 6 janvier 1978 modifiée, vous bénéficiez d'un droit d'accès, d'opposition et de " +
            "rectification aux informations qui vous concernent, que vous pouvez exercer en vous adressant à la " +
            "Direction Nationale des Formations par courriel à dnfmaster@cnam.fr ou en envoyant un courrier postal" +
            " à Direction Nationale des Formations 292 rue Saint-Martin - Case courrier DNF001 - 75003 Paris."
        ]
    };
    return content;
}

function getTodayDate() {
    let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date().toLocaleDateString('fr-FR', options).split(',').join(' à');
}

function formatDate(string, withTime = false) {
    let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    let localDate = new Date(string).toLocaleDateString('fr-FR', options);
    return withTime
        ? localDate.split(',').join(' à')
        : localDate.split(',')[0];
}

function getCreationInfos(object, isWoman = false) {
    return '<p>Accueilli' + (isWoman ? 'e' : '')
        + (object?.creator ? ' par ' + object?.creator?.fullName : '')
        + (object?.perimeter ? ' du ' + object?.perimeter?.label : '')
        + (object?.createdAt ? ', le ' + formatDate(object?.createdAt) : '')
        + '.</p>';
}

function getUpdatedAtInfos(object){
    if (!object?.actionEntries.length) return [];
    let lastUpdate = object?.actionEntries[object?.actionEntries.length - 1];
    return ['<p>Dernière modificaton du projet de formation'
        + (lastUpdate?.actor ? ' par ' + lastUpdate?.actor?.fullName : '')
        + (lastUpdate?.date ? ', le ' + formatDate(lastUpdate?.date) : '')
        + '.</p>'];
}

function getActionEntriesContent(actionEntries = []) {
    if (!actionEntries.length) return [];

    let stringContent = '';
    let content = [];
    actionEntries.map((actionEntry) => {
        switch (actionEntry?.label) {
            case 'Ouvrir':
                stringContent = 'Ouvert';
                if (actionEntry?.actor){
                    stringContent += ' par ' + actionEntry?.actor?.fullName ?? '---';
                }
                if (actionEntry?.date){
                    stringContent += ", le " + formatDate(actionEntry?.date);
                }
                content.push(stringContent + '.');
                break;
            default:
                break;
        }
    })
    return content;
}

function getTableInfosColumnsValues(
    data = null,
    askedInfosKey = null,
    defaultValues = []
) {
    return getTableInfosColumns(data, askedInfosKey, defaultValues);
}

function getTableInfosColumns(
    data,
    askedInfosKey,
    defaultValues)
{
    if (!Array.isArray(askedInfosKey)) return null;
    if (!Array.isArray(data)) {
        return askedInfosKey.map((key, index) => {
            let explodedKeys = key.split('.');
            let firstKey = explodedKeys.shift();
            if (Array.isArray(data[firstKey])){
                return getTableInfosColumns(data[firstKey], [explodedKeys.join('.')], [defaultValues[index]])[0];
            }
            return data[firstKey];
        });
    }
    if (!data.length) return [defaultValues[0] ?? 'Non renseigné'];
    return askedInfosKey?.map((key, index) => {
        let explodedKeys = key.split('.');
        if (explodedKeys.length > 1) {
            return data.map((singleData) => {
                let explodedKeys = key.split('.');
                let firstKey = explodedKeys.shift();
                return getTableInfosColumns(singleData[firstKey], [explodedKeys.join('.')], [defaultValues[index]]);
            });
        } else {
            return data.map((singleData) => [singleData[key] ?? ' ']);
        }
    });
}

export default getPositioningCertificateContent;
