import React from "react";
import {Button, FormGroup, Input, Label} from "reactstrap";
import {useLazyQuery, useQuery} from "@apollo/client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheckCircle, faExclamationCircle, faInfoCircle, faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
    GET_ADDRESSES,
    GET_CITIES,
    QUERY_COUNTRIES_CODE,
    QUERY_EQF_LEVELS,
    QUERY_PROFESSIONAL_EXPERIENCES,
    QUERY_PROFESSIONAL_STATUS
} from "../../graphql/queries";
import FormSearchWithLazyQuery from "./FormSearchWithLazyQuery";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import LoadingSpinner from "../Default/LoadingSpinner";

function FormProspect({
                          errors,
                          handleBlur,
                          handleChange,
                          setRequiredFieldValidated,
                          setValues,
                          handleSubmit,
                          touched,
                          values,
                          mode,
                          full = true,
                          valueFocussed,
                          requiredFieldsValidated
                      }) {
    setRequiredFieldValidated(); // check if the form is OK every time states changing
    const isCreating = mode === "create" || mode === "complete_enf";
    let textSubmit = isCreating ? "CRÉER" : "";
    let colorSubmitReady = isCreating ? "success" : "";

    let requiredFieldsInfo = [
        {text: "Les champs * sont obligatoires"},
        {text: "Les champs ** doivent avoir au moins un des champs renseignés"}
    ];

    const getCities = useLazyQuery(GET_CITIES);
    const getAddresses = useLazyQuery(GET_ADDRESSES);

    const onChangeUcFirst = event => {
        event.target.value = event.target.value.toLowerCase().replace(
            /(?<=[ \-,])[^\s]|^./g,
            a => a.toUpperCase()
        );
        handleChange(event);
    }

    const onChangeUppercase = event => {
        event.target.value = event.target.value.toUpperCase();
        handleChange(event);
    }

    const renderCities = function(c, value) {
        return c.postalCodes.length > 1
            ? c.postalCodes.includes(value)
                ?`${c.name} (${c.postalCodes[c.postalCodes.indexOf(value)]})`
                : <>
                    <div>{c.name}</div>
                    {c.postalCodes.map(postCode => <Button size={'sm'} color={'secondary'} onClick={() => {onSelectCities(c, postCode)}}>{postCode}</Button>)}
                </>
            : `${c.name} (${c.postalCodes[0]})`;
    }

    const onSelectCities = function(c, value) {
        c.postalCodes.length > 1
        ? c.postalCodes.includes(value) && setValues({
            ...values,
            ['homePostCode']: c.postalCodes[c.postalCodes.indexOf(value)],
            ['homeTownshipName']: c.name,
        })
        :setValues({
            ...values,
            ['homePostCode']: c.postalCodes[0],
            ['homeTownshipName']: c.name,
        });
    }

    const localLanguageFormat = new Intl.DateTimeFormat(
            (new Intl.DateTimeFormat()).resolvedOptions().locale
        )
        .formatToParts(new Date()).map(function(partDate) {
            switch (partDate.type) {
                case "day":
                    return 'dd';
                case "month":
                    return 'mm';
                case "year":
                    return 'yyyy';
                default:
                    return partDate.value;
            }
        })
        .join('')
        .replaceAll(/[\.\-]/g, '/');

    return (
        <form onSubmit={handleSubmit} autoComplete="off">

            <FormGroup check className={"pl-0"}>
                <div>
                    <div className={"pl-0 pr-1 pt-1 pb-1"}>
                        Genre *
                        <ValidationMarker touched={touched}
                                          errors={errors}
                                          valueFocussed={valueFocussed}
                                          values={values}
                                          name={"gender"}/>
                    </div>
                    <div className="row mb-2">
                        <div className={"ml-3 radio"}>
                            <label>
                                <input type="radio"
                                       name="gender"
                                       value="h"
                                       checked={values.gender === "h"}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                />{' '}Homme
                            </label>
                        </div>
                        <div className={"ml-2 radio"}>
                            <label>
                                <input type="radio"
                                       name="gender"
                                       value="f"
                                       checked={values.gender === "f"}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                />{' '}Femme
                            </label>
                        </div>
                    </div>
                </div>
            </FormGroup>

            <FormGroup>
                <Label for="prospectUseName">
                    Nom d'usage *
                    <ValidationMarker touched={touched}
                                      errors={errors}
                                      valueFocussed={valueFocussed}
                                      values={values}
                                      name={"useName"}/>
                </Label>
                <Input type="text"
                       name="useName"
                       id="prospectUseName"
                       placeholder="Saisissez un nom d'usage"
                       value={values.useName}
                       onChange={onChangeUppercase}
                       onBlur={handleBlur}/>
            </FormGroup>

            {full
                ? <FormGroup>
                    <Label for="prospectBirthName">
                        Nom de naissance
                        <ValidationMarker touched={touched}
                                          errors={errors}
                                          valueFocussed={valueFocussed}
                                          values={values}
                                          name={"nameBirth"}/></Label>
                    <Input type="text"
                           name="nameBirth"
                           id="prospectBirthName"
                           className="form-control"
                           placeholder="Saisissez un nom de naissance"
                           value={values.nameBirth}
                           onChange={onChangeUppercase}
                           onBlur={handleBlur}
                    />
                </FormGroup>
                : ""
            }

            <FormGroup>
                <Label for="prospectFirstName">
                    Prénom *
                    <ValidationMarker touched={touched}
                                      errors={errors}
                                      valueFocussed={valueFocussed}
                                      values={values}
                                      name={"firstName"}/>
                </Label>
                <Input type="text"
                       name="firstName"
                       id="prospectFirstName"
                       placeholder="Saisissez un prénom"
                       value={values.firstName}
                       onChange={onChangeUcFirst}
                       onBlur={handleBlur}/>
            </FormGroup>

            {
                full
                    ? <FormGroup>
                        <Label for="prospectMiddleNames">
                            Autre(s) prénom(s)
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"middleNames"}/>
                        </Label>
                        <Input type="text"
                               name="middleNames"
                               id="prospectMiddleNames"
                               placeholder="Saisissez les autres prénoms (séparés par une virgule sans espaces)"
                               value={values.middleNames}
                               onChange={onChangeUcFirst}
                               onBlur={handleBlur}/>
                    </FormGroup>
                    : ""
            }

            <FormGroup>
                <Label for="prospectBirthDate">
                    Date de naissance **
                    <ValidationMarker touched={touched}
                                      errors={errors}
                                      valueFocussed={valueFocussed}
                                      values={values}
                                      name={"birthDate"}/>
                </Label>
                <Input type="date"
                       name="birthDate"
                       id="prospectBirthDate"
                       value={values.birthDate}
                       onChange={handleChange}
                       onBlur={handleBlur}/>
                {
                    localLanguageFormat !== 'dd/mm/yyyy'
                    ? <small id="birthDateHelp" className="form-text text-muted">
                        Le format de saisie de la date est <b>"{localLanguageFormat}"</b> qui correspond au paramètre
                        de langue de votre navigateur <b>"{window.navigator.language}"</b>.
                    </small>
                    : ''
                }

            </FormGroup>

            <FormGroup>
                <Label for="prospectEmail">
                    Email **
                    <ValidationMarker touched={touched}
                                      errors={errors}
                                      valueFocussed={valueFocussed}
                                      values={values}
                                      name={"email"}/>
                </Label>
                <Input type="email"
                       name="email"
                       id="prospectEmail"
                       placeholder="Saisissez une adresse email (i.e. prospect@email.com)"
                       value={values.email}
                       onChange={handleChange}
                       onBlur={handleBlur}/>
            </FormGroup>

            {
                full
                    ? <FormGroup>
                        <Label for="prospectTelephone">
                            Telephone
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"telephone"}/>
                        </Label>
                        <Input type="tel"
                               name="telephone"
                               id="prospectTelephone"
                               placeholder="Saisissez un numéro de téléphone (i.e. 06 07 08 09 10)"
                               value={values.telephone}
                               onChange={handleChange}
                               onBlur={handleBlur}/>
                    </FormGroup>
                    : ""
            }

            {
                full
                    ? <FormGroup>
                        <Label for="prospectBirthCountryCode">
                            Pays de naissance
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"birthCountryCode"}/>
                        </Label>
                        <InputCountriesCode value={values?.birthCountryCode}
                                            inputName={"birthCountryCode"}
                                            inputId={"prospectBirthCountryCode"}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}/>
                    </FormGroup>
                    : ""
            }

            {
                full && values?.birthCountryCode !== 'FRA'
                    ? <FormGroup>
                        <Label for="prospectBirthTownshipName">
                            Commune de naissance
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"birthTownshipName"}/>
                        </Label>
                        <Input type="text"
                               name="birthTownshipName"
                               id="prospectBirthTownshipName"
                               placeholder="Saisissez la commune de naissance (i.e. Paris)"
                               value={values.birthTownshipName}
                               onChange={handleChange}
                               onBlur={handleBlur}/>
                    </FormGroup>
                    : ""
            }

            {
                full
                    ? <FormGroup>
                        <Label for="prospectBirthTownshipCode">
                            Code {values?.birthCountryCode === 'FRA' ? "INSEE" : "postal"} de naissance
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"birthTownshipCode"}/>
                        </Label>
                        {
                            values?.birthCountryCode === 'FRA' &&
                            <FormSearchWithLazyQuery searchVariableName={'name'}
                                                     variables={{includeDeprecated: true}}
                                                     placeholder={'Rechercher une ville par son nom'}
                                                     lazyQuery={getCities}
                                                     renderItem={c => `${c.name} (${c.departmentCode})`}
                                                     minLength={2}
                                                     onSelect={c => {
                                                         setValues({
                                                             ...values,
                                                             ['birthTownshipCode']: c.code,
                                                             ['birthTownshipName']: c.name,
                                                         })
                                                     }}
                            />
                        }
                        <Input type="text"
                               name="birthTownshipCode"
                               id="prospectBirthTownshipCode"
                               placeholder={`Saisissez un code ${values?.birthCountryCode === 'FRA' ? "INSEE" : "postal"} de naissance (i.e. 75020)`}
                               value={values.birthTownshipCode}
                               onChange={handleChange}
                               onBlur={handleBlur}/>
                    </FormGroup>
                    : ""
            }

            {
                full
                    ? <FormGroup>
                        <Label for="prospectNationality">
                            Nationalité
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"nationality"}/>
                        </Label>
                        <InputCountriesCode value={values?.nationality}
                                            inputName={"nationality"}
                                            inputId={"prospectNationality"}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}/>
                    </FormGroup>
                    : ""
            }

            <FormGroup>
                <Label for="prospectHomeCountryCode">
                    Pays de résidence *
                    <ValidationMarker touched={touched}
                                      errors={errors}
                                      valueFocussed={valueFocussed}
                                      values={values}
                                      name={"homeCountryCode"}/>
                </Label>
                <InputCountriesCode value={values?.homeCountryCode}
                                    inputName={"homeCountryCode"}
                                    inputId={"prospectHomeCountryCode"}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}/>
            </FormGroup>

            {
                full
                    ? <FormGroup>
                        <Label for="prospectHomeAddress">
                            Numéro et nom de voie
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"homeAddress"}/>
                        </Label>
                        {
                            values?.homeCountryCode === 'FRA' &&
                            <FormSearchWithLazyQuery searchVariableName={'query'}
                                                     placeholder={'Rechercher une adresse'}
                                                     lazyQuery={getAddresses}
                                                     renderItem={a => a.label}
                                                     minLength={4}
                                                     onSelect={a => {
                                                         setValues({
                                                             ...values,
                                                             ['homeAddress']: a.name,
                                                             ['homeTownshipName']: a.city.name,
                                                             ['homePostCode']: a.city.postalCode,
                                                         })
                                                     }}
                            />
                        }
                        <Input type="text"
                               name="homeAddress"
                               id="prospectHomeAddress"
                               placeholder="Saisissez un numéro et nom de voie (i.e. 77 rue de Belleville)"
                               value={values.homeAddress}
                               onChange={handleChange}
                               onBlur={handleBlur}/>
                    </FormGroup>
                    : ""
            }

            {
                full
                    ? <FormGroup>
                        <Label for="prospectHomeAddressAdditional">Complément d’adresse</Label>
                        <Input type="text"
                               name="homeAddressAdditional"
                               id="prospectHomeAddressAdditional"
                               placeholder="Saisissez un complément d'adresse (i.e. Batiment B)"
                               value={values.homeAddressAdditional}
                               onChange={handleChange}
                               onBlur={handleBlur}/>
                    </FormGroup>
                    : ""
            }

            {
                <FormGroup>
                    <Label for="prospectHomeTownshipName">
                        Commune de résidence *
                        <ValidationMarker touched={touched}
                                          errors={errors}
                                          valueFocussed={valueFocussed}
                                          values={values}
                                          name={"homeTownshipName"}/>
                    </Label>
                    {
                        values?.homeCountryCode === 'FRA' &&
                        <FormSearchWithLazyQuery searchVariableName={'postalCode'}
                                                 placeholder={'Rechercher une ville par son code postal'}
                                                 lazyQuery={getCities}
                                                 renderItem={renderCities}
                                                 minLength={5}
                                                 zIndexStart={103}
                                                 onSelect={onSelectCities}
                        />
                    }
                    <Input type="text"
                           name="homeTownshipName"
                           id="prospectHomeTownshipName"
                           placeholder="Saisissez la commune de résidence (i.e. Paris)"
                           value={values.homeTownshipName}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
                </FormGroup>
                // )
            }

            {/* Special case : When home country is France, the post code is required */}
            {
                (values?.homeCountryCode === 'FRA' &&
                    <FormGroup>
                        <Label for="prospectHomePostCode">
                            Code postal *
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"homePostCode"}/>
                        </Label>
                        <FormSearchWithLazyQuery searchVariableName={'name'}
                                                           placeholder={'Rechercher une ville par son nom'}
                                                           lazyQuery={getCities}
                                                           renderItem={renderCities}
                                                           minLength={2}
                                                           onSelect={onSelectCities}
                                                           zIndexStart={103}
                        />
                        <Input type="text"
                               name="homePostCode"
                               id="prospectHomePostCode"
                               placeholder="Saisissez un code postal de résidence (i.e. 75003)"
                               value={values.homePostCode}
                               onChange={handleChange}
                               onBlur={handleBlur}/>
                    </FormGroup>) ||
                full && <FormGroup>
                    <Label for="prospectHomePostCode">
                        Code postal
                        <ValidationMarker touched={touched}
                                          errors={errors}
                                          valueFocussed={valueFocussed}
                                          values={values}
                                          name={"homePostCode"}/>
                    </Label>
                    <Input type="text"
                           name="homePostCode"
                           id="prospectHomePostCode"
                           placeholder="Saisissez un code postal de résidence (i.e. 75003)"
                           value={values.homePostCode}
                           onChange={handleChange}
                           onBlur={handleBlur}/>
                </FormGroup>
            }

            {
                full
                    ?
                    <FormGroup>
                        <Label for="prospectHighestDiplomaLevel">
                            Plus haut niveau de diplôme{' '}
                            <OverlayTrigger
                                placement="right"
                                delay={{show: 250, hide: 400}}
                                overlay={renderTooltip("ttDiplomeID", "Cadre Européen des Certifications")}>
                                <FontAwesomeIcon icon={faInfoCircle} className="text-info"/>
                            </OverlayTrigger>
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"highestDiplomaLevel"}/>
                        </Label>
                        <InputEQFLevels values={values}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}/>
                    </FormGroup>
                    : ""
            }

            {
                full
                    ? <div className="d-flex w-100">
                        <FormGroup className="col-6 p-0 pr-1">
                            <Label for="prospectProfessionalExperience">
                                Expérience professionnelle
                                <ValidationMarker touched={touched}
                                                  errors={errors}
                                                  valueFocussed={valueFocussed}
                                                  values={values}
                                                  name={"professionalExperience"}/>
                            </Label>
                            <InputProfessionalExperiences id={"prospectProfessionalExperience"}
                                                          name={"professionalExperience"}
                                                          values={values}
                                                          handleChange={handleChange}
                                                          handleBlur={handleBlur}/>
                        </FormGroup>
                        <FormGroup className="col-6 p-0">
                            <Label for="projectRelatedProfessionalExperienceID">
                                Dans le domaine
                                <ValidationMarker touched={touched}
                                                  errors={errors}
                                                  valueFocussed={valueFocussed}
                                                  values={values}
                                                  name={"projectRelatedProfessionalExperience"}/>
                            </Label>
                            <InputProfessionalExperiences id={"projectRelatedProfessionalExperienceID"}
                                                          name={"projectRelatedProfessionalExperience"}
                                                          values={values}
                                                          handleChange={handleChange}
                                                          handleBlur={handleBlur}/>
                        </FormGroup>
                    </div>
                    : ""
            }

            {
                full
                    ? <FormGroup>
                        <Label for="prospectProfessionalStatus">
                            Statut face à l'emploi
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"professionalStatus"}/>
                        </Label>
                        <InputProfessionalStatus values={values}
                                                 handleChange={handleChange}
                                                 handleBlur={handleBlur}/>
                    </FormGroup>
                    : ""
            }

            {
                full
                    ? <FormGroup>
                        <Label>
                            A la recherche d'un emploi
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"lookingForJob"}/>
                        </Label>
                        <Input
                            type="select"
                            name="lookingForJob"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lookingForJob}
                        >
                            <option value=''>Non précisé</option>
                            <option value={true}>Oui</option>
                            <option value={false}>Non</option>
                        </Input>
                    </FormGroup>
                    : ""
            }

            {
                full
                    ? <FormGroup>
                        <Label>
                            Inscrit à Pôle emploi
                            <ValidationMarker touched={touched}
                                              errors={errors}
                                              valueFocussed={valueFocussed}
                                              values={values}
                                              name={"registeredAtEmploymentCenter"}/>
                        </Label>
                        <Input
                            type="select"
                            name="registeredAtEmploymentCenter"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.registeredAtEmploymentCenter}
                        >
                            <option value=''>Non précisé</option>
                            <option value={true}>Oui</option>
                            <option value={false}>Non</option>
                        </Input>
                    </FormGroup>
                    : ""
            }

            <div className={"row justify-content-around align-items-center pt-3 pt-lg-5"}>
                <div className={"col-12 col-md-6"}>
                    {requiredFieldsInfo.map(({text}, index) => (
                        <small key={index} className={"row pl-3"}>{text}</small>
                    ))}
                </div>
                <div className={"col-12 col-md-6"}>
                    {
                        Object.values(errors).length > 0
                            ? <button className={"btn btn-outline-warning btn-block disabled"}>
                                <em>Le formulaire est incomplet</em>
                            </button>
                            : requiredFieldsValidated
                            ?
                            isCreating ?
                                <button className={`btn btn-lg btn-${colorSubmitReady} btn-block`} type="submit">
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                    <span> {textSubmit}</span>
                                </button> : ''
                            : isCreating ?
                                <button className="btn btn-outline-secondary btn-block" type="submit">

                                    <FontAwesomeIcon icon={faPlusCircle} size="sm"/>

                                    <em> {textSubmit}</em>
                                </button> : ''
                    }

                </div>
            </div>

        </form>
    )
}

function InputCountriesCode({value, inputName, inputId, handleChange, handleBlur}) {
    const {loading, data} = useQuery(QUERY_COUNTRIES_CODE);
    if (loading) {
        return <LoadingSpinner/>;
    }
    return <Input
        type="select"
        name={inputName}
        id={inputId}
        multiple={false}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}>
        <option value="">-- Veuillez sélectionner --</option>
        <optgroup label={'Accès rapide'}>
            <option value="FRA">{inputName === "nationality" ? "Française" : "France"}</option>
        </optgroup>
        <optgroup label={'Tous les pays'}>
            { data
                ? data['Countries'].map(({iso3Code, labelFr, nationalityFr}) => (
                    <option key={iso3Code} value={iso3Code}>
                        {inputName === "nationality" ? nationalityFr : labelFr}
                    </option>
                ))
                : ""
            }
        </optgroup>
    </Input>;
}

function InputProfessionalStatus({values, handleChange, handleBlur}) {
    const {loading, data} = useQuery(QUERY_PROFESSIONAL_STATUS, {variables: {isAscending: true}});
    if (loading) {
        return <LoadingSpinner/>;
    }
    return <Input
        type="select"
        name="professionalStatus"
        id="prospectProfessionalStatus"
        multiple={false}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values?.professionalStatus}>
        <option value="">-- Veuillez sélectionner --</option>
        { data
            ? data['ProfessionalStatus'].map(({id, label}) => (
                <option key={id} value={id} style={{width: "auto"}}>{label}</option>
            ))
            : ""
        }
    </Input>;
}


function InputEQFLevels({values, handleChange, handleBlur}) {
    const {loading, data} = useQuery(QUERY_EQF_LEVELS, {variables: {isAscending: true}});
    if (loading) {
        return <LoadingSpinner/>;
    }
    return <Input
        type="select"
        name="highestDiplomaLevel"
        id="prospectHighestDiplomaLevel"
        multiple={false}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.highestDiplomaLevel}>
        <option value="">-- Veuillez sélectionner --</option>
        { data
            ? data['EQFLevels'].map(({id, label}) => (
                <option key={id} value={id}>{label}</option>
            ))
            : ""
        }
    </Input>;
}

function InputProfessionalExperiences({id, name, values, handleChange, handleBlur}) {
    const {loading, data} = useQuery(QUERY_PROFESSIONAL_EXPERIENCES);
    if (loading) {
        return <LoadingSpinner/>;
    }
    return <Input
        type="select"
        name={name}
        id={id}
        multiple={false}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]}>
        <option value="">Non précisé</option>
        { data
            ? data['ProfessionalExperiences'].map(({id, label}) => (
                <option key={id} value={id}>{label}</option>
            ))
            : ""
        }
    </Input>;
}

const ValidationMarker = ({touched, errors, valueFocussed, values, name}) => {
    if (valueFocussed !== name && values[name] !== undefined) {
        let isEmpty = values[name] === null ? true : !values[name].length > 0;
        if (touched[name] && !errors[name] && !isEmpty) {
            return <FontAwesomeIcon className="ml-2 text-success" icon={faCheckCircle}/>
        } else if (touched[name] && errors[name]) {
            return (
                <small className="ml-2 text-warning">
                    <FontAwesomeIcon icon={faExclamationCircle}/> <em>{errors[name]}</em>
                </small>
            )
        }
    }
    return '';
}

const renderTooltip = (id, text) => (
    <Tooltip id={id}>{text}</Tooltip>
);

export default FormProspect;
