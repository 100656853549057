import React, {useContext} from 'react';
import PropTypes from 'prop-types'
import {CardTitle, CardSubtitle, CardText, CardBody, Nav, NavItem, NavLink as BSNavLink} from 'reactstrap';
import Avatar from "react-avatar";
import { useQuery } from '@apollo/client';
import { USER_PROFILE_QUERY } from '../../graphql/queries';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserContext from "../../contexts/UserContext";
import {faLifeRing, faSpinner} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {NavLink} from "react-router-dom";
import auth from "../../lib/security/auth";

// Todo : Set correct path to administration page (Route, etc)
const navItems = [
    {to: '/admin', name: 'administration', active: '', exact: false, fa: ['fas', 'user-shield']},
    {to: '/help', name: 'aide', active: '', exact: false, fa: faLifeRing},
];

const UserPopoverCard = () => {

    const {currentUser, onLogout} = useContext(UserContext);

    const { client, loading, error, data } = useQuery(USER_PROFILE_QUERY, {
        variables: { userId: currentUser?.id ? currentUser.id : 0 },
    });

    if (currentUser !== null) {
        if (loading) {
            return (
                <h3><FontAwesomeIcon icon={faSpinner} color={UserPopoverCard.defaultProps.userColor} spin/></h3>
            )
        }
        if (error) {
            return (
                <h6>Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></h6>
            )
        }
    }

    // User not connected
    if (data === undefined || data.Users.length === 0) {
        return '';
    }

    const user = data.Users[0];

    const getRoleColor = (roleKey, isHex) => {
        if (isHex) {
            return getComputedStyle(document.documentElement)
                .getPropertyValue('--color-'+roleKey);
        }
        switch(roleKey) {
            case 'ROLE_ADMIN':
                return 'text-primary';
            case 'ROLE_CHARGE_AIOA_IP':
                return 'text-info';
            case 'ROLE_LECTEUR':
                return 'text-secondary';
            default:
                return 'text-info';
        }
    }

    const rolesDisplay = user.localRoles
        ? user.localRoles.map(({id, role, perimetres}, index, roles) => (
            <div key={id} className="mt-3">
                <h6 className={getRoleColor(role.id, false)}>
                    <em>{role.label}</em>
                </h6>
            </div>
        ))
        : <span className="text-muted">Aucun role</span>;

    const isAdministrator = user.localRoles.some(item => item.role.id === 'ROLE_ADMIN');
    if (!isAdministrator) {
        navItems.shift();
    }

    const userFirstRoleId = user.localRoles[0].role.id;

    const popover = (
        <Popover id="popoverUser">
            <Popover.Content className="m-1">
                    <CardBody className="text-center p-1">
                        <CardTitle>
                            <Avatar name={user.fullName}
                                    size="50"
                                    textSizeRatio={3}
                                    round={true}
                                    color={getRoleColor(userFirstRoleId, true)}/>
                        </CardTitle>
                        <CardSubtitle>
                            <h6>{user.fullName}</h6>
                        </CardSubtitle>
                        <CardText>
                            {rolesDisplay}
                        </CardText>
                    </CardBody>

                    <hr className="pt-0 mt-1 pb-0 mb-1"/>

                    <Nav vertical className="align-items-start pl-1">
                        {navItems.map(({to, name, active, exact, fa}, index) => (
                            <NavItem key={index}>
                                <BSNavLink
                                    id={`userItem-${name}-${index}`}
                                    className={`pl-1 text-muted text-uppercase ${active}`}
                                    tag={NavLink}
                                    to={to}
                                    activeClassName={active}
                                    exact={exact}>
                                    <FontAwesomeIcon icon={fa} fixedWidth className={"mr-1"}/>
                                    <span className="">{name}</span>
                                </BSNavLink>
                            </NavItem>
                        ))}
                        <NavItem key={100}>
                            <BSNavLink
                                to='/'
                                className={`pl-1 text-muted text-uppercase`}
                                tag={NavLink}
                                onClick={() => {
                                    auth.unsetToken();
                                    onLogout();
                                    return client.clearStore();
                                }}>
                                <FontAwesomeIcon icon={['fas', 'sign-out-alt']} fixedWidth
                                                 className={"mr-1"}/>
                                <span className="">Déconnexion</span>
                            </BSNavLink>
                        </NavItem>
                    </Nav>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
            <Avatar name={user?.fullName}
                    size="40"
                    round={true}
                    textSizeRatio={3}
                    color={getRoleColor(userFirstRoleId, true)}
                    style={{cursor: "pointer"}}
            />
        </OverlayTrigger>
    );
};

UserPopoverCard.propTypes = {
    userName: PropTypes.string,
    userFirstName: PropTypes.string,
    userLastName: PropTypes.string,
    userEmail: PropTypes.string,
    userColor: PropTypes.string
};

UserPopoverCard.defaultProps = {
    userName: "Billy Bobby",
    userFirstName: "Billy",
    userLastName: "Bobby",
    userEmail: "billybobby@gmail.com",
    userColor: "#7986CB",
    userRole: "CHARGER_AIOIP"
};

export default UserPopoverCard;
