import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretRight, faCheckSquare} from "@fortawesome/free-solid-svg-icons";
import {faSquare, faMinusSquare} from "@fortawesome/free-regular-svg-icons";
import React, {useImperativeHandle, useRef, useState} from "react";
import PerimetersTree from "./PerimetersTree";

function PerimeterTreeElement({perimeter, onChange, checked, selectedPerimeter, selectablePerimeters, showAcquired, multiselect},ref) {
    const [opened, setOpened] = useState(perimeter.id === 'root');
    const flattenChildren = (perimeter) => {
        if (perimeter.children === null) {
            return [perimeter.id];
        }
        let flattenedChildren = [];
        flattenedChildren.push(perimeter.id);
        perimeter.children.forEach(p => {
            flattenedChildren = flattenedChildren.concat(flattenChildren(p));
        });
        return flattenedChildren;
    }
    const flattenedChildren = flattenChildren(perimeter);

    const childrenSelect = (selectedPerimeter) =>{
        if(selectedPerimeter){
            if(multiselect){
                return flattenedChildren.some((val) => selectedPerimeter.indexOf(val) !== -1);

            }
            else {
                return flattenedChildren.includes(selectedPerimeter)
            }
        }
    }
    const checkedFormattinf=()=>{
        let condition
        if(multiselect)
            condition = selectedPerimeter?.includes(perimeter.id)
        else{
            condition = selectedPerimeter?.value === perimeter.id
        }

        return checked || condition
    }
    const childrenSelected = childrenSelect(selectedPerimeter);
    const isChecked = checkedFormattinf();
    const isDisabled = !selectablePerimeters.includes(perimeter.id);
    return <div>
        <div className={'d-flex'}>
            <label
                data-id={perimeter?.id}
                onClick={selectablePerimeters.includes(perimeter.id)
                    ? onChange
                    : () => setOpened(!opened)
                }
                className={`my-auto ${isDisabled ? 'text-black-50' : ''}`}
            >
                <FontAwesomeIcon
                    icon={isChecked
                        ? faCheckSquare
                        : childrenSelected
                            ? faMinusSquare
                            : faSquare}
                    type={'checkbox'}
                    checked={isChecked}
                    className={`mr-2 ${isChecked ? 'text-primary' : ''}`}
                    disabled={isDisabled}
                />
                <span>{perimeter?.id === 'root' ? 'Cnam' : perimeter.id + ' - ' + perimeter.label}</span>
            </label>

            {
                perimeter?.children?.length > 0
                    ? <button
                        type={'button'}
                        className={'btn-no-style ml-2 text-'}
                        data-id={perimeter.id}
                        onClick={() => setOpened(!opened)}
                    >
                        <FontAwesomeIcon
                            className={'my-auto'}
                            size={'lg'}
                            icon={
                                opened
                                    ? faCaretDown
                                    : faCaretRight
                            }
                            color={'black'}
                        />
                    </button>
                    : <div className={'mr-3'}/>
            }
        </div>

        {
            opened && perimeter?.children?.length > 0
                ? <PerimetersTree
                    onChange={onChange}
                    selectedPerimeter={selectedPerimeter}
                    perimetersTree={perimeter.children}
                    showAcquired={showAcquired}
                    checked={checked || selectedPerimeter?.value === perimeter.id}
                    selectablePerimeters={selectablePerimeters}
                    multiselect={multiselect}
                />
                : ''
        }
    </div>
}

export default PerimeterTreeElement;