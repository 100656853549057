import { useContext } from 'react';
import {NotificationContext} from "../../contexts/NotificationContext";

function useNotification() {
    const {
        notifications,
        showToast,
        hideToast,
        pendingNotifications,
        addPendingNotification,
        showPendingToasts
    } = useContext(NotificationContext);

    return {
        notifications,
        showToast,
        hideToast,
        pendingNotifications,
        addPendingNotification,
        showPendingToasts
    };
}

export default useNotification;
