import React, {useState} from "react";
import {Button, Form, FormGroup} from "reactstrap";
import PerimetersTreeHandling from "../Tree/PerimetersTreeHandling";

function FormSearchFunding(props) {

    return (
        <Form onSubmit={props.onSubmit} autoComplete="off">
            <FormGroup>
              <PerimetersTreeHandling
                  acquiredOnly={true}
                  perimeterTree={props.localRoles.filter(({ role }) => ( role.id === 'ROLE_ADMIN' )).shift()}
                  origin={'ROT'}
                  onChange={props.onChange}
                  selectedPerimeter={props.value}
                  multiselect={false}
              />
            </FormGroup>

            <FormGroup className={"d-flex justify-content-end"}>
              <Button type={"submit"} color={"primary"}>Rechercher</Button>
            </FormGroup>
        </Form>
    );
}

export default FormSearchFunding;
