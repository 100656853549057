import React, {useContext, useRef, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    Collapse,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import {useMutation, useQuery} from '@apollo/client';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserContext from "../contexts/UserContext";
import {
    faCopy,
    faEdit,
    faFolderMinus,
    faFolderPlus,
    faChevronUp,
    faClock,
    faHistory,
    faMapMarkerAlt,
    faPencilAlt,
    faPlus,
    faSave,
    faSpinner,
    faUndo,
    faFilePdf,
    faChevronDown,
    faExclamationCircle,
    faPaperPlane, faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import {faFolder, faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import PropTypes from "prop-types";
import {PROJECT_BY_ID, PROJECTS_BY_REFERENCE_ID, USER_PROFILE_QUERY} from "../graphql/queries";
import Header from "../components/Layout/Header";
import FAButton from "../components/FAButton";
import LoadingSpinner from "../components/Default/LoadingSpinner";
import {ProjectSectionProspect} from "../components/Section/ProjectSectionProspect";
import {
    UPDATE_PROJECT_FULL_PAYLOAD,
    UPDATE_PROSPECT_FULL_PAYLOAD,
    DUPLICATE_PROJECT_FULL_PAYLOAD
} from "../graphql/mutations";
import ProjectSectionFormation from "../components/Section/ProjectSectionFormation";
import ProjectSectionConclusion from "../components/Section/ProjectSectionConclusion";
import ProjectFooterNotes from "../components/Section/ProjectFooterNotes";
import DuplicatedProjectsButton from "../components/Button/DuplicatedProjectsButton";
import RenderIfRole from "../components/Security/RenderIfRole";
import StatusCode from "http-status-codes";
import PdfModalDraggable from "../components/PdfModalDraggable";
import BasePdf from "../components/GeneratePdf/BasePdf";
import useNotification from "../lib/notification/useNotification";
import NotificationToast from "../lib/notification/NotificationToast";
import {
    getProspectInput,
    getProspectInputForUpdate
} from "../lib/input/prospectInput";
import trans from "../lib/translate/trans";
import ToggleSectionTitle from "../components/Section/ToggleSectionTitle";

const ProjectOverView = (props) => {
    let projectId = parseInt(props.match.params.id);
    let isView = props.match.params.accessMode !== 'edit';
    let section = props.match.params.sectionTargeted === undefined ? 'prospect' : props.match.params.sectionTargeted;
    let enfUid = props.match.params.enfUid;

    const [project, setProject] = useState();

    const attestationCanBetGenerated = () => project && project.prospect && project.conclusion && project.schema?.schema && project.schema.schema !== "[]";

    const faButtonsClosed = [
        { id: 'pdf', title: 'Générer l\'attestation de positionnement', fa: faFilePdf, btnClass: 'btn-info', titleClass: 'text-white', active: attestationCanBetGenerated() },
        { id: 'reOpen', title: 'Rouvrir le projet', fa: faFolderOpen, btnClass: 'btn-primary', titleClass: 'text-white'},
        { id: 'takeBack', title: 'Dupliquer le projet', fa: faCopy, btnClass: 'btn-primary', titleClass: 'text-white'}
    ];
    const faButtonsOpened = [
        { id: 'close', title: 'Clore le projet', fa: faFolderMinus, btnClass: 'btn-danger', titleClass: 'text-white'},
        { id: 'abort', title: 'Annuler les modifications', fa: faUndo, btnClass: 'btn-danger', titleClass: 'text-white' },
        { id: 'pdf', title: 'Générer l\'attestation de positionnement', fa: faFilePdf, btnClass: 'btn-info', titleClass: 'text-white', active: attestationCanBetGenerated() },
    ]

    // TODO: Check user has authorized access and deal with different access mode (view, edit, create)
    const {currentUser} = useContext(UserContext);
    const [completeCurrentUser, setCompleteCurrentUser] = useState(null)
    const { loading: currentUserLoading, error: currentUserError, data: currentUserData } = useQuery(USER_PROFILE_QUERY, {
        variables: { userId: currentUser?.id ? currentUser.id : 0 },
        onCompleted: (data) => {
            setCompleteCurrentUser(data?.Users[0] ?? null);
        }
    });

    const onCompletedProject = (data) => {
        const isUpdate = !data.Projects;
        if (!isUpdate && data.Projects.length !== 1) {
            setSchema({});
            setCursus(null);
            return;
        }

        const projectData = !isUpdate ? data.Projects[0] : data.UpdateProject.projet;
        setProject(projectData);
        setSchema(JSON.parse(projectData.schema.schema));
        setCursus(projectData.cursusTargeted);
        setConclusion(projectData.conclusion ?? '');
        setProspectInput(getProspectInput(projectData.prospect));

        if (isUpdate && data.UpdateProject.responseCode === StatusCode.OK) {
            toggleViewMode();
            refetchProjectsReference();
            setTouched(false);
        }

        showPendingToasts();
    }
    const {loading: loadingProject, error, data: projectData, refetch} =
        useQuery(PROJECT_BY_ID, {
            variables: { id: projectId, },
            onCompleted: onCompletedProject
        });

    const [updateProject, {loading: updateProjectLoading}] =
        useMutation(UPDATE_PROJECT_FULL_PAYLOAD, {
                onCompleted: (data) => {
                    if (data?.UpdateProject?.responseCode === StatusCode.OK) {
                        onCompletedProject(data);
                    } else {
                        displayErrorMessage(data?.UpdateProject?.responseMessages);
                    }
                }
            }
        );

    const [DuplicateProject, {loading: duplicateProjectLoading}] = useMutation(DUPLICATE_PROJECT_FULL_PAYLOAD);
    const [isFormationSectionOpened, setFormationSectionOpened] = useState(section === 'formation');
    const toggleSectionFormation = () => setFormationSectionOpened(!isFormationSectionOpened);
    const [isHistorySectionOpened, setHistorySectionOpened] = useState(section === 'history');
    const toggleSectionHistory = () => setHistorySectionOpened(!isHistorySectionOpened);

    //MODAL PDF
    const [modalPdf, setModalPdf] = useState(false);
    const toggleModalPdf = () => setModalPdf(!modalPdf);
    const [documentPdf, setDocumentPdf] = useState(null);

    // TODO : Handle and implement editing mode
    const [isViewMode, setIsViewMode] = useState(isView);
    const toggleViewMode = () => setIsViewMode(!isViewMode);
    const [touched, setTouched] = useState(false);

    const [prospectInput, setProspectInput] = useState();

    const onCompletedProspect = (data) => {
        const {responseCode, prospect, responseMessages} = data.UpdateProspect;
        if (responseCode === StatusCode.OK) {
            updateProjectData();
            setProspectInput(getProspectInput(prospect));
        } else {
            displayErrorMessage(responseMessages);
        }
    }
    const [updateProspect, {loading: updateProspectLoading}] = useMutation(
        UPDATE_PROSPECT_FULL_PAYLOAD,
        {onCompleted: onCompletedProspect}
        );

    const prevProspect = useRef();

    const saveProject = () => {
        if (prospect?.id && prevProspect.current !== prospect) {
                updateProspect({
                    variables: {
                        id: prospect.id,
                        prospect: {
                            ...getProspectInputForUpdate(prospectInput)
                        }
                    }}
                    )
        }
        else {
            updateProjectData();
        }
    }

    const updateProjectData = () => {
        addPendingNotification(new NotificationToast("PROJET",
            "Les données du projet ont été mises à jour",
            "success",
            faCheckCircle));

        updateProject({
            variables: {
                project: {
                    'isOpen': project.isOpen,
                    'prospect': prospect?.id,
                    'schema': JSON.stringify(schema),
                    'cursus': cursus?.id ?? null,
                    'notes': project.notes,
                    'conclusion': conclusion,
                    'relatedProfessionalExperience': project.relatedProfessionalExperience?.id
                },
                id: project.id
            }
        });
    }

    const [alreadyDuplicated, setAlreadyDuplicated] = useState(false);
    const {loading: loadingProjectsReference, refetch: refetchProjectsReference } = useQuery(PROJECTS_BY_REFERENCE_ID, {
        variables: { idReference: project ? project.idReference : 0 },
        onCompleted(data) {
            if (data.Projects.filter(p => p.id !== projectId && p.id > projectId).length > 0) {
                setAlreadyDuplicated(true);
            }
        }
    });

    const { showToast, addPendingNotification, showPendingToasts } = useNotification();

    const handleFAButton = (section) => {
        setIsViewMode(false);
        switch (section) {
            case 'pdf':
                if (documentPdf === null){
                    setDocumentPdf(<BasePdf
                        typeContent="attestationPositionnement"
                        object={project}
                        user={completeCurrentUser}
                    />)
                }
                toggleModalPdf();
                break;
            case 'abort':
                setTouched(false);
                refetch().then(result => onCompletedProject(result.data));
                toggleViewMode();
                break;
            case 'close':
                closeOpenProject(false);
                break;
            case 'reOpen':
                if (alreadyDuplicated) {
                    displayErrorMessage(["Projet():Ce projet possède une version plus récente et ne peut donc pas être rouvert"]);
                } else {
                    closeOpenProject(true);
                }
                break;
            case 'takeBack':
                if (alreadyDuplicated) {
                    displayErrorMessage(["Projet():Ce projet possède une version plus récente et ne peut donc pas être repris"]);
                }else {
                    takeBackProject();
                }
                break;
        }
    };
    const [schema, setSchema] = useState(projectData?.Projects[0].schema ?? []);
    const [cursus, setCursus] = useState();
    const [conclusion, setConclusion] = useState('');
    const [editFormation, setEditFormation] = useState(false);
    const toggleEditFormation = () => setEditFormation(!editFormation);

    if (!project || loadingProject) {
        return (
            <div className={"container-fluid"}>
                <Header titlePage={"PROJET"}
                        faIcon={faFolderOpen}
                        objectID={projectId}/>
                <div className="row justify-content-center">
                    <LoadingSpinner/>
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className={"container-fluid"}>
                <Header titlePage={"PROJET"}
                        faIcon={faFolderOpen}
                        objectID={projectId}/>
                <div className="row justify-content-center">
                    <em className="text-warning"> Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></em>
                </div>
            </div>
        )
    }

    if (projectData?.Projects.length === 0) {
        return (
            <div className={"container-fluid"}>
                <Header titlePage={"PROJET"}
                        faIcon={faFolderOpen}
                        objectID={projectId}/>
                <div className="row justify-content-center">
                    <h6 className="text-muted">Le projet n°{projectId} est introuvable</h6>
                </div>
            </div>
        );
    }

    const prospect = project?.prospect;
    const isOpen = project?.isOpen;

    const closeOpenProject = (closeopen) => {
        updateProject({
            variables: {
                project: {isOpen: closeopen},
                id: projectId
            }
        });
    }

    const takeBackProject = () => {
        Promise.resolve()
            .then (() => DuplicateProject({
                variables: {
                    id: projectId
                }
            })
                .then ((r) => {
                    const response = r.data.DuplicateProject
                    if (response.responseCode === 201) {
                        const duplicatedProjectId = response.projet.id;
                        const iri = "/project/";
                        const pathname= iri.concat(duplicatedProjectId);
                        props.history.push({pathname})
                    }
                }));
    }

    let faButtons;
    if (project?.isOpen) {
        faButtons = faButtonsOpened.filter(b => b.isTouched === undefined || b.isTouched === touched);
    } else {
        faButtons = faButtonsClosed;
    }

    faButtons = faButtons.filter(b => b.active === undefined || b.active);

    if (touched) {
       const element = document.getElementById('fab-projectOverview')
       if (element) element.classList.add('fab-save-dropdown');
    }

    const updateMutationLoading = updateProspectLoading || updateProjectLoading || duplicateProjectLoading;
    const loading = updateMutationLoading || loadingProjectsReference || loadingProject;

    const displayErrorMessage = (message) => {
        let title = "Un problème est survenu";
        let errorMessage = "";

        if (Array.isArray(message)) {
            message = message[0];
            const regex = /([\w]+)\(([\w|\W]*)\):(.+)/u;
            const messageParts = message.match(regex);

            if (!messageParts || messageParts.length <= 1) {
                errorMessage = message;
            } else {
                const field = messageParts[1];
                const transField = trans(field);
                const value = messageParts[2];
                const msg = messageParts[3];

                if (field.toLowerCase() === "projet" || field.toLowerCase() === "prospect") {
                    title = transField;
                    errorMessage = msg;
                } else {
                    title = field !== "" ? transField : title;
                    errorMessage += field !== '' ? `Une erreur est survenue sur la donnée '${transField}'` : '';
                    errorMessage += value !== '' ? ` avec la valeur '${value}' ` : ' ';
                    errorMessage += msg;
                }
            }
        } else {
            title = "Un problème est survenu";
            errorMessage = message;
        }

        showToast(new NotificationToast(title, errorMessage,"danger", faExclamationCircle));
    }

    return (
        <div className={"container-fluid pl-0 pl-md-3 pr-0 pr-md-3"}>
            <Header titlePage={"PROJET"}
                    faIcon={isOpen ? faFolderOpen : faFolder}
                    objectID={isOpen ? projectId : `${projectId} (Clos)`}/>

            <div id="fabContainer" className="project-sections-wrap">
                <div className="d-md-flex flex-row justify-content-between align-items-center mt-1">

                    <h6 className="col-12 col-sm-6 col-lg-auto text-muted">
                        <FontAwesomeIcon icon={faMapMarkerAlt}/> {project.perimeter.label}
                    </h6>

                    { project.updatedAt ?
                        <h6 className="col-12 col-sm-6 col-lg-4 text-muted text-md-right">
                            <FontAwesomeIcon icon={faClock}/> {formatDate(project.updatedAt)}
                            <DuplicatedProjectsButton project={project}/>
                        </h6>
                        : ''
                    }

                </div>
                {updateMutationLoading ?
                    <LoadingSpinner text={"Enregistrement des modifications en cours ..."} color={'info'}/>
                    : ''
                }
                <ListGroup className="mt-3">
                    <ListGroupItem className="col-12" tag={"div"} href={"#"} action={false}>
                        <ProjectSectionProspect project={project}
                                                setProject={setProject}
                                                prospect={prospect}
                                                prospectInput={prospectInput}
                                                setProspectInput={setProspectInput}
                                                section={section}
                                                isViewMode={!isOpen || isViewMode}
                                                enfUid={!isViewMode && !prospect ? enfUid : null}
                                                onChange={setTouched}/>
                    </ListGroupItem>

                    <ListGroupItem className="col-12" tag={"div"} href={"#"}>
                        <ProjectSectionFormation schema={schema}
                                                 setSchema={setSchema}
                                                 cursus={cursus}
                                                 setCursus={setCursus}
                                                 collapseOpen={isFormationSectionOpened}
                                                 toggleCollapse={toggleSectionFormation}
                                                 edit={isOpen && !isViewMode}
                                                 editFormation={editFormation}
                                                 setEditFormation={setEditFormation}
                                                 toggleEditFormation={toggleEditFormation}
                                                 fundingsPerimeter={project.perimeter}
                                                 onChange={setTouched}
                        />
                    </ListGroupItem>

                    <ListGroupItem className="col-12" tag={"div"} href={"#"} action={false}>
                        <div className="d-md-flex flex-row justify-content-md-between align-items-center text-dark">

                            <ToggleSectionTitle
                                title={'HISTORIQUE'}
                                icon={faHistory}
                                toggleFunction={toggleSectionHistory}
                            />

                            <h6 className="flex-grow-1">
                                { project.updatedAt ?
                                    <span>Dernière modification le {formatDate(project.updatedAt)}</span>
                                    : <em className="small">En attente de création</em>
                                }
                            </h6>

                            { project.createdAt ?
                                <Button
                                    size={'sm'}
                                    color="info" outline
                                    onClick={toggleSectionHistory}>
                                    {isHistorySectionOpened ?
                                        <em>Fermer <FontAwesomeIcon icon={faChevronUp}/></em>
                                        : <em>Afficher <FontAwesomeIcon icon={faChevronDown}/></em>
                                    }
                                </Button>
                                : ''
                            }

                        </div>

                        { project.createdAt ?
                            <Collapse isOpen={isHistorySectionOpened}>
                                <CollapsedHistoryData project={project}/>
                            </Collapse>
                            : ''
                        }
                    </ListGroupItem>

                    <ListGroupItem className="col-12" tag={"div"} href={"#"} action={false}>
                        <ProjectSectionConclusion setConclusion={c => {setConclusion(c); setTouched(touched || c !== project.conclusion);}}
                                                  conclusion={conclusion}
                                                  isViewMode={!isOpen || isViewMode}/>
                    </ListGroupItem>


                </ListGroup>

                {
                    touched ?
                        <div className={`btn-group-fab fab-save`} role="group">
                            <div>
                                <button className={`btn btn-fab btn-main btn-primary `}
                                        type="button"
                                        onClick={() => {
                                            saveProject()
                                        }}
                                >
                                    <FontAwesomeIcon spin={updateMutationLoading} icon={updateMutationLoading ? faSpinner : faSave}/>
                                </button>
                            </div>
                        </div>
                        :''
                }

                <RenderIfRole isRoleChargeAIOAIP all={false}>
                    {
                        isViewMode
                            ?
                            <div className={'btn-group-fab'}>
                                <button onClick={toggleViewMode}
                                        className={`btn btn-fab btn-main btn-primary has-tooltip`} type="button">
                                    <FontAwesomeIcon spin={loading} icon={loading ? faSpinner : faPencilAlt}/>
                                </button>
                            </div>
                            :
                            <div id={'fab-projectOverview'}>

                                <FAButton loading={loading}
                                          buttons={faButtons}
                                          iconMain={faPlus}
                                          btnClassMain={'btn-primary'}
                                          dataPlacement='left'
                                          withButtonTitle={true}
                                          withRotation={true}
                                          onClick={handleFAButton}/>

                            </div>
                    }
                </RenderIfRole>
                </div>
            <ProjectFooterNotes project={project} isViewMode={!isOpen || isViewMode} onChange={setTouched} setProject={setProject}/>

            {attestationCanBetGenerated() && <PdfModalDraggable
                document={documentPdf}
                object={project}
                setProject={setProject}
                modal={modalPdf}
                title="Genérer l'attestation de positionnement"
                toggle={() => toggleModalPdf()}
            />}
        </div>
    );
};

function CollapsedHistoryData({project}) {
    const entries = project.actionEntries.slice().sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    return (
        <Card color="light" style={{marginTop: '1rem'}}>
            <CardBody>
                {entries.map((entry, index) => (
                    <div key={index}>
                        <div className={"row"}>
                            <p className={"col-md-1"}>
                                {(() => {
                                    switch (entry.label.replace(/ n°(\d)+/, '')) {
                                        case 'Créer le projet' :
                                            return <FontAwesomeIcon icon={faFolderPlus} className="text-dark"/>
                                        case 'Modifier le projet' :
                                            return <FontAwesomeIcon icon={faEdit} className="text-dark"/>
                                        case 'Fermer le projet' :
                                            return <FontAwesomeIcon icon={faFolderMinus} className="text-dark"/>
                                        case 'Rouvrir le projet' :
                                            return <FontAwesomeIcon icon={faFolderOpen} className="text-dark"/>
                                        case 'Ouvrir' :
                                            return <FontAwesomeIcon icon={faFolderOpen} className="text-dark"/>
                                        case 'Dupliquer le projet' :
                                            return <FontAwesomeIcon icon={faCopy} className="text-dark"/>
                                        case 'Envoyer l\'attestation' :
                                            return <FontAwesomeIcon icon={faPaperPlane} className={"text-dark"}/>
                                    }
                                })()} </p>
                            <p className={"col-md-11 font-weight-lighter"}>
                                <span className={"font-weight-normal"}>{entry.actor?.fullName} </span>
                                a effectué l'action <span className={"font-weight-normal"}>{entry.label}</span> le <span
                                className={"font-weight-normal"}>{formatDate(entry.date)}</span>.
                            </p>
                        </div>
                        <hr/>
                    </div>
                ))}
            </CardBody>
        </Card>
    )
}

function formatDate(string) {
    let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(string).toLocaleDateString('fr-FR', options);
}



ProjectOverView.propTypes = {
    projectId: PropTypes.number,
    accessMode: PropTypes.oneOf([
        'view',
        'edit',
    ]),
    sectionTargeted: PropTypes.oneOf([
        'prospect',
        'formation',
        'history',
        'conclusion',
    ]),
};

ProjectOverView.defaultProps = {
    accessMode: 'view',
    sectionTargeted: 'prospect'
};

export default ProjectOverView;
