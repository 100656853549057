import React, {useContext} from 'react';
import Header from "../components/Layout/Header";
import WidgetCard from "../components/Card/WidgetCard";
import CountCard from "../components/Card/CountCard";
import {
    COUNT_ALL_PERIMETER_PROJECTS,
    COUNT_ALL_PROJECTS,
    COUNT_ALL_USER_PROJECTS, PROJECTS_BY_PERIMETER_ID, PROJECTS_BY_USER,
    PROJECTS_USER_RECENT,
} from "../graphql/queries";
import {
    faChartPie,
    faCompass,
    faPlus,
    faUserGraduate
} from "@fortawesome/free-solid-svg-icons";
import FormSearchProspectWrapper from "../components/Form/FormSearchProspectWrapper";
import ProjectsCard from "../components/Card/ProjectsCard";
import CreateProjectButton from "../components/Button/CreateProjectButton";
import RenderIfRole from "../components/Security/RenderIfRole";
import UserContext from "../contexts/UserContext";
import {ROLE_CHARGE_AIOA_IP} from "../lib/security/roles";
import ProjectsExportLink from "../components/Statistics/ProjectsExportLink";

function DashboardPage(props) {
    const { currentUser } = useContext(UserContext);
    const currentUserComputedPerimeter = currentUser?.localRolesComputed[ROLE_CHARGE_AIOA_IP]
        ? currentUser?.localRolesComputed[ROLE_CHARGE_AIOA_IP][0]
        : '';

    return (
        <div className={"container-fluid"}>
            <Header titlePage={props.title} faIcon={faCompass}/>

            <div className={'mb-2 d-flex flex-column flex-md-row justify-content-start'}>
                <RenderIfRole isRoleChargeAIOAIP isRoleGuest all={false}>
                    <div className={'flex-grow-1 mb-2 mb-md-0'}>
                        <CreateProjectButton title={"créer un projet"}
                                             color={"primary"}
                                             faIcon={faPlus}
                                             withAnimation={true}/>
                        <WidgetCard faIcon={faUserGraduate} color={"white"} title={"Rechercher un prospect"}>
                            <FormSearchProspectWrapper withRedirection={true}/>
                        </WidgetCard>
                    </div>
                </RenderIfRole>

                <div className={'ml-md-2 d-flex flex-column justify-content-start'}>
                    <CountCard title={ "Projets Cnam" } query={ COUNT_ALL_PROJECTS } faIcon={faChartPie} />
                    <RenderIfRole isRoleChargeAIOAIP>
                        {
                            currentUserComputedPerimeter !== 'root' &&
                            <CountCard title={"Projets Cnam " + currentUserComputedPerimeter} query={COUNT_ALL_PERIMETER_PROJECTS} faIcon={faChartPie}>
                                <ProjectsExportLink query={PROJECTS_BY_PERIMETER_ID} filename={'compas_projects_' + currentUserComputedPerimeter + '_export.csv'} />
                            </CountCard>
                        }
                        <CountCard title={"Vos projets"} query={COUNT_ALL_USER_PROJECTS} faIcon={faChartPie}>
                            <ProjectsExportLink query={PROJECTS_BY_USER} />
                        </CountCard>
                    </RenderIfRole>
                </div>
            </div>

            <RenderIfRole isRoleChargeAIOAIP>
                <ProjectsCard titleName={"Vos derniers projets"} query={PROJECTS_USER_RECENT}/>
            </RenderIfRole>
        </div>
    );
}

DashboardPage.defaultProps = {
    title: "TABLEAU DE BORD"
}

export default DashboardPage;
