import React, {useContext, useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import FormFunding from "./FormFunding";
import {CREATE_FUNDING_FULL_PAYLOAD, UPDATE_FUNDING_FULL_PAYLOAD} from "../../graphql/mutations";
import {Redirect} from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import {USER_LOCAL_ROLE_PERIMETERS_QUERY} from "../../graphql/queries";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const FundingFormHandling = ({ initialValues, validate, mode }) => {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = React.useState({});
    const [redirect, setRedirect] = useState(false);

    const { currentUser } = useContext(UserContext);
    const {loading, error, data} = useQuery(USER_LOCAL_ROLE_PERIMETERS_QUERY, {
        variables: {
            userId: currentUser.id
        }
    });

    const localRoles = data?.Users[0].localRoles ?? [];

    function removeError(fieldName) {
        const errorsCopy = {...errors}
        if (fieldName === 'email' || fieldName === 'birthDate') {
            delete errorsCopy['email']
            delete errorsCopy['birthDate']
        } else {
            delete errorsCopy[fieldName]
        }
        setErrors(errorsCopy);
    }

    // change event handler
    const onPerimeterChange = e => {
        removeError('perimeter');

        setValues({
            ...values,
            perimeter: {
                ...values.perimeter,
                'value': values.perimeter.value === e.currentTarget.dataset.id ? null : e.currentTarget.dataset.id,
            }
        })

        setTouched({
            ...touched,
            ['perimeter']: true,
        });
    }

    const onLabelChange = e => {
        removeError(e.target.name);
        setValues({
            ...values,
            label: {
                ...values.label,
                'value': e.target.value,
            }
        })

        setTouched({
            ...touched,
            [e.target.name]: true,
        });
    }

    const [mutationFunding, { called: calledMutation }] = useMutation(
        mode === "create" ? CREATE_FUNDING_FULL_PAYLOAD : UPDATE_FUNDING_FULL_PAYLOAD,
        {
            onCompleted: () => {
                setRedirect(true);
            }
        }
    );

    // form submit handler
    const onSubmit = e => {
        e.preventDefault();

        const formValidation = Object.keys(values).reduce(
            (acc, key) => {
                let newError = validate[key](
                    values[key],
                    values[key]?.values === null
                        ? null
                        : values[key]?.values
                );

                const newTouched = { [key]: true };

                return {
                    errors: {
                        ...acc.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...acc.touched,
                        ...newTouched,
                    },
                };
            },
            {
                errors: { ...errors },
                touched: { ...touched },
            },
        );
        setErrors(formValidation.errors);
        setTouched(formValidation.touched);

        if (
            !Object.values(formValidation.errors).length && // errors object is empty
            Object.values(formValidation.touched).length ===
            Object.values(values).length && // all fields were touched
            Object.values(formValidation.touched).every(t => t === true) // every touched field is true
        ) {
            // Save funding form !
            const newFunding = {
                label: values.label?.value,
                perimeter: values.perimeter?.value
            };

            if (mode === 'create') {
                mutationFunding({variables: {funding: newFunding}});
            }else {
                delete newFunding['fundingID'];
                mutationFunding({variables: {id: values?.fundingID?.value, funding: newFunding}});
            }
        }
    }

    return (
        <>
            {
                loading
                    ? <p><FontAwesomeIcon icon={['fas', 'spinner']} spin /> Chargement</p>
                    : ''
            }
            {
                error
                    ? <p>Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
                    : ''
            }
            {
                data
                ? <FormFunding
                        onSubmit={onSubmit}
                        onChange={onLabelChange}
                        onPerimeterChange={onPerimeterChange}
                        values={values}
                        mode={mode}
                        errors={errors}
                        touched={touched}
                        localRoles={localRoles}
                    />
                : ''
            }
            {
                redirect && calledMutation
                    ? <Redirect
                        to={{
                            pathname: '/search/results/fundings',
                            state: {
                                values:
                                    {
                                        perimeter: values.perimeter
                                    },
                                localRoles: localRoles
                            }
                        }}
                        push={true}/>
                    : ""
            }
        </>
    )
}
export default FundingFormHandling;
