import React from "react";
import PerimeterTreeElement from "./PerimeterTreeElement";

function PerimetersTree(props) {
    const perimetersTree = props.perimetersTree ?? [];
    const selection = (perimeterId) => {
        let isSelected;
        if (props.multiselect) {
            isSelected = props.selectedPerimeter?.includes(perimeterId);
        } else
            isSelected = props.selectedPerimeter === perimeterId;
        return isSelected
    }

    return (
        <>
            {
                perimetersTree.map(
                    perimeter => {
                        return (
                            <div
                                key={perimeter.id}
                                className={
                                    perimeter.id === 'root'
                                        ? ''
                                        : 'ml-3'
                                }
                            >
                                {
                                    <PerimeterTreeElement
                                        perimeter={perimeter}
                                        onChange={props.onChange}
                                        checked={props.checked || selection(perimeter.id)}
                                        selectedPerimeter={props.selectedPerimeter}
                                        selectablePerimeters={props.selectablePerimeters}
                                        multiselect={props.multiselect}
                                    />
                                }
                            </div>
                        );
                    }
                )
            }
        </>
    )
}

export default PerimetersTree;