import { gql } from '@apollo/client';
import {FULL_PROSPECT, FULL_PROJECT} from "./fragments";

export const CREATE_PROJECT_FROM_SCRATCH = gql`
    mutation CreateProject($project: ProjectInput!) {
      CreateProject(input: $project) {
        responseCode
        responseMessages
        projet: object {
          ... on Project {
            id
            idReference
            perimeter {
              parent {
                id,
                label
              }
            }
            createdAt
            updatedAt
            isOpen
          }
        },
          
      }
    }
`;

export const CREATE_PROJECT_FULL_PAYLOAD = gql`
  ${FULL_PROJECT}
  mutation CreateProjectFullPayload($project: ProjectInput!) {
    CreateProject(input: $project) {
      responseMessages
      responseCode
      projet: object {
        ... on Project {
          ...FullProject
        }
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: Int!) {
    DeleteProject(id: $id) {
      responseMessages
      responseCode
    }
  }
`;

export const UPDATE_PROJECT_FULL_PAYLOAD = gql`
${FULL_PROJECT}
mutation UpdateProjectFullPayload($project: ProjectInput!, $id: Int!) {
  UpdateProject(input: $project, id: $id) {
    responseMessages
    responseCode
    projet: object {
      ... on Project {
        ...FullProject
      }
    }
  }
}
`;

export const CREATE_PROSPECT_FULL_PAYLOAD = gql`
  ${FULL_PROSPECT}
  mutation CreateProspectFullPayload($prospect: ProspectInput!) {
    CreateProspect(input: $prospect) {
      responseMessages
      responseCode
      prospect: object {
        ... on Prospect {
          ...FullProspect
        }
      }
    }
  }
`;

export const DELETE_PROSPECT = gql`
  mutation DeleteProspect($id: Int!) {
    DeleteProspect(id: $id) {
      responseMessages
      responseCode
    }
  }
`;

export const UPDATE_PROSPECT_FULL_PAYLOAD = gql`
  ${FULL_PROSPECT}
  mutation UpdateProspectFullPayload($prospect: ProspectInput!, $id: Int!) {
    UpdateProspect(input: $prospect, id: $id) {
      responseMessages
      responseCode
      prospect: object {
        ... on Prospect {
          ...FullProspect
        }
      }
    }
  } 
`;

export const UPDATE_PROSPECT = gql`
  mutation UpdateProspect($prospect: ProspectInput!, $id: Int!) {
    UpdateProspect(input: $prospect, id: $id) {
      responseMessages
      responseCode
    }
  }
`;

export const CREATE_FUNDING_FULL_PAYLOAD = gql`
  mutation CreateFundingFullPayload($funding: FundingInput!) {
    CreateFunding(input: $funding) {
      responseMessages
      responseCode
      funding: object {
        ... on Funding {
          id
          perimeter {
            id
          }
          label
        }
      }
    }
  }
`;

export const UPDATE_FUNDING_FULL_PAYLOAD = gql`
  mutation UpdateFundingFullPayload($funding: FundingInput!, $id: Int!) {
    UpdateFunding(input: $funding, id: $id) {
      responseMessages
      responseCode
      funding: object {
        ... on Funding {
          id
          perimeter {
            id
          }
          label
        }
      }
    }
  }
`;

export const DELETE_FUNDING = gql`
  mutation DeleteFunding($id: Int!) {
    DeleteFunding(id: $id) {
      responseMessages
      responseCode
    }
  }
`;

export const CREATE_USER_FULL_PAYLOAD = gql`
  mutation CreateUserFullPayload($user: UserInput!) {
    CreateUser(input: $user) {
      responseMessages
      responseCode
      user: object {
        ... on User {
          id
          createdProjects {
            id
          }
          contributedProjects {
            id
          }
          enfUid
          fullName
          username
          email
          localRoles {
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_FULL_PAYLOAD = gql`
  mutation UpdateUserFullPayload($user: UserInput!) {
    UpdateUser(input: $user) {
      responseMessages
      responseCode
      user: object {
        ... on User {
          id
          createdProjects {
            id
          }
          contributedProjects {
            id
          }
          enfUid
          fullName
          username
          email
          localRoles {
            id
          }
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($enfUid: String!) {
    DeleteUser(enfUid: $enfUid) {
      responseMessages
      responseCode
    }
  }
`;

export const ADD_LOCAL_ROLE_USER_FULL_PAYLOAD = gql`
  mutation AddLocalRoleUserFullPayload($localRoleUser: [LocalRoleInput!]!, $id: Int!) {
    AddLocalRoleUser(input: $localRoleUser, id: $id) {
      responseMessages
      responseCode
      user: object {
        ... on User {
          id
          createdProjects {
            id
          }
          contributedProjects {
            id
          }
          enfUid
          fullName
          username
          email
          localRoles {
            role {
              id
              label
            }
            perimetres {
              id
              label
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_LOCAL_ROLE_USER_FULL_PAYLOAD = gql`
  mutation RemoveLocalRoleUserFullPayload($localRoleUser: [LocalRoleInput!]!, $id: Int!) {
    RemoveLocalRoleUser(input: $localRoleUser, id: $id) {
      responseMessages
      responseCode
      user: object {
        ... on User {
          id
          createdProjects {
            id
          }
          contributedProjects {
            id
          }
          enfUid
          fullName
          username
          email
          localRoles {
            role {
              id
              label
            }
            perimetres {
              id
              label
            }
          }
        }
      }
    }
  }
`;

export const ADD_EXTRA_FIELD_TO_SCHEMA = gql`
  mutation AddExtraFieldToSchema($elementId: String!, $schema: String!, $extraKey: String!, $extraValue: String!, $uniteOnly: Boolean, $visitChildren: Boolean) {
    data:AddSchemaExtra(idElement: $elementId, schema: $schema, extraKey: $extraKey, extraValue: $extraValue, uniteOnly: $uniteOnly, visitChildren: $visitChildren) {
      schema
    }
  }
`;

export const ADD_UNIT_TO_SCHEMA = gql`
mutation AddUnitToSchema($schema: String!, $elementId: String!, $codeUnite: String!) {
  data:AddSchemaUnit(schema: $schema, idElement: $elementId, codeUnite: $codeUnite) {
    schema
  }
}    
`;

export const DUPLICATE_PROJECT_FULL_PAYLOAD = gql`
  ${FULL_PROJECT}
  mutation duplicateProjectFullPayload ($id: Int!) {  
    DuplicateProject(id: $id){
      responseMessages,
      responseCode,
      projet: object {
        ...on Project {
          ...FullProject
        }
      }
   }
  }
`;

export const REMOVE_UNIT_TO_SCHEMA = gql`
mutation RemoveUnitToSchema($schema: String!, $elementId: String!) {
  data:RemoveSchemaElement(schema: $schema, idElement: $elementId) {
    schema
  }
}    
`;

export const SEND_POSITIONING_CERTIFICATE = gql`
${FULL_PROJECT}
mutation SendPositioningCertificate($emails: [String]!, $attestation: PositioningCertificateInput!, $projectId: ID!){
   SendPositioningCertificate(emails: $emails, attestation: $attestation, projectId: $projectId) {
    responseCode
    responseMessages
    project: object {
      ...on Project {
        ...FullProject
      }
    }     
  }
}  
`;