import React from "react";
import {Button} from "reactstrap";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom"

function BackButton({className, size}) {
    const history = useHistory()
    return <Button className={className} size={size} onClick={() => history.goBack()} color={'danger'}>
        <FontAwesomeIcon icon={faChevronLeft}/> Retour
    </Button>;
}

BackButton.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string
};

export default BackButton;