import React, {useEffect, useRef, useState} from 'react';
import FormProspect from "./FormProspect"
import {CustomInput} from "reactstrap";
import {FormGroup, ListGroupItem} from "react-bootstrap";
import PropTypes from "prop-types";
import FormConsentsProspect from "./FormConsentsProspect";

const ProspectFormHandling = ({
                                  formValues,
                                  validate,
                                  mode,
                                  project,
                                  mutationCallback,
                                  onChange,
                                  setProject,
                                  consentsSchemas }) => {

    const [requiredFieldsValidated, setRequiredFieldValidated] = useState(mode === 'edit' || mode === 'complete_enf');

    const [registeredConsents, setRegisteredConsents] = useState(formValues?.gpdrConsents
        ? JSON.parse(formValues?.gpdrConsents) ?? {}
        : {});

    // Update the consents
    useEffect(function() {
        let builtConsents = {};

        Object.keys(consentsSchemas).forEach(function(consentId) {
            builtConsents[consentId] = consentsSchemas[consentId]?.mandatory
                ? true
                : registeredConsents[consentId] ?? false;
        });

        setValues({
            ...values,
            gpdrConsents: builtConsents
        });
    }, [consentsSchemas, registeredConsents]);

    const [values, setValues] = useState({
        ...formValues,
        gpdrConsents: {}
    });

    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [valueFocussed, setValueFocussed] = useState("");

    const [showNotRequiredFields, setShowNotRequiredFields] = useState(mode === 'edit');

    useEffect(()=> {
        if (project.prospect) {
            const relatedProfessionalExperience = getRelatedProfessionalExperience();
            let prospect = getProspectValues();
            //prospect.id = project.prospect.id
            mutationCallback({variables: {prospect: prospect, relatedProfessionalExperience: {id: relatedProfessionalExperience}}})
        }
    },[values]);

    function removeError(newErrors, fieldName) {
        const errorsCopy = {...newErrors}
        if (fieldName === 'email' || fieldName === 'birthDate') {
            delete errorsCopy['email']
            delete errorsCopy['birthDate']
        }
        else if (fieldName === 'homeCountryCode' || fieldName === 'homePostCode') {
            delete errorsCopy['homeCountryCode']
            delete errorsCopy['homePostCode']
        }
        else {
            delete errorsCopy[fieldName]
        }
        return errorsCopy;
    }

    // change event handler
    const handleChange = evt => {

        onChange(true)

        const { name, value: newValue, type } = evt.target;

        setValueFocussed(name); // NEW !
        setErrors(removeError(errors, name));

        // keep number fields as numbers
        let value = type === 'number' ? +newValue : newValue;

        if (type === 'checkbox') {
            const { checked } = evt.target
            value = checked;
        }
        // save field values
        setValues({
            ...values,
            [name]: value,
        });

        // was the field modified
        setTouched({
            ...touched,
            [name]: true,
        });

        if (name === 'email') {
            setTouched( {
                ...touched,
                ['birthDate']: true,
            });
        }
        if (name === 'birthDate') {
            setTouched( {
                ...touched,
                ['email']: true,
            });
        }
    };

    const handleBlur = evt => {
        const { name, value } = evt.target;

        // remove whatever error was there previously
        const { [name]: removedError, ...rest } = errors;
        setValueFocussed("");

        // check for a new error
        let error;
        if (name === 'birthDate') {
            let isEmailEmpty = !values?.email ? true : values?.email?.trim() === "";
            error = validate[name](value, isEmailEmpty);
            if (error !== null) {
                const errorsCopy = {...errors}
                errorsCopy['birthDate'] = error;
                setErrors(errorsCopy);
            }
            setTouched( {...touched, ['birthDate']: true,});
        }
        else if (name === 'email') {
            let isBirthDateEmpty = !values.birthDate ? true : values.birthDate.trim() === "";
            error = validate[name](value, isBirthDateEmpty);
            if (error !== null) {
                const errorsCopy = {...errors}
                errorsCopy['email'] = error;
                setErrors(errorsCopy);
            }
            setTouched( {...touched, ['email']: true,});
        }
        else if (name === 'homePostCode' || name === 'homeCountryCode') {
            error = validate['homePostCode'](values.homePostCode, values.homeCountryCode);
            if (error !== null) {
                const errorsCopy = {...errors}
                errorsCopy['homePostCode'] = error;
                setErrors(errorsCopy);
            }
            setTouched( {...touched, ['homePostCode']: true,});
        }
        else if (name !== "addressesResults") {
            error = validate[name](value);
            // validate the field if the value has been touched
            setErrors({
                ...rest,
                ...(error && { [name]: touched[name] && error }),
            });
        }

        setRequiredFieldsFormValidity();
    };
      // form submit handler
    const handleSubmit = evt => {
        evt.preventDefault();

        // validate the form
       const formValidation = validateForm();

        setErrors(formValidation.errors);
        setTouched(formValidation.touched);

        if (!Object.values(formValidation.errors).length && // errors object is empty
            Object.values(formValidation.touched).length === Object.values(values).length && // all fields were touched
            Object.values(formValidation.touched).every(t => t === true) // every touched field is true
        ) {
            const relatedProfessionalExperience = getRelatedProfessionalExperience();
            const prospectValues = getProspectValues();
            mutationCallback({variables: { prospect: prospectValues, relatedProfessionalExperience: relatedProfessionalExperience }});
        }
    };

    const getProspectValues = () => {
        let prospectValues = {...values};
        if (!prospectValues.id) {
            prospectValues.perimeter = project.perimeter.id;
        }
        delete prospectValues.id;
        if (!prospectValues.nameBirth) {
            prospectValues.nameBirth = prospectValues.useName;
        }

        delete prospectValues.projectRelatedProfessionalExperience;

        for (const [key, value] of Object.entries(prospectValues)) {
            switch (value) {
                case '': prospectValues[key] = null; break;
                case 'true': prospectValues[key] = true; break;
                case 'false': prospectValues[key] = false; break;
            }
        }

        if (prospectValues?.gpdrConsents) {
            prospectValues.gpdrConsents = JSON.stringify(prospectValues.gpdrConsents);
        }

        if (mode === 'edit' && !touched['perimeter']) {
            delete prospectValues.perimeter;
        }
        return prospectValues;
    }

    const getRelatedProfessionalExperience = () => values.projectRelatedProfessionalExperience !== ''
        ? values.projectRelatedProfessionalExperience
        : null
    ;

    const validateForm = () => {
        return Object.keys(values).reduce(
            (acc, key) => {

                let newError;
                if (key === 'birthDate' || key === 'email') {
                    if (key === 'birthDate') {
                        newError = validate[key](values[key], values.email && values.email.trim() === "");
                    } else if (key === 'email') {
                        newError = validate[key](values[key], values.birthDate && values.birthDate.trim() === "");
                    }
                } else if (key === 'homePostCode') {
                    newError = validate['homePostCode'](values[key], values.homeCountryCode);
                } else if (validate[key]) {
                    newError = validate[key](values[key]);
                }

                const newTouched = {[key]: true};
                return {
                    errors: {
                        ...acc.errors,
                        ...(newError && {[key]: newError}),
                    },
                    touched: {
                        ...acc.touched,
                        ...newTouched,
                    },
                };
            },
            {
                errors: {...errors},
                touched: {...touched},
            },
        );
    }

    const setRequiredFieldsFormValidity = () => {
        let requiredValues = ['gender', 'useName', 'firstName', 'homeCountryCode', 'homeTownshipName'];
        if (values.homeCountryCode === 'FRA') {
            requiredValues.push('homePostCode');
        }
        if ((values.birthDate && values.birthDate.trim().length === 0) && (values.email && values.email.trim().length === 0)) {
            requiredValues.push('birthDate');
            requiredValues.push('email');
        } else if (values.birthDate && values.birthDate.trim().length === 0) {
            requiredValues.push('birthDate');
        } else if (values.email && values.email.trim().length === 0) {
            requiredValues.push('email');
        }

        let isAllOk = true;
        for (let i = 0; i < requiredValues.length; i++) {
            let name = requiredValues[i];
            let error = validate[name](values[name]);
            if (error !== undefined && error !== null && error.length > 0) {
                isAllOk = false;
                break;
            }
        }
        setRequiredFieldValidated(isAllOk);
    }

    const handleSwitch = () => {
        setShowNotRequiredFields(!showNotRequiredFields);
    }

    const setValuesAndTouched = (newValues) => {
        let newErrors = {...errors};
        const newTouchedArray = Object.keys(newValues)
            .filter(k => newValues[k] !== values[k])
            .map(k => {
                newErrors = removeError(newErrors, k);
                return {[k]: true}
            });
        const newTouched = Object.assign({}, ...newTouchedArray);
        setErrors(newErrors);
        setValues(newValues);
        setTouched({
            ...touched,
            ...newTouched
        })
    }

    const [onlyConsents, setOnlyConsents] = useState(mode === "create" || mode === "complete_enf");
    const validConsents = () => setOnlyConsents(false);

    const handleChangeConsents = (evt) => {
        const { name, checked } = evt.target;

        let newConsents = values['gpdrConsents'];
        newConsents[name] = checked;

        setValues({
            ...values,
            gpdrConsents: newConsents
        });
    }

    return <>
        <ListGroupItem className="col-12" tag={"div"}>
            <FormConsentsProspect
                consents={values?.gpdrConsents}
                handleChange={handleChangeConsents}
                displayButton={onlyConsents}
                validConsents={validConsents}
                validConsentsText={'Suivant'}
                consentsSchemas={consentsSchemas}
            />
        </ListGroupItem>
        {
            !onlyConsents
            ? <>
                <FormGroup className={"pull-right pt-3"}>
                    <CustomInput type="switch"
                                 id="showNotRequiredFields"
                                 name="showNotRequiredFields"
                                 checked={showNotRequiredFields}
                                 label="Afficher les champs non requis"
                                 onChange={handleSwitch}
                                 className={"text-info"}/>
                </FormGroup>

                <FormProspect
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setValues={setValuesAndTouched}
                    handleSubmit={handleSubmit}
                    setRequiredFieldValidated={setRequiredFieldsFormValidity}
                    errors={errors}
                    touched={touched}
                    setTouched={setTouched}
                    values={values}
                    mode={mode}
                    full={showNotRequiredFields}
                    project={project}
                    valueFocussed={valueFocussed}
                    requiredFieldsValidated={requiredFieldsValidated}
                    consentsSchemas={consentsSchemas}/>
            </>
            : ''
        }
    </>
}

ProspectFormHandling.propTypes = {
    formValues: PropTypes.array.isRequired,
    validate: PropTypes.func.isRequired,
    mode: PropTypes.oneOf([
        'create',
        'edit',
        'complete_enf' // Completing ENF user's profile
    ]).isRequired,
    project: PropTypes.object,
    mutationCallback: PropTypes.func,
};

export default ProspectFormHandling;
