import React from 'react';
import {ListGroupItem} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

function FundingListItem(props) {
    return (
        <ListGroupItem key={props?.id} tag={"a"} href={'/funding/update/' + props.id} action={true}>
            <div className={"d-flex justify-content-start"}>
                <h4>{props.label}</h4>
            </div>
            <div className={"d-flex justify-content-start"}>
                <span className={"muted mr-3"}>
                    <span><FontAwesomeIcon icon={faMapMarkerAlt}/> </span>
                    {props.perimeter.label}
                </span>
            </div>
        </ListGroupItem>
    )
}

export default FundingListItem;