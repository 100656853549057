import React, {useEffect, useRef, useState} from 'react';

import PropTypes from "prop-types";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {Form} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretSquareDown, faQuoteLeft, faSave, faSpinner,} from "@fortawesome/free-solid-svg-icons";
import {faCaretSquareUp} from "@fortawesome/free-regular-svg-icons";

function ProjectFooterNotes({ project, isViewMode, onChange, setProject }) {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleChange = (newNotes) => {
        onChange(true);
        setProject({
            ...project,
            notes: newNotes
        });
    }

    setFooterHeight(isCollapsed, isViewMode);

    const toggleCollapsed = () => {
        setIsCollapsed(!isCollapsed);
        setFooterHeight(!isCollapsed, isViewMode);
    }

    return (
        <div className="footer-notes">

            <div className="col-12 col-lg-10 pt-1">
                <Form>

                    <div className="d-flex justify-content-start align-items-center" onClick={toggleCollapsed}>
                        <div className="col-12 col-md-6">
                            <h5>
                                <FontAwesomeIcon className={'text-info'}
                                                 icon={isCollapsed ? faCaretSquareUp : faCaretSquareDown}
                                                 size={"lg"}/>
                                <FontAwesomeIcon icon={faQuoteLeft} className="ml-3"/> <em className="ml-2 small">NOTES</em>

                            </h5>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center pt-1 pt-md-3">
                        <div className="col-12 col-lg-10 pl-0 pr-0 text-dark">
                            {
                                isViewMode ?
                                    project.notes ?
                                        <div className="ck-editor-view-mode ml-3"
                                             dangerouslySetInnerHTML={{__html: project.notes}}/>
                                        : <em className="ml-3 ml-lg-5 small">Aucunes</em>
                                    : <CKEditor
                                        editor={ ClassicEditor }
                                        config={ {
                                            toolbar: ['heading', 'bold', 'italic', 'Link', 'numberedList', 'bulletedList']
                                        } }
                                        data={project.notes}
                                        onChange={(event,editor) => {
                                            handleChange(editor.getData());
                                        } } />
                            }
                        </div>
                    </div>
                </Form>

            </div>
        </div>
    );
}

function setFooterHeight (isCollapsed, isViewMode) {
    let docStyle = getComputedStyle(document.documentElement);
    const expanded = docStyle.getPropertyValue("--notes-footer-height-expanded");
    const expandedViewMode = docStyle.getPropertyValue("--notes-footer-height-expanded-view-mode");
    const collapsed = docStyle.getPropertyValue("--notes-footer-height-collapsed");
    let footerHeight = isCollapsed ? collapsed : expanded;
    footerHeight = !isCollapsed && isViewMode ? expandedViewMode : footerHeight;
    document.documentElement.style.setProperty("--notes-footer-height", footerHeight);
}

ProjectFooterNotes.propTypes = {
    project: PropTypes.object.isRequired,
    isViewMode: PropTypes.bool.isRequired
};

export default ProjectFooterNotes;
