import React, {useState} from "react";
import {Document, Image, Page, Text, View, Link} from "@react-pdf/renderer";
import {styles} from "./pdfStylesheet";
import getPositioningCertificateContent from "./models/positioningCertificate";

function BasePdf(props) {
    const [values, setValues] = useState(setContent(props?.typeContent, props?.object, props?.user));

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Header headerValues={values?.header}/>
                <Body bodyValues={values?.body}/>
                <Footer footerValues={values?.footer}/>
            </Page>
        </Document>
    );
}

function Header({headerValues}) {
    return (
        <View style={styles?.sectionHeader} fixed={true}>
            <View style={styles?.headerTop}>
                <View style={styles?.headerInfos}>
                    {
                        headerValues?.header.map((elementHeader) =>
                            <Text>{elementHeader}</Text>
                        )
                    }
                </View>
                <View style={styles?.headerLogoRegion}>
                    <Image style={styles?.headerImage} source={require('../../assets/img/cnam_logo.png')}/>
                    <TitleMaple style={styles?.headerRegion} title={headerValues?.region}/>
                </View>
            </View>
        </View>
    )
}

function Body({bodyValues}) {
    return (
        <View style={styles?.sectionBody}>
            <TitleMaple style={styles?.bodyTitle} title={bodyValues?.title}/>
            {
                bodyValues?.sections?.map((section) =>
                    <>
                        <Text style={styles?.bodySectionTitle}>{section?.title}</Text>
                        {
                            section?.subSections.map((subSection) => {
                                switch (subSection?.type) {
                                    case 'simpleInfos' :
                                        return <SimpleInfosSubSection subSection={subSection} />;
                                    case 'multiColumnInfos' :
                                        return <MultiColumnInfosSubSection subSection={subSection} />
                                    case 'tableInfos' :
                                        return <TableInfosSubSection subSection={subSection}/>;
                                    default :
                                        return getAvailableText();
                                }
                            })
                        }
                    </>
                )
            }
        </View>
    )
}

function Footer({footerValues}) {
    return (
        <View style={styles?.sectionFooter} fixed={true}>
            <Text style={styles?.footerContent}>{footerValues?.content}</Text>
        </View>
    )
}

function SubSectionHeader({subSection}) {
    return <>
        {
            subSection?.subSectionTitle
                ? <Text style={styles?.bodySubSectionTitle}>{subSection?.subSectionTitle}</Text>
                : <></>
        }
        {
            subSection?.subSectionSubTitle
                ? <Text style={styles?.bodySubSectionSubTitle}>{subSection?.subSectionSubTitle}</Text>
                : <></>
        }
        {
            subSection?.subSectionMention
                ? <Text style={styles?.bodySubSectionMention}>{subSection?.subSectionMention}</Text>
                : <></>
        }
    </>
}

function SimpleInfosSubSection({subSection}) {
    return <View style={styles?.bodySubSection}>
        <SubSectionHeader subSection={subSection} />
        {
            subSection?.content === null || !subSection?.content.length
                ? subSection?.subSectionSubTitle ? <></> : getEmptyText()
                : <View style={styles?.bodySimpleInfos}>
                    {
                        subSection?.content?.map((line) =>
                            <TextHtml style={styles?.bodySimpleInfos_line} html={line}/>
                        )
                    }
                </View>
        }
    </View>;
}

function TextHtml({style, html}) {
    const htmlDom = (new DOMParser()).parseFromString(html, "text/html");
    let lines = [];

    // New line if <p>, <div>, <section>, <article>, <h*> are found in :scope and add some style according to pdfStylesheet.js
    htmlDom.querySelectorAll(':scope p,:scope div,:scope section,:scope article, :scope h1, :scope h2, :scope h3, :scope h4').forEach((value, key, parent) => {
        let content = [];
        // allow to style some markup (<strong>, <i> ...) according to pdfStylesheet.js
        for (let i=0; i<value.childNodes.length; i++) {
            content[content.length] = {
                textContent: value.childNodes[i].textContent,
                nodeName: value.childNodes[i].nodeName.toLowerCase(),
                attr: value.childNodes[i].nodeName.toLowerCase() === 'a'
                    ? {href: value.childNodes[i].attributes.getNamedItem('href').value}
                    : {}
            };
        }
        lines[lines.length] = {content: content, nodeName: value.nodeName.toLowerCase()};
    })

    return (
        <View style={style}>
            {lines.map(line => <Text style={styles[line.nodeName] ?? {}}>
                {line.content.map(text => <Text style={styles[text.nodeName] ?? {}}>
                    {text.nodeName === 'a' ? <Link src={text.attr.href}>{text.textContent}</Link> : text.textContent}
                </Text>)}
            </Text>)}
        </View>
    )
}

function MultiColumnInfosSubSection({subSection}) {
    return <View style={styles?.bodySubSection}>
        <SubSectionHeader subSection={subSection} />
        {
            subSection?.content === null || !subSection?.content.length
                ? subSection?.subSectionSubTitle ? <></> : getEmptyText()
                : <>
                    {
                        subSection?.content?.map((line) =>
                            <View style={styles?.bodyMultiColumnInfos_line}>
                                {
                                    line?.map((element) =>
                                        <View style={styles?.bodyMultiColumnInfos_element}>
                                            <Text style={styles?.bodyMultiColumnInfos_elementTitle}>{element.titleInfo} : </Text>
                                            {
                                                Array.isArray(element.data)
                                                    ?
                                                    <View style={styles?.bodyMultiColumnInfos_elementMultipleData}>
                                                        {
                                                            element.data.map((dataLine) =>
                                                                <Text>
                                                                    {dataLine.content}
                                                                    <Text
                                                                        style={styles?.moreInfo}>{dataLine.moreInfo}</Text>
                                                                </Text>
                                                            )
                                                        }
                                                    </View>
                                                    :
                                                    <Text style={styles?.bodyMultiColumnInfos_elementData}>
                                                        {element.data} <Text style={styles?.moreInfo}>{element.moreInfo}</Text>
                                                    </Text>
                                            }
                                        </View>
                                    )
                                }
                            </View>
                        )
                    }
                </>
        }
    </View>;
}

function TextWeight({children, weight}) {
    return (
        <Text style={{fontWeight: weight}}>
            {children}
        </Text>
    )
}

function TitleMaple({style, title}) {
    return (
        <>
            {title.length >= 18 && <Text style={style}>
                <TextWeight weight={'light'}>{title.substring(0, title.length - 9)}</TextWeight>
                <TextWeight weight={'normal'}>
                    {title.substring(title.length - 9, title.length - 6)}
                </TextWeight>
                <TextWeight weight={'medium'}>
                    {title.substring(title.length - 9 + 3, title.length - 3)}
                </TextWeight>
                <TextWeight weight={'bold'}>
                    {title.substring(title.length - 9 + 3 + 3)}
                </TextWeight>
            </Text>}
            {title.length < 18 && title.length > 3 && <Text style={style}>
                <TextWeight weight={'light'}>{title.substring(0, title.length - 3)}</TextWeight>
                <TextWeight weight={'normal'}>
                    {title.substring(title.length - 3, title.length - 2)}
                </TextWeight>
                <TextWeight weight={'medium'}>
                    {title.substring(title.length - 2, title.length - 1)}
                </TextWeight>
                <TextWeight weight={'bold'}>
                    {title.substring(title.length - 1)}
                </TextWeight>
            </Text>}
            {title.length <= 3 && <Text style={style}>{title}</Text>}
        </>
    )
}

function Table({header, rows, sizes}) {
    const colsLength = header.length;
    const rowsLength = rows.length;
    return (
        <View style={styles.table}>
            <View style={{...styles.tr, borderBottomWidth: 2}}>
                {header.map((text, index) => {
                    const styleTh = (index === colsLength - 1) ? styles.thLast : styles.th;
                    return <Text style={{...styleTh, width: `${sizes[index]}%`}}>{text}</Text>}
                )}
            </View>
            {rows.map((row, index) => {
                const styleTr = (index === rowsLength - 1) ? styles.trLast : styles.tr;
                return (
                    <View style={styleTr}>
                        {row.map((text, index) => {
                                const styleTd = (index === colsLength - 1) ? styles.tdLast : styles.td;
                                return <Text style={{...styleTd, width: `${sizes[index]}%`}}>{text}</Text>
                        })}
                    </View>
                )
            })}
        </View>
    );
}

function TableInfosSubSection({subSection}) {
    if (!subSection?.content) {
        return <View style={styles?.bodySubSection}>
            <SubSectionHeader subSection={subSection} />
            {
                getEmptyText()
            }
        </View>
    }

    const tableHead = subSection?.content?.tableBody?.map((column, index) => subSection?.content?.tableHead[index]);
    const tableColumnsToRows = (columnToRows, col, index) => {
        for (let i=0; i<col.length; i++) {
            if (!columnToRows[i]) {
                columnToRows[i] = {};
            }
            columnToRows[i][index] = col[i].content ? col[i].content[0] : col[i][0];
        }
        return columnToRows;
    };
    const rows = Object.values(subSection?.content?.tableBody.reduce(tableColumnsToRows, {})).map(v => Object.values(v));
    const sizes = subSection?.content.sizing;

    return <View style={styles?.bodySubSection}>
        <SubSectionHeader subSection={subSection} />
        {
            !subSection?.content?.tableBody?.length
                ? subSection?.subSectionSubTitle ? <></> : getEmptyText()
                : <Table header={tableHead} rows={rows} sizes={sizes} />
        }
    </View>;
}

function setContent(typeContent, object = null, currentUser = null) {
    if (object === null) return {
        body: {title: "Objet non défini : aucune information à générer."}
    };

    let content = {
        header: {},
        body: {},
        footer: {}
    }
    switch (typeContent) {
        case "attestationPositionnement":
            content = getPositioningCertificateContent(object, currentUser);
            break;
        default:
            content.header = {
                title: "Contenu non disponible",
            }
            break;
    }
    return content;
}

function getAvailableText() {
    return (
        <Text style={styles?.notAvailable}>
            Non disponible pour le moment.
        </Text>
    );
}

function getEmptyText() {
    return (
        <Text style={styles?.emptyText}>
            Il n'y a aucune information enregistrée concernant cette section.
        </Text>
    );
}

export default BasePdf;