import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBookOpen,
    faChevronDown,
    faChevronUp,
    faRedoAlt
} from "@fortawesome/free-solid-svg-icons";
import FormSearchCursus from "../Form/FormSearchCursus";
import {Button, ButtonGroup, Collapse} from "reactstrap";
import uuidv4 from "../../lib/security/uuidv4";
import SchemaVisualizer from "../Schema/SchemaVisualizer";
import ToggleSectionTitle from "./ToggleSectionTitle";
import PropTypes from "prop-types";


function ProjectSectionFormation({
                                     edit,
                                     schema,
                                     setSchema,
                                     cursus,
                                     setCursus,
                                     collapseOpen,
                                     toggleCollapse,
                                     fundingsPerimeter,
                                     onChange
                                 }) {
    const setCollapseOpen = b => {
        if (collapseOpen !== b) {
            toggleCollapse();
        }
    }

    const [formOpen, setFormOpen] = useState(edit && schema.length === 0);
    const toggleFormOpen = () => setFormOpen(!formOpen);
    const schemaEmpty = schema.length === 0;

    useEffect(() => {
        return () => setFormOpen(false); // hide form when edit state change
    }, [edit]);

    return <>
        <div className="d-md-flex flex-row justify-content-between align-items-center text-dark">

            <ToggleSectionTitle
                title={'FORMATION VISÉE'}
                icon={faBookOpen}
                toggleFunction={toggleCollapse}
            />

            { /* choose a cursus or create an empty schema */
                edit && (schemaEmpty || formOpen) && <div className={'flex-grow-1'}>
                    <FormSearchCursus onSubmit={(cursus) => {
                        setCursus(cursus);
                        onChange(true)
                        setFormOpen(false);
                        setSchema(JSON.parse(cursus.schema.schema));
                        setCollapseOpen(true);
                    }}/>
                    <hr/>
                    <Button onClick={() => {
                        setSchema({
                            type: 'schema',
                            credits: 0,
                            schema: [],
                            extras: {'id': uuidv4()}
                        });
                        setCursus(null);
                        setFormOpen(false);
                        setCollapseOpen(true);
                    }
                    } block color={'primary'}>
                        A la carte
                    </Button>
                    {(edit && schema.length === 0) || formOpen && <Button onClick={toggleFormOpen} block>Annuler</Button>}
                </div>
            }

            { /* if schema exists, show an abstract of it */
                !schemaEmpty && (!edit || !formOpen) && <h6 className="flex-grow-1">
                    {cursus
                        ? <span>Cursus ({cursus.code})</span>
                        : <span>Enseignements à la carte ({schema.credits ?? 0} ECTS)</span>
                    }
                </h6>
            }

            {
                /* if schema does not exist, show something */
                !edit && schemaEmpty && <h6 className={'flex-grow-1'}>Aucune</h6>
            }

            {
                /* button edit when schema exist */
                schema.length !== 0 && !formOpen &&
                <ButtonGroup size={'sm'}>
                    {edit && <Button color={'primary'} outline onClick={toggleFormOpen}>
                        <FontAwesomeIcon icon={faRedoAlt}/>
                        <span> Remplacer</span>
                    </Button>}
                    <Button
                        color="info"
                        outline
                        onClick={toggleCollapse}>
                        {
                            collapseOpen
                                ? <em>Fermer <FontAwesomeIcon icon={faChevronUp}/></em>
                                : <em>Afficher <FontAwesomeIcon icon={faChevronDown}/></em>
                        }
                    </Button>
                </ButtonGroup>

            }
        </div>

        <Collapse isOpen={!formOpen && collapseOpen}>
            <SchemaVisualizer schema={schema}
                              edit={edit}
                              canAdd={!cursus}
                              perimeter={fundingsPerimeter.id}
                              setSchema={setSchema}
                              setTouched={onChange}/>
        </Collapse>
    </>
}

ProjectSectionFormation.propTypes = {
    edit: PropTypes.bool,
    schema: PropTypes.object.isRequired,
    setSchema: PropTypes.func,
    cursus: PropTypes.object,
    setCursus: PropTypes.func,
    collapseOpen: PropTypes.bool,
    toggleCollapse: PropTypes.func.isRequired,
    fundingsPerimeter: PropTypes.object
};

ProjectSectionFormation.defaultProps = {
    edit: false,
    setSchema: () => {},
    setCursus: () => {},
    collapseOpen: false,
}

export default ProjectSectionFormation;
