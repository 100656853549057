import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Redirect} from "react-router-dom";

function ErrorPage(props) {
    const [redirectDashboard, setRedirectDashboard] = useState(false);

    const returnDashboard = () => {
        setRedirectDashboard(true);
        setTimeout(() => {
            const returnedValue = props?.resetErrors(false);
        }, 30);
    }

    return (
        <div className={"d-flex cr-page px-3 px-md-0"}>
            <div className={"mt-5"}>
                <h1>Oups</h1>

                <p>Une erreur s'est produite. Vous pouvez réessayer. Si le problème continue,
                    merci de prendre contact avec le service technique.<br/>
                </p>
                <div className={"d-flex flex-column flex-sm-row justify-content-end"}>
                    <button
                        className={"btn btn-primary mb-2 mb-sm-0 mr-0 mr-sm-2"}
                        onClick={returnDashboard}
                    >
                        <FontAwesomeIcon
                            icon={['far', 'compass']}
                            size="sm"
                            className={"mr-2"}
                        />
                        Retour à l'accueil
                    </button>
                    <a className={"btn btn-secondary"} href={"mailto:sifa.compas@cnam.fr"}>
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            size="sm"
                            className={"mr-2"}
                        />
                        Service technique
                    </a>
                </div>


                <div className={"d-flex justify-content-end"}>

                </div>

                {
                    redirectDashboard
                        ? <Redirect to="/" />
                        : ''
                }
            </div>
        </div>
    );
}
export default ErrorPage;