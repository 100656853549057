import React from 'react';
import {useQuery} from "@apollo/client";
import Header from "../components/Layout/Header";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {GET_PROSPECT_BY_ID_OR_ENFUID} from "../graphql/queries";
import {CollapsedProspectData} from '../components/Section/ProjectSectionProspect';
import UserEnfCard from "../components/Card/UserEnfCard";
import ProjectListGroupItem from "../components/List/ProjectListGroupItem";
import WidgetCard from "../components/Card/WidgetCard";
import CreateProjectButton from "../components/Button/CreateProjectButton";
import LoadingSpinner from "../components/Default/LoadingSpinner";
import BackButton from "../components/Button/BackButton";
import FormConsentProspectWrapper from "../components/Form/FormConsentProspectWrapper";

function ProspectOverView(props) {
    const prospectUsingEnf = !(/^\d+$/.test(props.match.params.id));
    const id = prospectUsingEnf ? props.match.params.id : parseInt(props.match.params.id);

    const getProspectVariables = prospectUsingEnf ? {enfUid: id} : {id: id};
    const { loading, error, data } = useQuery(
        GET_PROSPECT_BY_ID_OR_ENFUID,
        { variables: { ...getProspectVariables } }
    );

    const prospect = data?.Prospects[0];
    const notFound = !prospectUsingEnf && !prospect;

    if (loading) {
        return (
            <div className={"container-fluid"}>

                <Header titlePage={"PROSPECT"}
                        faIcon={['fas', 'user-graduate']}
                        objectID={id}
                />

                <div className="row justify-content-center">
                    <LoadingSpinner />
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className={"container-fluid"}>

                <Header titlePage={"PROSPECT"}
                        faIcon={['fas', 'user-graduate']}
                        objectID={id}
                />

                <div className={"d-flex flex-row justify-align-content"}>
                    <p>Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
                </div>
            </div>
        )
    }

    if (notFound) {
        return (
            <div className={"container-fluid"}>

                <Header titlePage={"PROSPECT"}
                        faIcon={['fas', 'user-graduate']}
                        objectID={id}
                />

                <div className="row justify-content-center">
                    <h6 className="text-muted">Le prospect n°{id} est introuvable</h6>
                </div>
            </div>
        )
    }

    return (
        <div className={"container-fluid"}>
            <Header titlePage={"PROSPECT"}
                    faIcon={['fas', 'user-graduate']}
                    objectID={props.match.params.id}
            />
            <BackButton className={'mb-2'} onClick={() => props.history.goBack()} />

            <div className={'mb-2'}>
                <WidgetCard title='Détails'>
                    {
                        !prospectUsingEnf
                            ? <>
                                <CollapsedProspectData prospect={prospect} />
                                <FormConsentProspectWrapper
                                    prospect={prospect}
                                    className={'p-1 pt-3'}
                                />
                            </>
                            : <UserEnfCard uid={props.match.params.id}/>
                    }
                </WidgetCard>
            </div>

            <CreateProjectButton enfUid={prospectUsingEnf ? id : null}
                                 prospect={prospect}
                                 title={"créer un projet"}
                                 color={"primary"}
                                 faIcon={faPlus}
                                 withAnimation={true}/>

            {
                !prospectUsingEnf &&
                <WidgetCard title='Projets'>
                    {prospect?.projects.map(p => <ProjectListGroupItem props={{...p}}/>)}
                </WidgetCard>
            }
        </div>
    );
}

export default ProspectOverView;
