import React, {useContext, useState} from 'react';
import Header from "../components/Layout/Header";
import {Button, Form, Input, FormGroup, Alert} from "reactstrap";
import UserContext from "../contexts/UserContext";
import auth from "../lib/security/auth";
import WidgetCard from "../components/Card/WidgetCard";
import {faExternalLinkAlt, faInfoCircle, faSignInAlt, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function LoginPage() {
    const { onLogin } = useContext(UserContext);
    const [username, setUsername] = useState(null);
    const [error, setError] = useState(false);

    const handleChange = e => {
        setUsername(e.target.value);
    }

    const handleLogin = () => {
        setError(false);
        auth.fetchToken(username).then(() => {
            if (auth.getUser() !== null) {
                onLogin()
            } else {
                setError(true);
            }
        });
    }

    return (
        <div className={"container-fluid"}>
            <Header titlePage={"Connexion"}
                    faIcon={['fas', 'sign-in-alt']}
            />

            { process.env.NODE_ENV !== 'development' && <Alert color={'info'}>
                <FontAwesomeIcon icon={faInfoCircle}/> Cette application est accessible, aux personnels des Cnam, via l'Espace Numérique de Formation (ENF) et à condition que des droits vous aient été accordés.
            </Alert> }

            <div className={"d-flex align-items-center justify-content-center"} style={{minHeight: "40vh"}}>
                <WidgetCard className={"col-12 col-lg-6 col-xl-4"} faIcon={faUserCircle} title={"Connexion"}>
                    { error ? <Alert color={"danger"}>Erreur de connexion</Alert> : ""}
                    <Form>
                        {
                            process.env.NODE_ENV === 'development'
                                ? <FormGroup>
                                    <Input placeholder={"UID ENF"} type={"text"} value={username} name={"username"} onChange={handleChange} />
                                </FormGroup>
                                : ""
                        }
                        <Button block={true} color={"primary"} onClick={handleLogin}>
                            <FontAwesomeIcon icon={faSignInAlt}/> Se connecter
                        </Button>
                        <a href={process.env.REACT_APP_ENF_URI} className={'btn btn-block btn-outline-info'}>
                            <FontAwesomeIcon icon={faExternalLinkAlt}/> Accéder à l'ENF
                        </a>
                    </Form>
                </WidgetCard>
            </div>
        </div>
    );
}
export default LoginPage;
