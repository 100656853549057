const messages = {
    middleNames: {
        fr: 'Autres prénoms',
        en: 'Middle names',
    },
    enfUid: {
        fr: 'UID ENF',
        en: 'UID ENF',
    },
    perimeter: {
        fr: 'Périmètre',
        en: 'Perimeter',
    },
    projects: {
        fr: 'Projets',
        en: 'Projects',
    },
    creator: {
        fr: 'Créateur',
        en: 'Creator',
    },
    gender: {
        fr: 'Genre',
        en: 'Gender',
    },
    firstName: {
        fr: 'Prénom',
        en: 'First name',
    },
    birthDate: {
        fr: 'Date de naissance',
        en: 'Birth date',
    },
    birthCountryCode: {
        fr: 'Pays de naissance',
        en: 'Birth country',
    },
    birthTownshipCode: {
        fr: 'Code de la ville de naissance',
        en: 'Birth township',
    },
    nationality: {
        fr: 'Nationalité',
        en: 'Nationality',
    },
    telephone: {
        en: 'Phone number',
    },
    homeAddress: {
        fr: 'Adresse postale domicile',
        en: 'Home address',
    },
    homeAddressAdditional: {
        fr: 'Complément d\'adresse',
        en: 'Home address additional',
    },
    homeTownshipName: {
        fr: 'Ville domicile',
        en: 'Home township name',
    },
    homePostCode: {
        fr: 'Code postal domicile',
        en: 'Home post code',
    },
    homeCountryCode: {
        fr: 'Pays domicile',
        en: 'Home country'
    },
    professionalStatus: {
        fr: 'Statut professionnel',
        en: 'Professional status',
    },
    lookingForJob: {
        fr: 'En recherche d\'emploi',
        en: 'Looking for job',
    },
    registeredAtEmploymentCenter: {
        fr: 'Inscrit à pôle emploi',
        en: 'Registered at employment center',
    }
};

const fallback = 'fr';

const trans = (message, language = fallback) => {
    return messages[message] === undefined || messages[message][language] === undefined
        ? message
        : messages[message][language];
}

export default trans;