export const getProspectInput = prospect => {
    return {
        id: prospect?.id,
        enfUid: prospect?.enfUid,
        perimeter: prospect?.perimeter?.id,
        gender: prospect?.gender,
        nameBirth: prospect?.nameBirth ?? prospect?.useName,
        useName: prospect?.useName,
        firstName: prospect?.firstName,
        middleNames: prospect?.middleNames,
        birthDate: prospect?.birthDate,
        birthCountryCode: prospect?.birthCountryCode?.iso3Code,
        birthTownshipCode: prospect?.birthTownshipCode,
        birthTownshipName: prospect?.birthTownshipName,
        nationality: prospect?.nationality?.iso3Code,
        email: prospect?.email,
        telephone: prospect?.telephone,
        homeAddress: prospect?.homeAddress,
        homeAddressAdditional: prospect?.homeAddressAdditional,
        homeTownshipName: prospect?.homeTownshipName,
        homePostCode: prospect?.homePostCode,
        homeCountryCode: prospect?.homeCountryCode?.iso3Code,
        professionalStatus: prospect?.professionalStatus?.id,
        highestDiplomaLevel: prospect?.highestDiplomaLevel?.id,
        professionalExperience: prospect?.professionalExperience?.id,
        lookingForJob: prospect?.lookingForJob,
        registeredAtEmploymentCenter: prospect?.registeredAtEmploymentCenter,
        gpdrConsents: prospect?.gpdrConsents
    }
}

export const getProspectInputFromUserEnf = (prospect, perimeter) => {
    return {
        id: prospect?.id,
        enfUid: prospect?.enfUid,
        perimeter: perimeter.id,
        gender: prospect?.gender,
        nameBirth: prospect?.nameBirth,
        useName: prospect?.useName,
        firstName: prospect?.firstName,
        middleNames: prospect?.middleNames,
        birthDate: prospect?.birthDate,
        birthCountryCode: prospect?.birthCountryCode?.iso3Code,
        birthTownshipCode: prospect?.birthTownshipCode,
        birthTownshipName: prospect?.birthTownshipName,
        nationality: prospect?.nationality?.iso3Code,
        email: prospect?.email,
        telephone: prospect?.telephone,
        homeAddress: prospect?.homeAddress,
        homeAddressAdditional: prospect?.homeAddressAdditional,
        homeTownshipName: prospect?.homeTownshipName,
        homePostCode: prospect?.homePostCode,
        homeCountryCode: prospect?.homeCountryCode?.iso3Code,
        professionalStatus: prospect?.professionalStatus?.id,
        highestDiplomaLevel: prospect?.highestDiplomaLevel?.id,
        professionalExperience: prospect?.professionalExperience?.id,
        lookingForJob: prospect?.lookingForJob,
        registeredAtEmploymentCenter: prospect?.registeredAtEmploymentCenter,
        gpdrConsents: prospect?.gpdrConsents
    }
}

export const getProspectInputForUpdate = prospectInput => {
    let prospectInputWithoutPerimeter = {...prospectInput};
    if (!Number.isNaN(prospectInput.id)) {
        delete prospectInputWithoutPerimeter.perimeter;
    }
    delete prospectInputWithoutPerimeter.id;
    return prospectInputWithoutPerimeter;
}

export default {getProspectInput, getProspectInputForUpdate, getProspectInputFromUserEnf}
