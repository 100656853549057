import React from 'react';
import {ListGroupItem} from "reactstrap";
import {
    faMapMarkerAlt,
    faChalkboardTeacher,
    faCalendarAlt,
    faStar

} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const temporalities = {1: '1er Semestre', 2: '2ème Semestre', 4: 'Annuel'};
const teachingModalities = {
    'f': 'FOAD',
    'j': 'TO',
    'w': 'HTO',
    's': 'Stage intensif',
    'fw': 'FOAD/HTO',
    'fj': 'FOAD/TO'
};

function EnrolOptionListItem({option, index, onClick, active}) {
    const {perimeter} = option;
    return (
        <ListGroupItem className={`${active ? 'enrol-option-active d-flex pl-1' : ''}`} onClick={onClick} key={index}
                       tag="div" action>
            {active && <div className={'d-flex flex-column justify-content-center align-items-center mr-2'}><FontAwesomeIcon size={'lg'} className={'text-ens'} icon={faStar}/></div>}
            <div className={'w-100'}>
                <div className={"mb-2 d-flex flex-column flex-md-row justify-content-start"}>
                    <span className={'col-md-4 col-lg-3 pl-0'}><FontAwesomeIcon
                        icon={faChalkboardTeacher}/> {teachingModalities[option.teachingModality]} </span>
                    <span className={'pl-0'}><FontAwesomeIcon
                        icon={faCalendarAlt}/> {option.year}/{option.year + 1} - {temporalities[option.temporality] ?? option.temporality}</span>
                </div>
                <div className={"d-flex justify-content-between"}>
                    <small>
                        <span><FontAwesomeIcon icon={faMapMarkerAlt}/> </span>
                        {perimeter.type.id === 'CEN'
                            ? <span className={'ml-1'}> {perimeter.label} ({perimeter.parent.id})</span>
                            : <span className={'ml-1'}> {perimeter.label}</span>
                        }
                    </small>
                </div>
            </div>
        </ListGroupItem>
    )
}

export {temporalities, teachingModalities, EnrolOptionListItem};
