import React, {useState} from "react";
import {faCodeBranch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

function Branches({schema, children, onUpdate}) {
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const activeBrancheSchema = schema.extras.activeBrancheId
        ? schema.branches.filter(b => b.extras.id === schema.extras.activeBrancheId).pop()
        : schema.branches[0];

    const handleBranche = (e) => onUpdate(schema.extras.id, 'activeBrancheId', e.currentTarget.dataset.id, null, false, false);

    return <div className={'schema-branches'}>
        <div className={'d-flex justify-content-between align-items-start'}>
            <div className={'d-flex justify-content-between'}>
                <span className={'text-muted'}>{activeBrancheSchema.libelle}</span>
                <span className={'mt-1 ml-3 badge badge-info rounded'}>{activeBrancheSchema.credits} ECTS</span>
            </div>
            <ButtonDropdown isOpen={toggleDropdown} toggle={() => setToggleDropdown(!toggleDropdown)}>
                <DropdownToggle caret color={'info'}>
                    <FontAwesomeIcon size={'lg'} icon={faCodeBranch}/>
                    <span> {schema.nombre_de_branche}</span>
                </DropdownToggle>
                <DropdownMenu>
                    {schema.branches.map(b =>
                        <DropdownItem onClick={handleBranche} data-id={b.extras.id}>{b.libelle ?? b.extras.id}</DropdownItem>
                    )}
                </DropdownMenu>
            </ButtonDropdown>

        </div>
        {children.filter(b => b.props.schema.extras.id === activeBrancheSchema.extras.id)}
    </div>
}

export default Branches;