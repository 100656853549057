import {StyleSheet, Font} from "@react-pdf/renderer";
import MapleLight from "../../assets/fonts/Maple-Light.ttf";
import MapleLightItalic from "../../assets/fonts/Maple-LightItalic.ttf";
import MapleRegular from "../../assets/fonts/Maple-Regular.ttf";
import MapleRegularItalic from "../../assets/fonts/Maple-RegularItalic.ttf";
import MapleMedium from "../../assets/fonts/Maple-Medium.ttf";
import MapleMediumItalic from "../../assets/fonts/Maple-MediumItalic.ttf";
import MapleBold from "../../assets/fonts/Maple-Bold.ttf";

export const colors = {
    text: 'black',
    colorMain: '#C1002A',
    colorSecondary: '#857761',
    infoColor: '#cfc8c0',
    tableHeadColor: "#5B5B5B",
    tableColor: "grey",
}
export const sizeText = {
    titlePage: '18',
    titleSection: "14",
    normal: '10',
    info: '8',
}

const cell = {
    borderRightWidth: 1,
    borderColor: colors.colorSecondary,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: 2,
    paddingLeft: 3,
    color: colors.colorSecondary
};

const row = {
    borderBottomWidth: 1,
    borderColor: colors.colorSecondary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
}

const conclusionLine = {
    marginBottom: 10,
    textAlign: 'justify'
}

Font.register({
    family: 'Maple',
    fonts: [
        { src: MapleRegular },
        { src: MapleRegularItalic, fontStyle: 'italic'},
        { src: MapleLight, fontWeight: 300},
        { src: MapleLightItalic, fontWeight: 300, fontStyle: 'italic'},
        { src: MapleMedium, fontWeight: 500},
        { src: MapleMediumItalic, fontWeight: 500, fontStyle: 'italic'},
        { src: MapleBold, fontWeight: 600 },
    ]
});

export const styles = StyleSheet.create({
    page: {
        flexDirection: 'column'
    },
    moreInfo: {
        fontSize: sizeText?.info
    },
    notAvailable: {
        padding: 10,
        paddingTop: 0,
        textAlign: 'center',
        fontSize: sizeText.normal,
        color: colors?.infoColor ?? 'lightgrey'
    },
    emptyText: {
        padding: 10,
        paddingTop: 0,
        fontSize: sizeText.normal,
        color: colors?.infoColor ?? 'lightgrey'
    },

    //HEADER ===================================================
    sectionHeader: {
        fontFamily: 'Maple',
        padding: 15,
        paddingLeft: 30,
        fontSize: sizeText.normal
    },
    headerTop: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
        color: colors?.infoColor ?? 'lightgrey'
    },
    headerInfos: {
        fontSize: sizeText.info
    },
    headerLogoRegion: {
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'end',
        alignItems: 'end'
    },
    headerImage: {
        width: '130px',
        height: 'auto',
        marginLeft: 'auto',
        marginBottom: '5px'
    },
    headerRegion: {
        marginLeft: 'auto',
        fontSize: 12
    },
    headerTitle: {
        textAlign: 'right',
        fontSize: sizeText.titlePage,
        color: colors?.colorMain ?? 'black'
    },

    //BODY ===================================================
    sectionBody: {
        fontFamily: 'Maple',
        fontSize: sizeText.normal,
        flexGrow: 1
    },
    bodyTitle: {
        padding: 10,
        paddingBottom: 15,
        textAlign: 'right',
        fontSize: sizeText.titlePage,
        color: colors?.colorMain ?? 'black'
    },
    bodySectionTitle: {
        backgroundColor: colors?.colorMain ?? 'black',
        fontSize: sizeText.titleSection,
        color: 'white',
        padding: 30,
        paddingTop: 3,
        paddingBottom: 5
    },
    bodySubSection: {
        padding: 30,
        paddingTop: 10,
        paddingBottom: 5
    },
    bodySubSectionTitle: {
        color: colors?.colorMain ?? 'black',
        fontSize: sizeText?.titleSection,
        marginBottom: 10
    },
    bodySubSectionSubTitle: {
        marginBottom: 10,
        color: colors?.colorSecondary ?? 'black'
    },
    bodySubSectionMention: {
        marginBottom: 10,
        fontStyle: 'italic',
        color: colors?.colorSecondary ?? 'black'
    },
    //SubSection type=simpleInfos
    bodySimpleInfos: {
        marginBottom: 5
    },
    bodySimpleInfos_line: {
        marginBottom: 5,
        color: colors?.colorSecondary ?? 'black',
        textAlign: 'justify',
    },
    //SubSection type=multiColumnInfos
    bodyMultiColumnInfos_line: {
        display: 'flex',
        flexDirection: 'row'
    },
    bodyMultiColumnInfos_element: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flex: 1,
        padding: 5,
        marginBottom: 5
    },
    bodyMultiColumnInfos_elementTitle: {
        color: colors?.infoColor ?? 'lightgrey'
    },
    bodyMultiColumnInfos_elementData: {
        color: colors?.colorSecondary ?? 'grey'
    },
    bodyMultiColumnInfos_elementMultipleData: {
        display: 'flex',
        flexDirection: 'column',
        color: colors?.colorSecondary ?? 'grey'
    },
    //SubSection type=tableInfos
    bodyTableInfos: {
        display: 'table-row',
        verticalAlign: 'middle',
        color: colors?.colorSecondary ?? 'grey'
    },
    table: {
        width: '100%',
        borderWidth: 1,
        borderColor: colors.colorSecondary,
        display: 'flex',
        flexDirection: 'column',
    },
    tr: row,
    trLast: {...row, borderBottomWidth: 0},
    td: cell,
    tdLast: {...cell, borderRightWidth: 0},
    th: {...cell, fontWeight: 800},
    thLast: {...cell, fontWeight: 800, borderRightWidth: 0},
    bodyTableInfos_head: {
        display: 'table-cell',
        borderBottom: '2px solid ' + (colors?.tableHeadColor ?? 'grey')
    },
    bodyTableInfos_row: {
        display: 'table-row'
    },
    bodyTableInfos_column: {
        display: 'table-column'
    },
    bodyTableInfos_body: {
        flexGrow: 1
    },
    bodyTableInfos_element: {
        display: 'table-cell'
    },

    //FOOTER
    sectionFooter: {
        padding: 30,
        paddingTop: 20,
        paddingBottom: 20
    },
    footerContent: {
        fontSize: sizeText.info,
        textAlign: 'justify',
        color: colors?.infoColor ?? 'lightgrey'
    },

    // Conclusion content
    h1: {
        ...conclusionLine,
        fontWeight: 'bold',
        fontSize: 16
    },
    h2: {
        ...conclusionLine,
        fontWeight: 'bold',
        fontSize: 14
    },
    h3: {
        ...conclusionLine,
        fontWeight: 'bold',
        fontSize: 12
    },
    h4: {
        ...conclusionLine,
        fontSize: 12
    },
    h5: {
        ...conclusionLine,
        fontSize: 12
    },
    div: conclusionLine,
    p: conclusionLine,
    section: conclusionLine,
    article: conclusionLine,
    strong: { fontWeight: 'bold' },
    b: { fontWeight: 'bold' },
    i: { fontStyle: 'italic' },
    a: { color: colors.colorMain, fontWeight: 300 },
    br: conclusionLine
});