import React, {useState} from 'react';
import ReactDOM from 'react-dom';

import {ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "apollo-link-error";
import {Observable} from "apollo-link";

import App from './App';

import * as serviceWorker from './serviceWorker';

import './styles/scss/index.scss';
import './styles/icons/fontawesome';

import { from } from '@apollo/client';

import auth from './lib/security/auth';
import UserContext from "./contexts/UserContext";
import { createUploadLink } from 'apollo-upload-client';

const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_API_URI,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists or fetch a new one
    const promiseToken = auth.getToken('');
    // return the headers to the context so link can read them
    return promiseToken.then(token => {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
                cors: 'no-cors'
            }
        }
    })
});

function Root() {
    const [errorServer, setErrorServer] = useState(false);
    const onSetErrorServer = () => {
        setErrorServer(false);
        return false;
    }

    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({message, locations, path}) => {
                if (message === 'Internal server Error'){
                    setErrorServer(true);
                }
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            });
            return forward(operation);
        }
        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
            if (networkError.statusCode === 401) { // Unauthorized
                auth.unsetToken();
                onLogout();
            } else if (networkError.statusCode >= 500 || networkError.statusCode === undefined){
                setErrorServer(true);
            }
            return Observable.of(operation)
        }
    });

    const client = new ApolloClient({
        link: from([
            errorLink,
            authLink,
            uploadLink
        ]),
        cache: new InMemoryCache(),
    });

    const [currentUser, setCurrentUser] = useState(auth.getUser);

    const onLogin = () => {
        setCurrentUser(auth.getUser());
    }

    const onLogout = () => {
        setCurrentUser(null);
        if (process.env.NODE_ENV !== 'development') {
            window.location.replace(process.env.REACT_APP_API_URI + '/Shibboleth.sso/Logout');
        }
    }

    return (
        <React.StrictMode>
            <ApolloProvider client={client}>
                <UserContext.Provider value={{ currentUser: currentUser, onLogin: onLogin, onLogout: onLogout }}>
                   <App errorServer={errorServer} onSetErrorServer={onSetErrorServer}/>
                </UserContext.Provider>
            </ApolloProvider>
        </React.StrictMode>
    )
}

ReactDOM.render(
    <Root/>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
