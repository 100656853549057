import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function ToggleSectionTitle({
                                title,
                                icon,
                                toggleFunction
                            }) {
    return (
        <h5 className="col-12 col-md-4 text-left user-select-none" onDoubleClick={toggleFunction}>
            <FontAwesomeIcon icon={icon}/>
            <em className="ml-2 small">{title}</em>
        </h5>
    );
}

export default ToggleSectionTitle;