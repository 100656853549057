import React, {useContext} from 'react';
import { CardColumns } from "reactstrap";
import Header from "../components/Layout/Header";
import WidgetCard from "../components/Card/WidgetCard";
import FormSearchFundingWrapper from "../components/Form/FormSearchFundingWrapper";
import {faCoins, faKey} from "@fortawesome/free-solid-svg-icons";
import AccessCard from "../components/Card/AccessCard";
import UserContext from "../contexts/UserContext";
import {useQuery} from "@apollo/client";
import {USER_LOCAL_ROLE_PERIMETERS_QUERY} from "../graphql/queries";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function AdminPage() {
    const {currentUser} = useContext(UserContext);
    const {loading, error, data} = useQuery(USER_LOCAL_ROLE_PERIMETERS_QUERY, {
        variables: {
            userId: currentUser.id
        }
    });

    const localRoles = data?.Users[0].localRoles ?? [];

    return (
        <div className={"container-fluid"}>

            <Header titlePage={"ADMINISTRATION"}
                    faIcon={['fas', 'user-shield']}
            />
            {
                loading
                    ? <p className={'text-center py-4'}>
                        <FontAwesomeIcon icon={['fas', 'spinner']} spin /> Chargement
                        </p>
                    : ''
            }
            {
                error
                    ? <p>Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
                    : ''
            }
            {
                data
                    ?
                    localRoles.filter(({ role }) => ( role.id === 'ROLE_ADMIN' )).length > 0
                        ?
                        <CardColumns className="d-flex flex-column d-md-block" style={{columnCount: 2}}>
                            <WidgetCard faIcon={faCoins} color={"white"} title={"Gérer les financements"}>
                                <p>Sélectionnez un périmètre *</p>
                                {
                                    loading
                                        ? <p><FontAwesomeIcon icon={['fas', 'spinner']} spin/> Chargement</p>
                                        : ''
                                }
                                {
                                    error
                                        ? <p>Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
                                        : ''
                                }
                                {
                                    data
                                        ? <FormSearchFundingWrapper localRoles={localRoles}/>
                                        : ''
                                }
                            </WidgetCard>

                            <WidgetCard faIcon={faKey} color={"white"} title={"Gérer les accès"}>
                                <AccessCard localRoles={localRoles}/>
                            </WidgetCard>
                        </CardColumns>
                        : <p>Vous n'avez pas accès à cette section <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
                    :''
            }

        </div>
    )
}

export default AdminPage;