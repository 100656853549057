import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import PropTypes from "prop-types";

function LoadingSpinner ({text, color}) {
    return (
        <h6 className={`text-${color}`}>
            <FontAwesomeIcon icon={faSpinner} spin/>
            <em> {text}...</em>
        </h6>
    );
}

LoadingSpinner.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
};

LoadingSpinner.defaultProps = {
    text: 'Chargement',
    color: 'secondary'
};

export default LoadingSpinner;