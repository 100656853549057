import React from "react";
import {GET_UNITS_BY_CODE} from "../../graphql/queries";
import {useLazyQuery} from "@apollo/client";
import FormSearchWithLazyQuery from "./FormSearchWithLazyQuery";

function FormSearchUnite({className, onSubmit}) {
    const lazyQuery = useLazyQuery(GET_UNITS_BY_CODE, {variables: {code: null}});

    return <FormSearchWithLazyQuery lazyQuery={lazyQuery}
                                    searchVariableName={'code'}
                                    minLength={3}
                                    renderItem={u => `${u.code} ${u.intitule}`}
                                    onSelect={onSubmit}
                                    className={className}
                                    variables={{}}
                                    placeholder={"Rechercher par code de l'unité (i.e : SMB101)"}

    />
}

export default FormSearchUnite;