import React, {useState} from 'react';
import Header from "../components/Layout/Header";
import WidgetCard from "../components/Card/WidgetCard";
import {Alert, Button, ListGroupItem} from "reactstrap";
import {faInfoCircle, faSearchPlus, faTimes, faUserGraduate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ListGroup} from "react-bootstrap";
import ProspectListItem from "../components/List/ProspectListItem";
import FormSearchProspectWrapper from "../components/Form/FormSearchProspectWrapper";
import {Link} from "react-router-dom";
import BackButton from "../components/Button/BackButton";

function SearchResultsPage(props) {
    const [values, setValues] = useState(props.location.state.values);
    const [searchedProspectResults, setSearchedProspectResults] = useState(props.location.state.results);

    const [isModifyingSearch, setIsModifyingSearch] = useState(false);
    const toggleNewSearch = () => setIsModifyingSearch(!isModifyingSearch);

    if (values !== props.location.state.values) {
        setValues(props.location.state.values);
        setIsModifyingSearch(false);
    }

    return (
        <div>
            <Header titlePage={"Résultat de la recherche"} faIcon={faUserGraduate}/>
            <BackButton className={'mb-2'} />

            { isModifyingSearch ?
                <WidgetCard>
                    <div className="d-flex justify-content-between mb-3">
                        <h5 className="text-info">
                            <FontAwesomeIcon icon={faSearchPlus}/><em> Nouvelle recherche</em>
                        </h5>
                        <Button className="col-4 col-md-2" onClick={toggleNewSearch} size={"sm"} color={"secondary"} outline={true}>
                            <FontAwesomeIcon icon={faTimes}/> Annuler
                        </Button>
                    </div>
                    <FormSearchProspectWrapper setSearchedResults={setSearchedProspectResults}
                                               withRedirection={true}/>
                </WidgetCard>
                :<>
                    <SearchSummary values={values} results={searchedProspectResults} toggleEdit={toggleNewSearch}/>
                    { searchedProspectResults.length >= 50 && <Alert color={'info'}>
                        <FontAwesomeIcon icon={faInfoCircle}/> Vos critères de recherches ne sont pas assez ciblés. Précisez votre recherche si celle-ci ne vous permet pas de trouver le prospect.
                    </Alert>}
                    {
                        searchedProspectResults?.map(user => <Link to={`/prospect/${user.id ?? user.enfUid}`}
                                                                   style={{ textDecoration: 'none' }}>
                            <ProspectListItem key={user.id !== undefined ? user.id : user.enfUid}
                                              user={user}
                                              callBack={() => {}} />
                        </Link>)
                    }
                </>
            }
        </div>
    )
}

const SearchSummary = ({values, results, toggleEdit}) => {
    const dataCount = results ? results.length : 0;
    const alertTitle = dataCount ? `${dataCount} prospect${dataCount > 1 ? 's' : ''}` : 'Aucun prospect';

    return <Alert color={"success"}>
        <div className={"d-flex justify-content-between mt-2"}>
            <h4><strong>{alertTitle}</strong></h4>
            <Button onClick={toggleEdit} color={"primary"} size={"sm"}>
                <FontAwesomeIcon icon={faSearchPlus}/> Modifier
            </Button>
        </div>

        <div className={"d-flex justify-content-between mt-2"}>
            <h5>{dataCount && dataCount > 0 ? '' : 'ne '}correspond{dataCount && dataCount > 1 ? 'ent' : ''} aux
                critères suivants :</h5>
        </div>

        <ListGroup className={"mt-2 mb-2"}>
            {
                values
                    ? Object
                        .values(values)
                        .map(field => {
                            return <ListGroupItem tag={"span"}>
                                <strong>{field.label}</strong> contient '{field.value}'
                            </ListGroupItem>
                        })
                    : ""
            }
        </ListGroup>

    </Alert>;
}

export default SearchResultsPage;
