import PropTypes from "prop-types";
import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import {PROJECTS_BY_REFERENCE_ID} from "../../graphql/queries";
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolder} from "@fortawesome/free-regular-svg-icons";

const DuplicatedProjectsButton = ({project}) => {

    const projectId = project.id;
    const projectIdReference = project.idReference;
    const isOriginal = parseInt(projectId) === projectIdReference;

    const [original, setOriginal] = useState(null);
    const [duplicates, setDuplicates] = useState(null);
    const [otherSingleProject, setOtherSingleProject] = useState(null);

    const [isDuplicateOpened, setIsDuplicateOpened] = useState(false);
    const toggleDuplicates = () => setIsDuplicateOpened(!isDuplicateOpened);

    useQuery(PROJECTS_BY_REFERENCE_ID, {
        variables: { idReference: projectIdReference },
        onCompleted(data) {
            if (data.Projects.length > 1) {
                const initial = data.Projects.filter(p => parseInt(p.id) === projectIdReference);
                setOriginal(initial[0]);

                const others = data.Projects.filter(p => (p.id !== projectId && parseInt(p.id) !== p.idReference));
                setDuplicates(others);

                if (data.Projects.length === 2) {
                    setOtherSingleProject(isOriginal ? others[0] : initial[0]);
                }
            }
        }
    });

    return (
        <>
            {
                duplicates ?
                    <div className="d-flex flex-row justify-content-end mt-1 grow-in">

                        { otherSingleProject ?
                            <Button color={"info"} size={'sm'} outline href={`/project/${otherSingleProject.id}`}>
                                <FontAwesomeIcon icon={faFolder}/>
                                { isOriginal ? " Projet dupliqué N°" : " Projet original N°"}{otherSingleProject.id}
                            </Button>
                            : <Dropdown isOpen={isDuplicateOpened} toggle={toggleDuplicates}>
                                <DropdownToggle color={"info"} size={'sm'} outline caret>
                                    <FontAwesomeIcon icon={faFolder}/> Projets dupliqués
                                </DropdownToggle>
                                <DropdownMenu>
                                    {
                                        duplicates.map(p => (
                                            <DropdownItem key={p.id} href={`/project/${p.id}`}>
                                                N°{p.id}
                                            </DropdownItem>
                                        ))}

                                    {
                                        !isOriginal ?
                                            <>
                                                <DropdownItem divider/>
                                                <DropdownItem key={original.id} href={`/project/${original.id}`}>
                                                    N°{original.id}<em className="text-muted small"> ORIGINAL</em>
                                                </DropdownItem>
                                            </>
                                            : ''
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        }
                    </div>
                    : ''
            }
        </>
    )
}

DuplicatedProjectsButton.propTypes = {
    project: PropTypes.object.isRequired,
};

export default DuplicatedProjectsButton;
