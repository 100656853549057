import React from 'react';
import PropTypes from 'prop-types'
import {Card, CardTitle, CardSubtitle, CardBody, CardFooter} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const WidgetCard = (props) => {
    return (
            <Card className={props.className} inverse={props.inverse} color={props.color}>
                <CardBody className={"p-3"}>
                    <div className={"d-flex justify-content-between"}>
                        <CardTitle tag={"span"} style={{fontSize: '1.5em'}}>
                            {props.title}
                        </CardTitle>
                        {
                            props.faIcon
                                ? <FontAwesomeIcon icon={props.faIcon} color={props.inverse ? "white" : "green"}/>
                                : ''
                        }
                    </div>

                    {
                        props.subtitle
                            ? <CardSubtitle>{props.subtitle}</CardSubtitle>
                            : ''
                    }

                    <CardBody className={"p-0 mt-1"}>
                        {props.children}
                    </CardBody>
                </CardBody>
                {props.footer
                    ? <CardFooter>{props.footer}</CardFooter>
                    : ""
                }
            </Card>
    );
};

WidgetCard.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'info',
        'warning',
        'danger',
        'light',
        'dark',
        'white',
    ]),
    inverse: PropTypes.bool,
    faIcon: PropTypes.object,
};

WidgetCard.defaultProps = {
    title: '',
    subtitle: '',
    color: 'white',
    inverse: false,
};

export default WidgetCard;
