import { gql } from '@apollo/client';

export const FULL_PROSPECT = gql`
fragment FullProspect on Prospect {
  id
  enfUid
  gender
  useName
  nameBirth
  firstName
  middleNames
  perimeter {
    id
    label
    parent {
      id
      label
    }
  }
  email
  telephone
  birthDate
  birthCountryCode {
    iso3Code
    labelFr
  }
  birthTownshipCode
  birthTownshipName
  nationality {
    iso3Code
    nationalityFr
  }
  homeAddress
  homeTownshipName
  homePostCode
  homeCountryCode {
    iso3Code
    labelFr
  }
  highestDiplomaLevel {
    id
    label
  }
  professionalStatus {
    id
    label
  }
  professionalExperience {
    id
    label
  }
  lookingForJob
  registeredAtEmploymentCenter
  gpdrConsents
}
`;

export const FULL_PROJECT = gql`
${FULL_PROSPECT}
fragment FullProject on Project {
  id
  idReference
  perimeter {
    id
    label
    type {
      id
    }
    parent {
      id
      label
    }
  }
  prospect {
    ...FullProspect
  }
  creator {
    id
    fullName
  }
  contributors {
    id
    fullName
  }
  cursusTargeted {
    id
    code
    intituleCourt
    intituleLong
    dateDebutValidite
    dateFinValidite
    dateDiplomeFinAccreditation
  }
  schema {
    schema
  }
  actionEntries {
    label
    date
    actor {
      fullName
    }
  }
  notes
  conclusion
  relatedProfessionalExperience {
    id
    label
  }
  createdAt
  updatedAt
  isOpen
}  
`;