import {gql} from '@apollo/client';
import {FULL_PROJECT, FULL_PROSPECT} from "./fragments";

export const USER_PROFILE_QUERY = gql`
  query UserForProfile($userId: Int!) {
    Users(id: $userId) {
      username
      fullName
      email
      localRoles {
        id,
        role { 
          id, 
          label 
        },
        perimetres { 
          id, 
          label 
        }
      }
    }
  }
`;

export const USER_LOCAL_ROLE_PERIMETERS_QUERY = gql`
  query UserForProfile($userId: Int!) {
    Users(id: $userId) {
      localRoles {
        role{
          id
          label
        }
        acquiredPerimeters:perimetres(compute: true) {
          id
        }
        perimetres {
          id
          label
          type {
            id
            label
          }
        }
      }
    }
  }
`;

export const ALL_USER_QUERY = gql`
  query User {
    Users {
      id
      username
      fullName
      localRoles {
        role {
          id
          label
        }
        perimetres {
          id
          label
        }
      }
    }
  }
`;

export const PROJECTS_BY_REFERENCE_ID = gql`
${FULL_PROJECT}
query projects($idReference: Int!) {
  Projects(idReference: $idReference) {
    ...FullProject
  }
}
`;

export const COUNT_ALL_PROJECTS = gql`
  query CountAllProjects {
    count: ProjectsCount
  }
`;

export const COUNT_ALL_USER_PROJECTS = gql`
  query CountUserProjects($userId: Int!) {
    count: ProjectsCount(contributor: $userId)
  }
`;

export const COUNT_ALL_PERIMETER_PROJECTS = gql`
  query CountUserProjects($perimeter: String!) {
    count: ProjectsCount(perimeter: $perimeter)
  }
`;

export const USER_ENF_UID_LOGIN = gql`
  query UserEnfUidForLogin($login: String!) {
    UsersEnf(login: $login, isFilterStaff: true) {
      uid
    }
  }
`;

export const FUNDING_PERIMETER_QUERY = gql`
  query FundingsPerimeter($perimeter: String!) {
    Fundings(perimeterID: $perimeter){
      id
      label
      perimeter {
        id
        label
      }
    }
  }
`;

export const FUNDING_ID_QUERY = gql`
  query FundingsPerimeter($fundingID: Int!) {
    Fundings(id: $fundingID){
      id
      label
      perimeter {
        id
        label
      }
    }
  }
`;

export const PERIMETERS_TREE_QUERY = gql`
  query PerimetersTree($typeID: String!) {
      Perimeters(type: $typeID) {
        id
        label
        type {
          id
          label
        }
        children {
          id
          label
          type {
            id
            label
          }
          children {
            id
            label
            type {
              id
              label
            }
            children {
              id
              label
              type {
                id
                label
              }
              children {
                id
                label
                type {
                  id
                  label
                }
              }
            }
          }
        }
      }
  }
`;

export const PROJECT_BY_ID = gql`
${FULL_PROJECT}
query projects($id: Int!) {
  Projects(id: $id) {
    ...FullProject
  }
}
`;

export const PROJECTS_BY_USER = gql`
${FULL_PROJECT}
query projects($userID: Int!) {
  Projects(contributorID: $userID) {
    ...FullProject
  }
}
`;

export const PROJECTS_BY_PERIMETER_ID = gql`
${FULL_PROJECT}
query projects($perimeter: String!) {
  Projects(perimeterID: $perimeter) {
    ...FullProject
  }
}
`;

export const PROJECTS_USER_RECENT = gql`
query projects($userID: Int!) {
  Projects(contributorID: $userID) {
    id
    idReference
    perimeter {
      parent {
        label
      }
    }
    prospect {
      useName
      firstName
    }
    cursusTargeted {
      code
    }
    schema {
      schema
    }
    updatedAt
    isOpen
  }
}
`;

/* Used @ FormProspect **/
export const QUERY_ADDRESS = gql`
query adresse($query: String!) {
  Addresses(query: $query) {
    houseNumber
    street
    name
    label
    city {
      deprecated
      name
      code
      departmentCode
      postalCodes
      country {
        iso3Code
        iso2Code
        labelFr
        nationalityFr
        onuCode
      }
    }
  }
}
`;

export const GET_ADDRESSES = gql`
query addresses($query: String!) {
  items:Addresses(query: $query) {
    name
    label
    city {
      name
      postalCode
    }
  }
}
`;

export const GET_CITIES = gql`
query cities($postalCode: String, $name: String, $includeDeprecated: Boolean) {
  items:Cities(postalCode: $postalCode, name: $name, includeDeprecated: $includeDeprecated) {
    code
    departmentCode
    postalCodes
    name
  }
}
`;

export const QUERY_COUNTRIES_CODE = gql`
    query countries {
        Countries {
            iso3Code,
            labelFr,
            nationalityFr
        }
    }`;

export const QUERY_PROFESSIONAL_STATUS = gql`
    query professional_status($isAscending: Boolean!) {
        ProfessionalStatus (isOrderedByLabel: $isAscending){
            id,
            label
        }
    }`;

export const QUERY_EQF_LEVELS = gql`
    query eqf_levels($isAscending: Boolean!) {
        EQFLevels (isOrderedByLabel: $isAscending) {
            id,
            label
        }
    }`;

export const QUERY_PROFESSIONAL_EXPERIENCES = gql`
    query professional_exp {
        ProfessionalExperiences {
            id,
            label
        }
    }`;

export const UNITS_ENROL_OPTIONS = gql`
query UnitsEnrolOptions($code: String!) {
  Units(codeLike: $code) {
    enrolOptions {
      year
      perimeter {
        id
        label
        type {
          id
        }
        parent {
          id
        }
      }
      temporality
      teachingModality
    }
  }
}
`;

export const GET_FUNDINGS_BY_PERIMETER = gql`
query getFundingsByPerimeter($perimeter: String!) {
  Fundings(perimeterID: $perimeter) {
    id
    label
  }
}
`;

export const GET_CURSUS_BY_CODE = gql`
query ($code: String!) {
  items:Cursuses(codeLike: $code) {
    id
    code
    intituleLong
    schema {
      schema
    }
    dateDebutValidite
    dateFinValidite
  }
}
`;

export const GET_UNITS_BY_CODE = gql`
query ($code: String!) {
  items:Units(codeLike: $code) {
    code
    intitule
  }
}
`;
export const GET_PROSPECT_BY_ID_OR_ENFUID = gql`
query prospects($id: Int, $enfUid: String) {
  Prospects(id: $id, enfUid: $enfUid) {
    id
    useName
    nameBirth 
    firstName 
    middleNames 
    perimeter { 
      id
      label 
    } 
    email 
    telephone 
    birthDate 
    birthCountryCode {
        iso3Code
        labelFr
    }
    birthTownshipCode 
    nationality {
      iso3Code
      nationalityFr
    }
    homeAddress
    homeTownshipName
    homePostCode
    homeCountryCode {
        iso3Code
        labelFr
    }
    highestDiplomaLevel {
      id 
      label
    }
    professionalStatus {
      id
      label
    }
    professionalExperience {
      id
      label
    } 
    lookingForJob
    registeredAtEmploymentCenter
    projects {
      id
      perimeter {
        parent {
          label
        }
      }
      prospect {
        useName
        firstName
      }
      cursusTargeted {
        code
      }
      schema {
        schema
      }
      updatedAt
      isOpen
    }
    gpdrConsents
  }
}
`;

export const USERS_ENF_UID_QUERY = gql`
  query UserEnfUid($enfUid: String!) {
    UsersEnf(uid: $enfUid) {
      uid
      useName: name
      gender
      firstName
      name
      nameBirth
      birthDate:dateBirth
      email
      login
    }
  }
`;

export const SEARCH_USER_ENF = gql`
  query SearchUsersENF($name: String, $firstName: String) {
    UsersEnf(name: $name, firstName: $firstName,isFilterStudent: true) {
      uid
      name   
      firstName
      login
      dateBirth
      email
    }
 }
`;

export const SEARCH_USER_ENF_EMAIL = gql`
  query SearchUser($name: String!, $isFilterStaff: Boolean, $isFilterStudent: Boolean) {
    items: UsersEnf(name: $name, isFilterStaff: $isFilterStaff, isFilterStudent: $isFilterStudent) {
      firstName
      useName: name
      email
    }
  }
`;

export const SEARCH_PROSPECTS = gql`
  query SearchProspects($nameLike: String, $firstNameLike: String, $emailLike: String) {
    Prospects(nameLike: $nameLike, firstNameLike: $firstNameLike, emailLike: $emailLike) {
      id
      enfUid
      firstName
      useName
      projects {
        id
        isOpen
        perimeter {
          id
        }
      }
      perimeter {
        parent {
          label
        }
      }
    }
  }
`;

export const SEARCH_PROSPECTS_AND_USERS_ENF = gql`
${FULL_PROSPECT}
query SearchProspectsAndUsersEnf($nameLike: String, $firstNameLike: String, $emailLike: String, $enfUid: String) {
  Prospects(nameLike: $nameLike, firstNameLike: $firstNameLike, emailLike: $emailLike, enfUid: $enfUid) {
    ...FullProspect
  }
  UsersEnf(name: $nameLike, firstName: $firstNameLike, uid: $enfUid, isFilterStudent: true) {
    enfUid: uid
    firstName
    nameBirth
    useName: name
    birthDate:dateBirth
    gender
    email
  }
}
`;

export const SEARCH_PROSPECTS_AND_USERS_ENF_WITH_PROJECTS = gql`
${FULL_PROSPECT}
query SearchProspectsAndUsersEnf($nameLike: String, $firstNameLike: String, $emailLike: String, $enfUid: String) {
  Prospects(nameLike: $nameLike, firstNameLike: $firstNameLike, emailLike: $emailLike, enfUid: $enfUid) {
    ...FullProspect
    projects {
      id
      isOpen
    }
  }
  UsersEnf(name: $nameLike, firstName: $firstNameLike, uid: $enfUid, isFilterStudent: true) {
    enfUid: uid
    firstName
    nameBirth
    useName: name
    birthDate:dateBirth
    gender
    email
  }
}
`;

export const PERIMETER_BY_ID = gql`
    query PerimeterById($id: String!) {
      Perimeters(id: $id) {
        id
        label
        children {
          id
        }
      }
    }
`;

export const SEARCH_USER_STAFF_ENF = gql`
query SearchUsersStaffENF($name: String, $firstName: String) {
  UsersEnf(name: $name, firstName: $firstName, isFilterStaff: true) {
    uid
    fullName
    login
    compasUser: user {
      id
      localRoles {
        role {
          id
          label
        }
        perimetres {
          id
        }
      }
    }
  }
}
`;

export const SEARCH_PERIMETER_SETTINGS = gql`
query SearchPerimeterSettings($idPerimeter: String, $filterSettings: String) {
  PerimeterSettings(idPerimeter: $idPerimeter, filterSettings: $filterSettings) {
    perimeter {
      id
    }
    settings
  }
}
`