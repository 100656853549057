import React, {useState} from "react";
import FormSearchFunding from "./FormSearchFunding";
import {Redirect} from "react-router-dom";

function FormSearchFundingWrapper(props) {
    const [values, setValues] = useState(props?.values ??
        {
            perimeter: {
                value: null,
                label: 'Périmètre'
            }
        }
    );
    const [redirect, setRedirect] = useState(false);

    const onChange = e => {
        setValues({
            perimeter: {
                ...values.perimeter,
                'value': values.perimeter.value === e.currentTarget.dataset.id ? null : e.currentTarget.dataset.id
            }
        })
    }

    const onSubmit = e => {
        setRedirect(values.perimeter.value !== null);
        e.preventDefault();
    }

    return <>
        <FormSearchFunding onSubmit={onSubmit} onChange={onChange} value={values.perimeter.value}
                           localRoles={props?.localRoles}/>
        {
            redirect
                ? <Redirect
                    to={{
                        pathname: '/search/results/fundings',
                        state: {
                            values: values,
                            localRoles: props?.localRoles
                        }
                    }}
                    push={true}/>
                : ""
        }
    </>
}

export default FormSearchFundingWrapper;
