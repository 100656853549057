import React, {useState} from 'react';
import Header from "../components/Layout/Header";
import WidgetCard from "../components/Card/WidgetCard";
import {faLifeRing, faVideo, faQuestionCircle, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Button, Alert} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import createProjectSinglePerimeter from '../assets/img/compas_create_single_perimeter.gif';
import createProjectMultiPerimeters from '../assets/img/compas_create_multi_perimeters.gif';
import createProspectProject from '../assets/img/compas_create_prospect.gif';
import searchProspectDashboard from '../assets/img/compas_dashboard_search_prospect.gif';
import searchProspectProject from '../assets/img/compas_project_search_prospect.gif';
import chooseCursusProject from '../assets/img/compas_project_formation_cursus.gif';
import chooseAlacarteProject from '../assets/img/compas_project_formation_alacarte.gif';
import replaceFormationProject from '../assets/img/compas_project_formation_remplacer.gif';
import setObtainedUnit from '../assets/img/compas_project_formation_obtenue.gif';
import setOptionsUnit from '../assets/img/compas_project_formation_options.gif';
import grantAccess from '../assets/img/compas_user_grant.gif';
import revokeAccess from '../assets/img/compas_user_revoke.gif';
import createFunding from '../assets/img/compas_create_funding.gif';
import downloadAttest from '../assets/img/compas_project_download_attestation.gif';
import sendAttest from '../assets/img/compas_project_send_attestation.gif';

function HelpPage() {
    const [modal, setModal] = useState(false);
    const [gifPath, setGifPath] = useState('');
    const toggle = () => setModal(!modal);

    return (
        <>
            <div className={"container-fluid"}>

                <Header titlePage={"A I D E"}
                        faIcon={faLifeRing}
                />

                <div className={'mb-2'}>
                    <WidgetCard>
                        <h4>Nous contacter</h4>

                        En cas de dysfonctionnement de l'application COMPAS vous pouvez contacter <a
                        href='mailto:sifa.compas@cnam.fr'>sifa.compas@cnam.fr</a>.
                    </WidgetCard>
                </div>


                <WidgetCard>
                    <h4>Tutoriels</h4>

                    <div className={'ml-2'}>
                        <h6><FontAwesomeIcon icon={faQuestionCircle}/> Comment créer un projet ?</h6>

                        <ListGroup className={'ml-2'}>
                            <ListGroupItem action onClick={() => {
                                setGifPath(createProjectSinglePerimeter);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Créer un projet depuis le tableau de bord (utilisateur
                                avec un seul périmètre)
                            </ListGroupItem>
                            <ListGroupItem action onClick={() => {
                                setGifPath(createProjectMultiPerimeters);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Créer un projet depuis le tableau de bord (utilisateur
                                avec plusieurs périmètres)
                            </ListGroupItem>
                            <ListGroupItem action onClick={() => {
                                setGifPath(searchProspectDashboard);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Rechercher un prospect depuis le tableau de bord et
                                créer un nouveau projet
                            </ListGroupItem>
                        </ListGroup>
                    </div>

                    <div className={'ml-2 mt-2'}>
                        <h6><FontAwesomeIcon icon={faQuestionCircle}/> Comment compléter la section "Prospect" du projet
                            ?</h6>

                        <ListGroup className={'ml-2'}>
                            <ListGroupItem action onClick={() => {
                                setGifPath(createProspectProject);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Créer un prospect et l'associer au projet
                            </ListGroupItem>
                            <ListGroupItem action onClick={() => {
                                setGifPath(searchProspectProject);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Rechercher un prospect et l'associer au projet
                            </ListGroupItem>
                            <ListGroupItem action onClick={() => {
                                setGifPath(searchProspectDashboard);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Rechercher un prospect depuis le tableau de bord et
                                créer un nouveau projet
                            </ListGroupItem>
                        </ListGroup>
                    </div>

                    <div className={'ml-2 mt-2'}>
                        <h6><FontAwesomeIcon icon={faQuestionCircle}/> Comment compléter la section "Formation" du
                            projet ?</h6>

                        <ListGroup className={'ml-2'}>
                            <ListGroupItem action onClick={() => {
                                setGifPath(chooseCursusProject);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Choisir un cursus cible
                            </ListGroupItem>
                            <ListGroupItem action onClick={() => {
                                setGifPath(chooseAlacarteProject);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Choisir une formation "à la carte"
                            </ListGroupItem>
                            <ListGroupItem action onClick={() => {
                                setGifPath(replaceFormationProject);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Remplacer la formation cible
                            </ListGroupItem>
                            <ListGroupItem action onClick={() => {
                                setGifPath(setOptionsUnit);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Choisir une option de formation pour une unité
                            </ListGroupItem>
                            <ListGroupItem action onClick={() => {
                                setGifPath(setObtainedUnit);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Indiquer qu'une unité a été obtenue
                            </ListGroupItem>
                        </ListGroup>
                    </div>

                    <div className={'ml-2 mt-2'}>
                        <h6><FontAwesomeIcon icon={faQuestionCircle}/> Comment générer une attestation de positionnement ?</h6>

                        <Alert color={'info'}>
                            <small>
                                <FontAwesomeIcon icon={faInfoCircle}/> L'attestation de positionnement ne peut être générée que si le projet contient, à
                                minima, un prospect, une formation et une conclusion.
                            </small>
                        </Alert>

                        <ListGroup className={'ml-2'}>
                            <ListGroupItem action onClick={() => {
                                setGifPath(downloadAttest);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Télécharger une attestation de positionnement
                            </ListGroupItem>
                            <ListGroupItem action onClick={() => {
                                setGifPath(sendAttest);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Envoyer une attestation de positionnement
                            </ListGroupItem>
                        </ListGroup>
                    </div>

                    <div className={'ml-2 mt-2'}>
                        <h6><FontAwesomeIcon icon={faQuestionCircle}/> Comment gérer les accès utilisateurs ?</h6>

                        <ListGroup className={'ml-2'}>
                            <ListGroupItem action onClick={() => {
                                setGifPath(grantAccess);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Ajouter un droit d'accès
                            </ListGroupItem>
                            <ListGroupItem action onClick={() => {
                                setGifPath(revokeAccess);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Retirer un droit d'accès
                            </ListGroupItem>
                        </ListGroup>
                    </div>

                    <div className={'ml-2 mt-2'}>
                        <h6><FontAwesomeIcon icon={faQuestionCircle}/> Comment gérer la liste des financements ?</h6>

                        <ListGroup className={'ml-2'}>
                            <ListGroupItem action onClick={() => {
                                setGifPath(createFunding);
                                toggle();
                            }} style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faVideo}/> Ajouter financement local (ATTENTION seulement si son
                                existence est locale)
                            </ListGroupItem>
                        </ListGroup>
                    </div>

                </WidgetCard>

            </div>
            <Modal size={'xl'} centered isOpen={modal} toggle={toggle} className={'fullscreen'}>
                <ModalHeader toggle={toggle}>Tutoriel</ModalHeader>
                <ModalBody>
                    <img src={gifPath} alt={'GIF animé'}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Fermer</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default HelpPage;
