import React from 'react';
import Header from "../components/Layout/Header";
import {faUserGraduate} from "@fortawesome/free-solid-svg-icons";

function ProspectsPage() {
    return (
        <div className={"container-fluid"}>
            <Header titlePage={"P R O S P E C T S"} faIcon={faUserGraduate}/>
        </div>
    );
}
export default ProspectsPage;
