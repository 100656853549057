import React from "react";
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserPopoverCard from "../Card/UserPopoverCard";

const Header = ({titlePage, faIcon, objectID}) => {

    return (
        <div className="d-flex flex-row justify-content-between align-content-center mt-2 mb-3">
            <div className="responsiveTitleCol flex-grow-1">
                <h4 className="text-center">
                    <FontAwesomeIcon icon={faIcon}/>&nbsp;{titlePage}
                    { objectID ?
                        <em className="ml-2 small">N°{objectID}</em>
                        : ''
                    }
                </h4>
            </div>

            <div className="col-2 text-right d-none d-lg-block">
                <UserPopoverCard className="align-middle"/>
            </div>
        </div>
    );
};

Header.propTypes = {
    titlePage: PropTypes.string.isRequired,
    faIcon: PropTypes.object,
    objectID: PropTypes.number
};

export default Header;
