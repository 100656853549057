import React, {useState} from "react";
import {useLazyQuery} from "@apollo/client";
import {SEARCH_PROSPECTS_AND_USERS_ENF_WITH_PROJECTS} from "../../graphql/queries";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import FormSearchProspect from "./FormSearchProspect";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

const FormSearchProspectWrapper = ({withRedirection, setSearchedResults=null}) => {

    const [values, setValues] = useState({});
    const [results, setResults] = useState([]);
    const [redirect, setRedirect] = useState(false);

    const [searchQuery, {loading, error}] = useLazyQuery(SEARCH_PROSPECTS_AND_USERS_ENF_WITH_PROJECTS,
        {
            variables:
                {
                    nameLike: values.nameLike?.value ?? null,
                    firstNameLike: values.firstNameLike?.value ?? null,
                    emailLike: values.emailLike?.value ?? null,
                }, onCompleted: (data) => {
                let enfUids = [];
                // Prospects
                data.Prospects.map((prospect) => {
                    if (prospect['enfUid']) {
                        enfUids = enfUids.concat(prospect['enfUid'])
                    }
                })
                // UsersEnf
                const usersEnf = data.UsersEnf.filter((userEnf) => {
                    return !enfUids.includes(userEnf['enfUid'])
                })

                setResults(results.concat(usersEnf).concat(data.Prospects));
                if (setSearchedResults) {
                    setResults([]);
                    setSearchedResults(results.concat(usersEnf).concat(data.Prospects));
                }
                setRedirect(withRedirection);
            }
        });

    const onChange = e => {
        let currentValues = values;
        currentValues[e.target.name] = {value: e.target.value, label: e.target.dataset.label};
        setValues(currentValues);
    }

    const onSubmit = e => {
        e.preventDefault();
        searchQuery();
    }

    if (loading) {
        return (
            <div className="d-flex flex-row justify-content-center">
                <h5 className="text-primary">
                    <FontAwesomeIcon icon={faSpinner} spin/> Recherche en cours...
                </h5>
            </div>
        )
    }
    if (error) {
        return (
            <div className="d-flex flex-row justify-align-content">
                <p>Erreur <span role={"img"} aria-label={"disapointed face"}>😥</span></p>
            </div>
        )
    }

    return (
        <>
            <FormSearchProspect values={values} handleChange={onChange} handleSubmit={onSubmit}/>
            {
                redirect
                    ? <Redirect
                        to={{
                            pathname: '/search/results',
                            state: {values: values, results: results}
                        }}
                        push={true}/>
                    : ""
            }
        </>
    )
}

FormSearchProspectWrapper.propTypes = {
    withRedirection: PropTypes.bool.isRequired,
    setSearchedResults: PropTypes.func
};

FormSearchProspectWrapper.defaultProps = {
    withRedirection: true,
    setSearchedResults: null
};

export default FormSearchProspectWrapper;
